import { useEffect, useState } from 'react'

export const useMultiPlayerId = (roomId) => {
  const [multiPlayerId, setMultiPlayerId] = useState('')

  useEffect(() => {
    if (roomId) {
      const id = localStorage.getItem(`${roomId}`)
      setMultiPlayerId(id)
    }
  }, [roomId])

  return multiPlayerId
}
