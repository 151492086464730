import { Modal } from 'reactstrap'
import classNames from 'classnames'
export const ChangeAvatarModal = ({
  isChangeAvatarModalOpen,
  handleAvatarChange,
  avatars,
  onSelectedAvatar,
  selectedAvatar,
  firstAvatar,
  toggleModalOnScreen,
}) => {
  return (
    <Modal
      isOpen={isChangeAvatarModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
      toggle={toggleModalOnScreen}
    >
      <div className="py-4">
        <div className="px-7 pt-4 flex flex-col justify-center items-center gap-8 pb-7">
          <div className="styled-border"></div>
          <div className="flex flex-col gap-6 border-b-2 border-b-[#E5E5E6] py-8 justify-center items-center">
            <p className="m-0 font-semibold text-[22px]">Choose your avatar</p>
            <img
              src={!!selectedAvatar ? selectedAvatar : firstAvatar}
              alt="placeholder-avatar"
              className="w-[72px] h-[72px] rounded-full border-[1.5px] border-[#0077FF] cursor-pointer"
            />
          </div>
        </div>
        <div>
          <div className="flex flex-wrap items-center justify-center gap-4 border-b-2 border-b-[#E5E5E6] pb-8 w-full px-4">
            {avatars?.map((ava, idx) => (
              <img
                key={idx}
                src={ava.src}
                alt="placeholder-avatar"
                className={classNames('w-12 h-12 rounded-full cursor-pointer', {
                  'border-[1.5px] border-[#0077FF]': ava.src === selectedAvatar,
                })}
                onClick={() => handleAvatarChange(ava.src)}
              />
            ))}
          </div>
        </div>
        <button
          onClick={onSelectedAvatar}
          className="text-xl bg-[#F3D505] py-[10px] px-6 font-medium rounded-3xl mt-6 text-right flex ml-auto mr-3"
        >
          Done
        </button>
      </div>
    </Modal>
  )
}
