import { apiSlice } from '../api/apiSlice'

export const getStreaksSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStreaks: builder.mutation({
      query: () => {
        return {
          url: `reward/streak/stats`,
          method: 'GET',
        }
      },
    }),
    postStreaks: builder.mutation({
      query: (streakDetails) => {
        return {
          url: `reward/streak/claim`,
          method: 'POST',
          body: { ...streakDetails },
        }
      },
    }),
  }),
})

export const { useGetStreaksMutation, usePostStreaksMutation } = getStreaksSlice
