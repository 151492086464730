import './Banner.css'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { setOtpToStore } from '../../../store/login/loginSlice'
import {
  AndroidDownload,
  AppleDownload,
  AvatarsBanner,
  ControlledLg,
  ControlledSm,
  RankBadge,
  SearchIcon,
  TimePassNewLogo,
} from '../icones'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import LoginModal from '../Common/LoginModal'
import OtpModal from '../Common/OtpModal'
import { useLoginInfo } from '../../../hooks/useLoginInfo'
import { removeAllCategory } from '../../../store/ViewAll/categorySlice'
import classNames from 'classnames'
import { Carousal } from '../Common/Carousal'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useNavigate } from 'react-router'

const Banner = ({
  setHomePageModals,
  homePageModals,
  premiumGameConfig,
  fetchCategories,
  handleSearch,
  handleOnMenuClick,
  handleGetSMS,
  handlePlayInTournament,
  handlePlayInTournamentFromActive,
}) => {
  const {
    counter,
    handleGetOtp,
    handleMobileNumberChange,
    isCorrectMobileNumber,
    otp,
    handleSubmitOTP,
    setOtp,
    handleResendOTP,
    mobileNumber,
    otpSent,
    isVerifyOtpLoading,
    isNormalLogin,
    setNormalLogin,
    countryCode,
  } = useLoginInfo()

  const loginState = useSelector((state) => state?.login)

  const allCategoryId = useSelector((state) => state.allCategoryId)

  const dispatch = useDispatch()

  const handleScrollToPlayClick = () => {
    const properties = {
      ScreenName: 'Home',
    }

    sendAnalytics('track', properties, 'Scroll To Play Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handlePressPlayStore = (storeName) => {
    const properties = {
      ScreenName: 'Homepage',
      StoreName: storeName,
    }

    sendAnalytics('track', properties, 'Download Button Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleChangeNumber = () => {
    dispatch(setOtpToStore(false))
    setOtp('')
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: true,
        isOtpModalOpen: false,
      }
    })

    const properties = {
      SHOWN_FROM: 'Home',
    }

    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleLoginModal = (screenName) => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: true,
      }
    })

    const properties = {
      ScreenName: screenName,
    }
    sendAnalytics('track', properties, 'Login Button Pressed', [
      'moengage',
      'mixpanel',
    ])

    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])

    setNormalLogin(true)
  }

  const toggleLoginModal = () => {
    setNormalLogin(false)

    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: !prev.isLoginModalOpen,
      }
    })
  }

  const handleEnterPhoneNumber = async () => {
    const res = await handleGetOtp()
    if (res?.message === 'OTP Sent Successfully') {
      setHomePageModals((prev) => {
        return {
          ...prev,
          isLoginModalOpen: false,
          isOtpModalOpen: true,
        }
      })
    }
  }

  const handleSubmitOTPAndCloseModals = async () => {
    const res = await handleSubmitOTP()
    if (res?.message === 'Logged In') {
      setHomePageModals((prev) => ({
        ...prev,
        isOtpModalOpen: false,
      }))
      dispatch(removeAllCategory())
      fetchCategories(allCategoryId?.data)
    }
  }

  const handleToggleOtp = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isOtpModalOpen: false,
      }
    })
  }

  const isLarge = useMediaQuery('(min-width:768px)')
  const navigate = useNavigate()

  const handleSearchIconClick = () => {
    navigate('/search')

    const properties = {
      FROM: 'Home',
    }

    sendAnalytics('track', properties, 'Search Icon Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  return (
    <div className="relative">
      <LoginModal
        isOpen={homePageModals?.isLoginModalOpen}
        toggle={toggleLoginModal}
        mobileNumber={`${countryCode}${mobileNumber}`}
        handleMobileNumberChange={handleMobileNumberChange}
        handleGetOtp={handleEnterPhoneNumber}
        isCorrectMobileNumber={isCorrectMobileNumber} // Assuming this function is defined
        isNormalLogin={isNormalLogin}
      />
      <OtpModal
        isOpen={homePageModals?.isOtpModalOpen}
        toggle={handleToggleOtp}
        mobileNumber={`${countryCode}${mobileNumber}`}
        otp={otp}
        setOtp={setOtp}
        handleChangeNumber={handleChangeNumber}
        handleSubmitOTP={handleSubmitOTPAndCloseModals}
        handleResendOTP={handleResendOTP}
        otpSent={otpSent} // Assuming this variable is defined
        counter={counter} // Assuming this variable is defined
        isVerifyOtpLoading={isVerifyOtpLoading} // Assuming this variable is defined
      />
      {!loginState?.accessToken ? (
        <div className="flex justify-between items-center py-2 md:py-4 px-2 w-full xl:w-3/5 mx-auto">
          <div className="flex items-center justify-between w-full">
            <div className="w-[64px] h-[35px] md:w-[87px] md:h-[46px]">
              <TimePassNewLogo />
            </div>
            <div
              className="flex items-center gap-2 border border-[#e5e5e6] p-2 rounded-full"
              onClick={handleSearchIconClick}
            >
              <SearchIcon
                width="30px"
                height="30px"
                stroke="#47891D"
                fill="white"
                className="cursor-pointer"
                strokeWidth={2.5}
              />
            </div>
          </div>
          <div
            className={classNames('hidden  items-center font-semibold gap-7', {
              'sm:hidden': !loginState?.accessToken,
              'sm:flex': loginState?.accessToken,
            })}
          >
            {!loginState?.accessToken && (
              <div
                className="font-semibold px-10 py-2 border-black border-2 border-solid rounded-3xl cursor-pointer"
                onClick={() => handleLoginModal('Homepage')}
              >
                Login
              </div>
            )}
          </div>
        </div>
      ) : null}

      <div className=" m-auto h-[80vh] md:h-[65vh] mb-4 bg-gradient-to-b from-[#020303] via-[#020303]  to-[#0960C4] text-white flex">
        <div className="w-1/5 flex flex-col justify-between">
          <ControlledLg className="block w-[152px] z-20 md:w-[468px] h-[124px] md:h-[300px] relative right-[120%] sm:right-[80%] md:right-[80%] lg:right-[80%] bottom-[5%] md:bottom-[10%]" />
          <RankBadge className="w-[100px] md:w-[141px] h-[121px] md:h-[166px] mt-0" />
        </div>
        <div className="w-3/5  flex flex-col justify-between md:justify-center  items-center gap-4">
          <div className="flex flex-col gap-8 md:justify-between h-[60%] mt-[auto] md:mt-0 md:pt-0 md:pb-8">
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="font-semibold text-[22px] md:text-[40px] xl:text-5xl whitespace-nowrap z-10">
                Introducing Tournaments
              </div>
              <div className="text-center">
                Can you be the number 1 on the leaderboard?
              </div>
            </div>

            <div
              className={classNames(
                'flex flex-col md:flex-row gap-2 md:gap-5 justify-center items-center',
                {}
              )}
            >
              {!loginState?.accessToken && (
                <button
                  className="bg-[#004FA8] py-[10px] w-full md:w-1/2 font-semibold z-10 rounded-3xl whitespace-nowrap"
                  onClick={() => handleLoginModal('Homepage')}
                >
                  Login with OTP
                </button>
              )}
              <button
                className={classNames(
                  'bg-[#F3D505] py-[10px] font-semibold w-full md:w-1/2 rounded-3xl text-black whitespace-nowrap z-10',
                  {
                    'flex justify-center  items-center ':
                      !loginState?.accessToken,
                  }
                )}
                onClick={handlePlayInTournament}
              >
                Play in a Tournament
              </button>
            </div>

            <div className="flex flex-col items-center gap-1 md:gap-2 justify-center">
              <div className="text-xs">Available on</div>
              <div className="flex flex-row gap-2">
                <div onClick={() => handlePressPlayStore('Play Store')}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.simpleviralgames.timepass"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AndroidDownload />
                  </a>
                </div>
                <div onClick={() => handlePressPlayStore('App Store')}>
                  <a
                    href="https://apple.co/3MHAuNq"
                    target="_blank"
                    rel="noreferrer"
                    className="z-10"
                  >
                    <AppleDownload />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex md:hidden mb-[20%] mt-[1rem] flex-col items-center justify-center text-black gap-1">
            <a href="#gamecon">
              <div
                className="bg-white text-[17px] px-4 py-2  text-black rounded-full z-[99999]"
                onClick={handleScrollToPlayClick}
              >
                Scroll to Play
              </div>
            </a>
            <div className="flex flex-col items-center justify-center">
              <div className="chevron"></div>
              <div className="chevron"></div>
            </div>
          </div>
        </div>

        <div className="w-1/5 flex flex-col items-center justify-center mt-[auto] md:mt-0">
          <AvatarsBanner
            className="h-[150px] md:h-[300px] w-[135px] md:w-[270px]"
            width={isLarge ? '270px' : '135px'}
            height={isLarge ? '300px' : '150px'}
          />
        </div>
      </div>
      <div className="hidden lg:block">
        <Carousal
          handlePlayInTournamentFromActive={handlePlayInTournamentFromActive}
        />
      </div>
    </div>
  )
}

export default Banner
