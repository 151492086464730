import { apiSlice } from '../api/apiSlice'

export const userHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userHistory: builder.mutation({
      query: () => ({
        url: 'game/history?language=en',
        method: 'GET',
      }),
    }),
  }),
})

export const userAddGameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userAddGames: builder.mutation({
      query: (game) => ({
        url: `game/favorite?language=en`,
        method: 'POST',
        body: game,
      }),
    }),
  }),
})

export const userAllCategorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userAllCategory: builder.mutation({
      query: (data) => {
        const newPage = data.page ?? 1
        const category = data.categoryId
        return {
          url: `/game/category/${category}?p=${newPage}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const userAllCategoryIdSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userAllCategoryId: builder.mutation({
      query: () => {
        return {
          url: 'game/category/viewall?platform=web&language=en',
          method: 'GET',
        }
      },
    }),
  }),
})

export const userDeleteGameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userDeleteGames: builder.mutation({
      query: (game) => {
        return {
          url: 'game/favorite?language=en',
          method: 'DELETE',
          body: game,
        }
      },
    }),
  }),
})

export const userRecordHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userRecordHistory: builder.mutation({
      query: (id, options) => {
        return {
          url: `/game/${id}/history?language=en`,
          method: 'POST',
        }
      },
    }),
  }),
})

export const userNameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userName: builder.mutation({
      query: () => ({
        url: '/user/profile',
        method: 'GET',
      }),
    }),
    userProfile: builder.mutation({
      query: (updateProfile) => {
        return {
          url: '/user/profile',
          method: 'PUT',
          body: updateProfile,
        }
      },
    }),
  }),
})

export const userContinuePlaySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userContinuePlay: builder.mutation({
      query: (page) => {
        const pg = page ?? 1
        return {
          url: `/game/history/recentlyplayed?platform=WEB&language=en&p=${pg}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const userSavedGamesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userSavedGames: builder.mutation({
      query: (page) => {
        const pg = page ?? 1
        return {
          url: `game/favorite?language=en&p=${pg}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const userBulkUpdateSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userBulkUpdate: builder.mutation({
      query: (games) => ({
        url: `game/favorite/bulkupdate`,
        method: 'POST',
        body: {
          games: games,
        },
      }),
    }),
  }),
})

export const { useUserRecordHistoryMutation } = userRecordHistorySlice

export const { useUserSavedGamesMutation } = userSavedGamesSlice

export const { useUserHistoryMutation } = userHistorySlice

export const { useUserNameMutation, useUserProfileMutation } = userNameSlice

export const { useUserContinuePlayMutation } = userContinuePlaySlice

export const { useUserDeleteGamesMutation } = userDeleteGameSlice

export const { useUserAddGamesMutation } = userAddGameSlice

export const { useUserBulkUpdateMutation } = userBulkUpdateSlice

export const { useUserAllCategoryMutation } = userAllCategorySlice

export const { useUserAllCategoryIdMutation } = userAllCategoryIdSlice
