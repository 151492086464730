import React, { useEffect } from 'react'
import { Modal } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { AndroidDownload, AppleDownload } from '../icones'
import classNames from 'classnames'

const DownLoadLinkModal = ({
  isOpen,
  toggle,
  handlePhoneNumberChangeFromSMS,
  smsPhoneNumberVlaue,
  handlePressPlayStore,
  isCorrectMobileNumber,
  handleMobileNumberChange,
  handleGetSMS,
  mobileNumber,
  isDownloadLinkLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="login-modal">
      <div className="p-7 flex flex-col gap-8 items-center justify-center">
        <div className="styled-border"></div>

        <div className="text-[22px] font-semibold text text-center">
          Get TimePass mobile app to play Tournaments
        </div>
        <div className="flex flex-col gap-[1rem] w-full">
          <PhoneInput
            className="react-tel-input"
            placeholder="Enter phone number"
            country="in"
            value={mobileNumber}
            countryCodeEditable={false}
            onChange={handleMobileNumberChange}
            onEnterKeyPress={handleGetSMS}
          />
          <button
            className={classNames('w-full py-2 rounded-[30px] font-medium', {
              'bg-[#F3D505] cursor-pointer':
                isCorrectMobileNumber(mobileNumber),
              'bg-[#979797] cursor-not-allowed':
                !isCorrectMobileNumber(mobileNumber),
            })}
            onClick={handleGetSMS}
            disabled={
              !isCorrectMobileNumber(mobileNumber) || isDownloadLinkLoading
            }
          >
            Get Download Link
          </button>
        </div>
        <div>
          <span></span>
        </div>
        <div className="flex flex-row gap-2">
          <div onClick={() => handlePressPlayStore('Play Store')}>
            <a
              href="https://play.google.com/store/apps/details?id=com.simpleviralgames.timepass"
              target="_blank"
              rel="noreferrer"
            >
              <AndroidDownload />
            </a>
          </div>
          <div onClick={() => handlePressPlayStore('App Store')}>
            <a
              href="https://apple.co/3MHAuNq"
              target="_blank"
              rel="noreferrer"
              className="z-10"
            >
              <AppleDownload />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DownLoadLinkModal
