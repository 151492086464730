export const GAME_STARTED = 'game-started'
export const ROOM_DEACTIVATED = 'room-deactivated'
export const REACTION = 'reaction'

export const handleMPmsgs = (channelMsg, setChannelMessage) => {
  switch (channelMsg.type) {
    case 'STATECHANGE':
      setChannelMessage(channelMsg.message)
      break
    case 'REACTION':
      console.log(channelMsg.message)
      break
    case 'QUICKCHAT':
      console.log(channelMsg.message)
      break
    default:
      return
  }
}
