import Footer from '../../component/Footer/Footer'
import { useEffect } from 'react'

const SupportPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="max-w-[960px] px-4 mx-auto sm:px-6 lg:px-8 mt-10">
        <h1>
          <b>Timepass</b> Support
        </h1>
        <p>Experiencing issues in the games? Get it sorted here.</p>
        <p>
          Most of these questions are applicable to all our products and
          services. If you don't find the question related your problem, please
          reach out to us via support@simpleviralgames.com or (+91) 910-802-5480.
        </p>
        <h2>
          <b>Registered Address</b>
        </h2>
        <p>
          Flat 10113, Sobha Silicon Oasis Hosa Road, Electronic City Bangalore
          Bangalore KA 560100 IN
        </p>
        <h2>
          <b>Office Address</b>
        </h2>
        <p>
          1st Floor, 1213, 22nd Cross Rd, Sector 3, HSR Layout, Bengaluru,
          Karnataka 560102
        </p>
        <h2>
          <b>General questions</b>
        </h2>
        <h3>Where can I find my User ID?</h3>
        <p>Need help to figure out where your User ID is?</p>
        <p>Please follow the steps to get it: </p>
        <ul>
          <li>
            1. Tap the Settings icon on the home screen (the gear in the upper
            right corner of the screen)
          </li>
          <li>
            2. From there, you should tap the number in the bottom right corner,
            corresponding to your current game version. You should now see a
            notification saying Copied Info To Clipboard.
          </li>
          <li>3. Paste the account info into your email to us.</li>
        </ul>
        <p>
          If you have been asked to copy the detailed content information
          (content hash), you need to tap and hold your finger on the game
          version for about 5 seconds.
        </p>
        <h3>Delete or request a copy of your Data</h3>
        <p>
          Please feel free to request a copy of your data or ask to delete the
          data by contacting us via support@simpleviralgames.com. Once we have
          all the required info from you, we will start deleting or assembling
          your data and notify you when it is done.
        </p>
        <p>
          Usually, it takes up to 2 days to entirely delete your data or up to
          30 days to retrieve it and share it with you.
        </p>
        <p>Note: the data removal request is final and irreversible.</p>
        <p>
          Important: Timepass comply with the Children's Online Privacy
          Protection Act ("COPPA"). It means that players under 13 have their
          data stored only locally on their devices. In case they or their
          parents want to remove all data, they can proceed with uninstalling
          the game.{' '}
        </p>
      </div>
      <Footer />
    </>
  )
}

export default SupportPage
