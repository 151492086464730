import { Modal } from 'reactstrap'
import {
  CheckIcon,
  Circle,
  LeaveChannel,
  ArrowLeft,
  FilledCheck,
} from '../icones'
import classNames from 'classnames'
import { Fragment } from 'react'

export const GameModeModal = ({
  isGameModeModalOpen,
  onSelectMode,
  handleJoinWithCode,
  gameLogo,
  selectedGameMode,
  onCreateRoomClick,
  multiplayerDurationData,
}) => {
  return (
    <Modal
      isOpen={isGameModeModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
    >
      <div>
        <div className="flex flex-col gap-6 justify-center items-center pb-14 pt-8 px-4 ">
          <div className="">
            {/* <ArrowLeft /> */}
            <div className="text-black font-semibold text-[22px]">
              Select game mode
            </div>
          </div>
          <img
            src={gameLogo}
            className="rounded-xl h-14 w-14"
            alt="game-logo-icon"
          />
          {Object?.entries(multiplayerDurationData)?.map(
            ([gameMode, gameDescription]) => {
              return (
                <div
                  className={classNames(
                    'flex border-[1.5px] w-full border-[#2E3338] text-[#020303] rounded-xl py-4 pl-4 pr-12 gap-2 cursor-pointer',
                    {
                      'bg-[#0077FF] text-white shadow-game-mode-selected border-none':
                        selectedGameMode === gameMode,
                    }
                  )}
                  key={gameMode}
                  onClick={() => onSelectMode(gameMode)}
                >
                  {selectedGameMode === gameMode ? (
                    <FilledCheck width="20px" height="20px" className="mt-1" />
                  ) : (
                    <Circle width="20px" height="20px" className="mt-1" />
                  )}

                  <div className="flex flex-col">
                    <div className=" text-base font-semibold">
                      {gameDescription?.title}
                    </div>
                    <div className=" text-xs">{gameDescription?.help_text}</div>
                  </div>
                </div>
              )
            }
          )}

          {/* <Fragment>{Object.keys(multiplayerDurationData)?.map()}</Fragment> */}
          {/* <div className="flex flex-col"> */}
        </div>
        <div
          style={{ boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)' }}
          className="flex p-4 justify-between"
        >
          <div>
            <div className="text-xs">Have a code?</div>
            <div
              onClick={handleJoinWithCode}
              className="font-medium cursor-pointer"
            >
              Join us with code
            </div>
          </div>
          <button
            onClick={onCreateRoomClick}
            className={classNames(
              'font-semibold text-xl bg-[#F3D505] px-6 py-2 rounded-[45px] cursor-pointer',
              {
                'opacity-50 cursor-not-allowed': !selectedGameMode,
              }
            )}
            disabled={!selectedGameMode}
          >
            Create Room
          </button>
        </div>
      </div>
    </Modal>
  )
}
