import React, { useEffect, useState } from 'react'
import styles from './GameProductPurchaseBottomSheet.module.css'
import { AdIcon, CoinIcon, CoinImg, OfflineIcon } from '../icones'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { useGetStreaksMutation } from '../../../store/streaks/action'
import { setStreaks } from '../../../store/streaks/streakSlice'
import toast, { ErrorIcon } from 'react-hot-toast'
import {
  useGetGameProductDetailMutation,
  usePostGameProductPurchaseMutation,
} from '../../../store/game/actions'
import { Modal, ModalBody, Tooltip } from 'reactstrap'
import { sendAnalytics } from '../../../utils/mixpannelConn'

const GameProductPurchaseBottomSheet = ({
  isOpen,
  onClose,
  productId,
  gameName,
  gameId,
  growthFactor,
  notifyPurchaseCompleted,
  isLoggedIn,
  handleLoginFromBottomSheet,
}) => {
  const [product, setProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const isLarge = useMediaQuery('(min-width:579px)')
  const dispatch = useDispatch()
  const coin_balance = useSelector(
    (state) => state?.streaks?.data?.coin_balance
  )
  const [getStreaks] = useGetStreaksMutation()
  const [getGameProductDetail] = useGetGameProductDetailMutation()
  const [postGameProductPurchase] = usePostGameProductPurchaseMutation()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  let errorToastId = null

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }
  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  const fetchProduct = async () => {
    setIsLoading(true)
    try {
      const res = await getGameProductDetail({
        id: productId,
        body: { growthFactor },
      })
      const gameProductData = res.data.data
      setProduct(gameProductData)
      const properties = {
        GAME_NAME: gameName,
        GAME_UUID: gameId,
        PRODUCT_ID: productId,
        PRODUCT_TITLE: gameProductData.title,
        IS_OFFLINE: false,
        PRODUCT_TYPE: gameProductData.type,
      }

      sendAnalytics('track', properties, 'Power Clicked', ['mixpanel'])
    } catch (err) {
      setProduct(null)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (productId && isOnline) {
      if (isLoggedIn) {
        getUserStreakData()
      }
      fetchProduct()
    }
  }, [productId, isOnline])

  const getUserStreakData = async () => {
    try {
      const streakData = await getStreaks().unwrap()
      dispatch(setStreaks(streakData))
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (!coin_balance && isLoggedIn && isOnline) {
      getUserStreakData()
    }
  }, [coin_balance, isOnline])

  const purchaseGameProduct = async () => {
    if (!isOnline) {
      toast.error(
        'No internet connection. Please check your connection and try again.',
        {
          icon: <ErrorIcon />,
        }
      )
      return
    }

    const res = await postGameProductPurchase({
      id: productId,
      body: { growthFactor },
    })
    if (res.error) {
      toast.error(res.error.data.message, {
        icon: <ErrorIcon />,
      })
    } else if (res.data) {
      notifyPurchaseCompleted(productId, 'coin')
      const properties = {
        GAME_NAME: gameName,
        GAME_UUID: gameId,
        PRODUCT_ID: productId,
        PRODUCT_TITLE: product.title,
        COIN_TRANSACTION: true,
        REWARDED_AD: false,
        COIN_VALUE: product.selling_price,
        GROWTH_FACTOR: growthFactor,
        IS_OFFLINE: false
      }

      sendAnalytics('track', properties, 'In Game Product Purchased', [
        'mixpanel',
      ])
    }
    onClose()
  }

  const getCoins = (coins) => {
    if (coins === 0) {
      return coins
    }
    if (coins > 999) {
      return Math.floor(coins / 1000) + 'K'
    }
    return coins
  }

  const renderContent = () => {
    if (isLoading) {
      return <p className={styles.paragraph}>Loading....</p>
    }

    if (!isOnline) {
      return (
        <p className={styles.paragraph}>
          No internet connection. Please check your connection and try again.
        </p>
      )
    }

    if (!product) {
      return (
        <p className={styles.paragraph}>
          This Product is not available for Purchase at the moment. Try again
          later.
        </p>
      )
    }

    return (
      <>
        <h2 className={styles.title}>{product.title}</h2>
        <p className={styles.paragraph}>{product.description}</p>
        <div className={styles.buttonContainer}>
          {isLoggedIn ? (
            <button
              className={`${styles.button} ${styles.primary}`}
              onClick={purchaseGameProduct}
            >
              <CoinIcon className={styles.coin_icon} />
              {product.selling_price}
            </button>
          ) : (
            <button
              className={`${styles.button} ${styles.primary}`}
              onClick={() => {
                handleLoginFromBottomSheet({
                  productId,
                  growthFactor,
                })
              }}
            >
              <span className={styles.buttonText}>Login to use</span>
              <span className={styles.coinWrapper}>
                <CoinImg className={styles.coin_icon} />
                {product.selling_price}
              </span>
            </button>
          )}
          <button
            id="adbtn"
            className={`${styles.button} ${styles.ad}`}
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip}
            onClick={() => {
              toast.error('No ads available', {
                icon: <ErrorIcon />,
                duration: 3000,
                id: 'no-ads-toast',
              })
            }}
          >
            <AdIcon className={styles.coin_icon} /> Watch Ad
          </button>
          <Tooltip
            className={styles.customTooltip}
            placement="top"
            isOpen={tooltipOpen}
            target="adbtn"
            toggle={toggleTooltip}
          >
            <div className={styles.tooltipContent}>No ads available</div>
          </Tooltip>
        </div>
      </>
    )
  }

  if (isLarge) {
    return (
      <Modal isOpen={isOpen} toggle={onClose} className={styles.modal}>
        <ModalBody className={styles.modalBody}>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
          {coin_balance >= 0 && isOnline && (
            <div className={styles.coinBalanceContainer}>
              <CoinIcon className={styles.coinIcon} />
              <span className={styles.coinBalance}>
                {getCoins(coin_balance)}
              </span>
            </div>
          )}
          <div className={styles.imageContainer}>
            {isOnline ? (
              <img src="/Player.svg" alt="Illustration" />
            ) : (
              <OfflineIcon />
            )}
          </div>
          {renderContent()}
        </ModalBody>
      </Modal>
    )
  }

  return (
    <>
      {isOpen && <div className={styles.overlay} onClick={onClose}></div>}
      <div className={`${styles.bottomSheet} ${isOpen ? styles.open : ''}`}>
        <button className={styles.closeBtn} onClick={onClose}>
          &times;
        </button>
        {coin_balance && isOnline && (
          <div className={styles.coinBalanceContainer}>
            <CoinIcon className={styles.coinIcon} />
            <span className={styles.coinBalance}>{getCoins(coin_balance)}</span>
          </div>
        )}
        <div className={styles.imageContainer}>
          {isOnline ? (
            <img src="/Player.svg" alt="Illustration" />
          ) : (
            <OfflineIcon />
          )}
        </div>
        {renderContent()}
      </div>
    </>
  )
}

export default GameProductPurchaseBottomSheet
