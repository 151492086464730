import classNames from 'classnames'
import { PremiumCrown } from '../icones'
import { useTimer } from 'react-timer-hook'

export const PremiumTime = ({ expiresAt }) => {
  const expiryTimestamp = new Date(expiresAt)

  const timeLeft = useTimer({
    expiryTimestamp,
    onExpire: () => console.log(''),
  })
  const isDaysLeft = timeLeft.days > 0

  const isHoursLeft = timeLeft.days === 0 && timeLeft.hours > 0

  const isMinutesLeft =
    timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes > 0

  const isSecondsLeft =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds > 0

  const isNoTimeLeft =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0

  const timeToBeShown = () => {
    if (isDaysLeft) {
      return `${timeLeft.days}d`
    }
    if (isHoursLeft) {
      return `${timeLeft.hours}hr`
    }
    //MINUTES
    if (isMinutesLeft) {
      if (timeLeft.minutes < 9) {
        return `0${timeLeft.minutes}: ${timeLeft.seconds}s`
      }

      if (timeLeft.seconds < 9) {
        return `${timeLeft.minutes}: 0${timeLeft.seconds}s`
      }

      if (timeLeft.seconds < 9 && timeLeft.minutes < 9) {
        return `0${timeLeft.minutes}: 0${timeLeft.seconds}s`
      }

      return `${timeLeft.minutes}: ${timeLeft.seconds}s`
    }
    //SECONDS
    if (isSecondsLeft) {
      return timeLeft.seconds < 9
        ? `0${timeLeft.seconds}s`
        : `${timeLeft.seconds}s`
    }
  }

  return (
    <div
      className={classNames(
        'bg-black h-[24px] rounded-full z-50  flex items-center justify-center absolute top-[5%] ',
        {
          'left-[80%] w-[24px]': isNoTimeLeft,
          'right-0 mr-2 w-fit px-2 ': !isNoTimeLeft,
        }
      )}
    >
      <PremiumCrown />
      {!isNoTimeLeft && (
        <div className="flex gap-1 ml-2 text-white whitespace-nowrap">
          {timeToBeShown()}
        </div>
      )}
    </div>
  )
}
