import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'
import { store } from './store/index'
import { Toaster } from 'react-hot-toast'
import { Check } from './app/component/icones'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <Toaster
      position="top-center"
      reverseOrder={true}
      toastOptions={{
        style: {
          width: '100%',
          background: '#E7FFB3',
          color: 'black',
          padding: '1rem 2rem',
          borderRadius: '1rem',
        },
        icon: <Check style={{ width: '24px', height: '24px' }} />,
        duration: 3000,
      }}
    />
    <Provider store={store}>
      <App />
    </Provider>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
