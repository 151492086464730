import { Link } from 'react-router-dom'
import Footer from '../../component/Footer/Footer'
import { useEffect } from 'react'

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="max-w-[960px] px-4 mx-auto sm:px-6 lg:px-8 mt-10">
        <h1>
          About <b>Timepass Games</b>
        </h1>
        <p>
          We&rsquo;re growing fast to achieve a big mission that drives us every
          day.
        </p>
        <p>
          Redefining games like never before. We build simple and engaging games
          which are lightweight, simple in mechanics and free to play.
        </p>
        <h1>Legal Policies</h1>
        <p>
          This privacy policy sets out how <b>timepass.games</b> uses and
          protects any information that you give us when you use this website.
        </p>
        <p>
          <b>timepass.games</b> is committed to ensuring that your privacy is
          protected. Should we ask you to provide certain information by which
          you can be identified when using this website, then you can be assured
          that it will only be used in accordance with this privacy statement.
          Please remember that we may change this policy from time to time by
          updating this page, and you should check this page from time to time
          to ensure that you are happy with any changes we make. This policy is
          effective starting Oct 1st, 2022.
        </p>
        <h2>What we collect</h2>
        <p>We may collect the following information:</p>
        <ul>
          <li>Name</li>
          <li>Contact information including email address</li>
          <li>
            Information regarding services we offer that you might be interested
            in obtaining
          </li>
        </ul>
        <h2>What we do with the information we gather</h2>
        <p>
          We may obtain the above information from you to better understand your
          needs and provide you with information regarding the services we
          offer, and in particular for the following reasons:
        </p>
        <ul>
          <li>Internal record keeping</li>
          <li>
            We may use the information to offer our products and services.
          </li>
          <li>
            Using the email address you may provide us, we may periodically send
            informational newsletters and emails about our services or other
            information which we think you may find useful or interesting
            related to the services we offer.
          </li>
        </ul>
        <h2>Security</h2>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
        <h2>How we use cookies</h2>
        <p>
          A cookie is a small file which asks permission to be placed on your
          computer&rsquo;s hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>
        <p>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes.
        </p>
        <p>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </p>
        <p>
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>
        <h2>Links to other websites</h2>
        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide while visiting such sites and such sites
          are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>
        <h2>Controlling your personal information</h2>
        <p>
          If you have previously agreed to us collecting and using your personal
          information for any reason whatsoever, you may change your mind at any
          time by writing to or emailing us at hi@simpleviralgames.com. Changes
          you would like to make to how we possess or use your data include the
          following:
        </p>
        <ul>
          <li>
            <strong>Delete Data</strong>: You can ask us to erase or delete all
            or some of your personal data (e.g., if it is no longer necessary
            for the purposes set forth above).
          </li>
          <li>
            <strong>Change or Correct Data</strong>: You can edit some of your
            personal data through your account. You can also ask us to change,
            update or fix your data in certain cases, particularly if it&rsquo;s
            inaccurate.
          </li>
          <li>
            <strong>Object to, or Limit or Restrict, Use of Data</strong>: You
            can ask us to stop using all or some of your personal data (e.g., if
            we have no legal right to keep using it) or to limit our use of it
            (e.g., if your personal data is inaccurate or unlawfully held).
          </li>
          <li>
            <strong>Right to Access and/or Take Your Data</strong>: You can ask
            us for a copy of your personal data and can ask for a copy of
            personal data you provided in machine readable form.
          </li>
        </ul>
        <p>
          In the meantime, while we possess and use your information, we will
          not sell, distribute or lease your personal information to third
          parties unless we have your permission or are required by law to do
          so. Otherwise, we may use your personal information only consistent
          with the terms elsewhere in this policy.
        </p>
        <p>
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. If you would like a copy of
          the information held on you please write to or email us at
          hi@simpleviralgames.com.
        </p>
        <p>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </p>
        <h2>Change in control or sale</h2>
        <p>
          We can also share your personal data as part of a sale, merger or
          change in control, or in preparation for any of these events. Any
          other entity which buys us or part of our business will have the right
          to continue to use your data, but only in the manner set out in this
          Privacy Policy unless you agree otherwise.
        </p>
        <h2>Disclosure of your personal information</h2>
        <p>
          It is possible that we will need to disclose information about you
          when required by law, subpoena, or other legal process, or if we have
          a good faith belief that disclosure is reasonably necessary to (1)
          investigate, prevent, or take action regarding suspected or actual
          illegal activities or to assist government enforcement agencies; (2)
          enforce any existing or future agreements we might have with you, (3)
          investigate and defend ourselves against any third-party claims or
          allegations, (4) protect the security or integrity of our Service
          (such as by sharing with companies facing similar threats); or (5)
          exercise or protect the rights and safety of others. If such
          disclosure is required, we will make every reasonable effort to notify
          you about legal demands for their personal data when appropriate in
          our judgement, unless prohibited by law or court order or when the
          request is an emergency. We may dispute such demands when we believe,
          in our discretion, that the requests are overbroad, vague or lack
          proper authority, but we cannot promise to challenge every demand.
        </p>
      </div>
      <Footer />
    </>
  )
}
export default PrivacyPage
