import classNames from 'classnames'
import { ArrowLeft } from '../../component/icones'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export const CoinsPageHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const isTransactionPage = location?.pathname === '/coins/transactions'
  const isEarnCoinPage = location?.pathname === '/coins/earn'

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4 cursor-pointer">
        <ArrowLeft onClick={() => navigate('/')} />
        <div className="font-semibold text-[22px]">Coins</div>
      </div>
      <div className="flex justify-evenly">
        <Link
          to="/coins/transactions"
          className={classNames(
            'text-black p-4 text-center w-full font-medium',
            {
              'border-b-2 border-b-[#f3d505] border-solid': isTransactionPage,
            }
          )}
        >
          Transactions
        </Link>
        <Link
          to="/coins/earn"
          className={classNames(
            'text-black p-4 w-full text-center font-medium',
            {
              'border-b-2 border-b-[#f3d505] border-solid': isEarnCoinPage,
            }
          )}
        >
          Earn Coins
        </Link>
      </div>
    </div>
  )
}
