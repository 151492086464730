import { AndroidDownload, AppleDownload, DownloadLinkCheck } from '../icones'

export const TournamentLinkSentToast = ({
  mobileNumber,
  handlePressPlayStore,
}) => {
  return (
    <div className="flex flex-col justify-center items-center bg-white">
      <div className="styled-border"></div>
      <div className="flex flex-col justify-center gap-4 items-center mt-12">
        <DownloadLinkCheck />
        <div className="text-center font-semibold text-[20px]">
          Download link has been sent to {mobileNumber}
        </div>
        <div className="flex flex-row gap-2">
          <div onClick={() => handlePressPlayStore('Play Store')}>
            <a
              href="https://play.google.com/store/apps/details?id=com.simpleviralgames.timepass"
              target="_blank"
              rel="noreferrer"
            >
              <AndroidDownload />
            </a>
          </div>
          <div onClick={() => handlePressPlayStore('App Store')}>
            <a
              href="https://apple.co/3MHAuNq"
              target="_blank"
              rel="noreferrer"
              className="z-10"
            >
              <AppleDownload />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
