import { apiSlice } from '../../api/apiSlice'

export const otpSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyOTP: builder.mutation({
      query: (credentials) => ({
        url: '/user/verify',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
})

export const { useVerifyOTPMutation } = otpSlice
