export const bottomBarVisibleRoutes = [
  '/',
  '/reels',
  '/user/profile',
  '/coins/transaction',
  '/streaks',
  '/coins/earn',
  '/search',
]

export const isBottomBarVisible = () =>
  bottomBarVisibleRoutes?.some((route) => route === window.location.pathname)
