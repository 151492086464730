import { apiSlice } from '../api/apiSlice'

export const leaderboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderBoard: builder.mutation({
      query: (credentials) => {
        return {
          url: `game/${credentials.uuid}/leaderboard?page_number=${credentials.pg}&start_date=2023-11-01&end_date=2025-11-30`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const usersRankSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersRank: builder.mutation({
      query: (credentials) => {
        return {
          url: `game/${credentials.uuid}/userrank?start_date=2023-11-01&end_date=2025-11-30`,
        }
      },
    }),
  }),
})

export const { useGetUsersRankMutation } = usersRankSlice

export const { useGetLeaderBoardMutation } = leaderboardSlice
