import { useTimeOut } from './useTimeOut'
import { useEffect } from 'react'

export const useDebounce = (callback, delay, dependencies) => {
  const { reset, clear } = useTimeOut(callback, delay)

  useEffect(reset, [...dependencies, reset])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clear, [])
}
