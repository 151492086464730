import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
  otpSent: false,
  verifyOtp: false,
  country_code: '+91',
  mobile: '',
  otpValue: '',
  type: 'SMS',
  isModalOpen: false,
  verifyingOtp: false,
  name: '',
  refreshToken: JSON.parse(localStorage.getItem('refreshToken')),
  accessToken: JSON.parse(localStorage.getItem('accessToken')),
  avatars: [],
  selectedAvatar: '',
  gender: 'female',
  yearOfBirth: new Date().getFullYear().toString(),
  social_id: '',
}

const loginSlice = createSlice({
  name: 'login',
  initialState: initialValues,
  reducers: {
    setOtpToStore: (state, action) => {
      state.otpSent = action.payload
    },
    setCredentails: (state, action) => {
      const { accessToken, refreshToken, otpVerified } = action.payload
      if (otpVerified) {
        state.verifyOtp = true
        state.accessToken = accessToken
        state.refreshToken = refreshToken
      }
    },
    setUserInfo: (state, action) => {
      state.name = action?.payload?.name
      state.selectedAvatar = action?.payload?.selectedAvatar
      state.gender = action?.payload?.gender ?? 'male'
      state.yearOfBirth =
        action?.payload?.yearOfBirth ?? new Date().getFullYear().toString()
      state.mobile = action?.payload?.mobile
      state.country_code = action?.payload?.code
      state.social_id = action?.payload?.social_id
    },
    setAvatars: (state, action) => {
      state.avatars = action.payload
    },

    setCountryCode: (state, action) => {
      state.country_code = action.payload
    },
    setMobile: (state, action) => {
      state.mobile = action.payload
    },
    setOtpValue: (state, action) => {
      state.otpValue = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setOtpSent: (state, action) => {
      state.otpSent = action.payload
    },
    setVerifyOtp: (state, action) => {
      state.verifyOtp = action.payload
    },
    toggleModal: (state, action) => {
      state.isModalOpen = action.payload
    },
    setTokens: (state, action) => {
      const { access, refresh } = action.payload
      state.accessToken = access
      state.refreshToken = refresh
    },
    logout: (state, action) => {
      state.otpSent = false
      state.verifyOtp = false
      state.country_code = '+91'
      state.mobile = ''
      state.otpValue = ''
      state.type = 'SMS'
      state.isModalOpen = false
      state.verifyingOtp = false
      state.refreshToken = ''
      state.accessToken = ''
    },
  },
})

export const {
  setCountryCode,
  setMobile,
  setOtpValue,
  setType,
  setOtpSent,
  setVerifyOtp,
  toggleModal,
  setTokens,
  logout,
  setCredentails,
  setOtpToStore,
  setAvatars,
  setSelectedAvatar,
  setUserInfo,
} = loginSlice.actions

export default loginSlice.reducer
