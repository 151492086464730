import { ShowToast } from '../app/component/Common/ToastComponent'

import toast from 'react-hot-toast'

export const showNotification = (notification) => {
  if (notification?.length === 0) return
  notification?.forEach((data, idx) =>
    setTimeout(() => {
      toast(<ShowToast toastData={data} />, { icon: null })
    }, (idx + 2) * 2000)
  )
}
