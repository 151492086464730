import { apiSlice } from '../api/apiSlice'

export const agoraSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgoraToken: builder.mutation({
      query: (credentials) => {
        return {
          url: `/communication/agora/token`,
          method: 'POST',
          body: { ...credentials.body },
          headers: {
            'anonymous-user-id': credentials.id,
          },
        }
      },
    }),
  }),
})

export const hmsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHmsToken: builder.mutation({
      query: (credentials) => {
        return {
          url: '/communication/hundredms/token',
          method: 'POST',
          body: { ...credentials.body },
          headers: {
            'anonymous-user-id': credentials.id,
          },
        }
      },
    }),
  }),
})

export const agoraUsersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgoraUsers: builder.mutation({
      query: () => {
        return {
          url: `/user/avatars`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const createRoomSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoomInfo: builder.mutation({
      query: (credentails) => {
        return {
          url: '/multiplayer/room/create',
          method: 'POST',
          body: { ...credentails.body },
          headers: {
            'anonymous-user-id': credentails.id,
          },
        }
      },
    }),
  }),
})

export const joinRoomSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJoinRoomInfo: builder.mutation({
      query: (credentails) => {
        return {
          url: `/multiplayer/room/${credentails.roomId}/join`,
          method: 'POST',
          body: { ...credentails.body },
          headers: {
            'anonymous-user-id': credentails.id,
          },
        }
      },
    }),
  }),
})

export const leaveRoomSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    leaveRoom: builder.mutation({
      query: (credentails) => {
        return {
          url: `/multiplayer/room/${credentails.roomId}/leave`,
          method: 'POST',
          body: { ...credentails.body },
          headers: {
            'anonymous-user-id': credentails.id,
          },
        }
      },
    }),
  }),
})

export const startGameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startRoomInfo: builder.mutation({
      query: (credentails) => {
        return {
          url: `/multiplayer/room/${credentails.roomId}/start`,
          method: 'POST',
          body: { ...credentails.body },
          headers: {
            'anonymous-user-id': credentails.id,
          },
        }
      },
    }),
  }),
})

export const participantsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParticipantsInfo: builder.mutation({
      query: (credentails) => {
        return {
          url: `/multiplayer/room/${credentails.room_id}/participants`,
          method: 'POST',
          body: { ...credentails.body },
          headers: {
            'anonymous-user-id': credentails.id,
          },
        }
      },
    }),
  }),
})

export const mpVoiceChatSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMpVcConfig: builder.mutation({
      query: (gameId) => {
        return {
          url: `reward/voice-chat/${gameId}`,
          method: 'GET',
        }
      },
    }),
    postMpVcConfig: builder.mutation({
      query: (gameId) => {
        return {
          url: `reward/voice-chat/${gameId}`,
          method: 'POST',
        }
      },
    }),
  }),
})

export const { useGetMpVcConfigMutation, usePostMpVcConfigMutation } =
  mpVoiceChatSlice

export const { useLeaveRoomMutation } = leaveRoomSlice

export const { useGetParticipantsInfoMutation } = participantsSlice

export const { useStartRoomInfoMutation } = startGameSlice

export const { useGetJoinRoomInfoMutation } = joinRoomSlice

export const { useGetAgoraUsersMutation } = agoraUsersSlice

export const { useGetRoomInfoMutation } = createRoomSlice

export const { useGetAgoraTokenMutation } = agoraSlice

export const { useGetHmsTokenMutation } = hmsSlice
