import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  purchaseData: [],
  earnCoinsData: [],
  purchaseTotalpages: 1000,
}

export const coinsSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setPurchaseHistory: (state, action) => {
      state.purchaseData = action.payload.data
      state.purchaseTotalpages = action.payload.totalpage
    },
    setEarnCoinsData: (state, action) => {
      state.earnCoinsData = action.payload
    },
  },
})

export const { setPurchaseHistory, setEarnCoinsData } = coinsSlice.actions
export default coinsSlice.reducer
