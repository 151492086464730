import { createSlice } from '@reduxjs/toolkit/dist'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
}

export const allGameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGames: (state, action) => {
      state.data = action.payload
      state.isSuccess = true
    },
  },
  // extraReducers: {
  // 	[getGames.pending]: state => {
  // 		state.isLoading = true
  // 	},
  // 	[getGames.fulfilled]: (state, { payload }) => {
  // 		state.isLoading = false
  // 		state.data = payload
  // 		state.isSuccess = true
  // 	},
  // 	[getGames.rejected]: state => {
  // 		state.isLoading = false
  // 		state.isSuccess = false
  // 	},
  // },
})

export const { setGames } = allGameSlice.actions

export default allGameSlice.reducer
