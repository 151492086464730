import { CoinsPageHeader } from '../../component/Coin/CoinPageHeader'
import { setPurchaseHistory } from '../../../store/coins/slice'
import { useGetPurchaseHistoryMutation } from '../../../store/coins/action'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { formatDateToDDMonth } from '../../../utils/formatDate'
import { isBottomBarVisible } from '../../../utils/constansts'
import { BottomBar } from '../../component/Common/BottomBar'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import { CoinImg } from '../../component/icones'
import { useLocation } from 'react-router'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { CoinTransactionModal } from '../../component/Coin/CoinTransactionModal'

export const CoinTransaction = () => {
  const [getPurchaseHistory, { isLoading: isPurcahseHistoryLoading }] =
    useGetPurchaseHistoryMutation()

  const { getUserStreakData } = useProfileUpdate()

  const [isCoinTranctionModalOpen, setOpenCoinTranSactionModal] =
    useState(false)

  const [currentTransactionData, setCurrentTransactionData] = useState()

  const streaks = useSelector((state) => state.streaks)

  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('100px')

  const loginState = useSelector((state) => state.login)

  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch()

  const coins = useSelector((state) => state?.coins)

  const getPurchses = async (pg) => {
    try {
      const res = await getPurchaseHistory(pg).unwrap()
      dispatch(
        setPurchaseHistory({
          data:
            currentPage === 1
              ? res.results
              : [...coins?.purchaseData, ...res?.results],
          totalpage: res?.total_page,
        })
      )
      setCurrentPage((prev) => prev + 1)
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  useEffect(() => {
    if (isVisible && coins?.purchaseTotalpages > currentPage) {
      getPurchses(currentPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  useEffect(() => {
    if (!streaks.data) {
      getUserStreakData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaks])

  useEffect(() => {
    if (
      !!location?.state?.from &&
      coins?.purchaseData?.length > 0 &&
      streaks?.data?.coin_balance
    ) {
      const properties = {
        OPENED_FROM: location?.state?.from === '/' ? 'Home' : 'Direct Landing',
        USER_COINS: streaks?.data?.coin_balance,
      }
      sendAnalytics('track', properties, 'Coins Screen Opened', [
        'moengage',
        'mixpanel',
      ])
    }
  }, [location, streaks, coins])

  const handleCoinTransactionClick = (data) => {
    setCurrentTransactionData(data)
    setOpenCoinTranSactionModal(true)
  }

  const toggleCoinTransactionModal = () => {
    setOpenCoinTranSactionModal(false)
  }

  return (
    <div className="p-4 flex flex-col gap-4 w-full xl:w-[60%] mx-auto fixed h-[90vh] md:static md:h-full overflow-y-scroll">
      <CoinsPageHeader />
      <CoinTransactionModal
        isOpen={isCoinTranctionModalOpen}
        toggle={toggleCoinTransactionModal}
        currentTransactionData={currentTransactionData}
      />
      <div className="flex flex-col gap-4">
        {coins?.purchaseData?.map((data) => {
          const date = formatDateToDDMonth(data?.created_at)
          return (
            <div
              className="bg-[#f6f7f9] flex items-center justify-between px-4 xl:px-5 py-2 xl:py-3 rounded-xl"
              onClick={() => handleCoinTransactionClick(data)}
            >
              <div className="flex flex-col gap-[6px]">
                <div className="font-semibold w-[20ch] md:w-full">
                  {data?.metadata?.message ?? data?.metadata?.type}
                </div>
                <div>{date}</div>
              </div>
              <div className="flex gap-[4px] items-center">
                <CoinImg className="w-6 h-6" />
                <div className=" text-lg">
                  {data?.transaction_type === 'credit' ? '+' : '-'}{' '}
                  {data?.transaction_amount}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div ref={toBeIntersectedImgRef}></div>
      <BottomBar token={loginState.accessToken} />
    </div>
  )
}
