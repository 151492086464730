export const handleCopyUrl = (url, toast) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toast.dismiss()
      toast.success('Link copied to clipboard')
    })
    .catch((error) => {
      console.error('Failed to copy URL to clipboard:', error)
    })
}
