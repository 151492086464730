import { useRef, useState, useEffect } from 'react'
import { useOnScreen } from '../../../hooks/useOnScreen'
import { GameCard } from '../Common/GameCard'
import { GamesBarWrapper } from '../Common/GamesBarWrapper'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'

export const CategoryComponent = ({
  games,
  categoryId,
  totalpages,
  fetchSingleCategory,
  handleAllGamesClicked,
}) => {
  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('100% 0% 100% 0%')
  const [categoryGames, setCategoryGames] = useState(games.games)
  const [currentPage, setCurrentPage] = useState(1)

  const fetchNextPage = async (categoryId, pg) => {
    try {
      const data = await fetchSingleCategory(categoryId, pg)
      setCategoryGames((prev) => [...prev, ...data.results.games])
      setCurrentPage((prev) => prev + 1)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isVisible && totalpages?.[categoryId] >= currentPage + 1) {
      fetchNextPage(categoryId, currentPage + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <>
      <p className="text-lg text-black font-bold leading-6 sm:pl-0 pl-2 pt-4">
        {games.name}
      </p>
      <GamesBarWrapper>
        {categoryGames?.map((data, idx) => {
          return (
            <GameCard
              data={data}
              idx={idx}
              ref={toBeIntersectedImgRef}
              handleGameCardClick={handleAllGamesClicked}
              allGamesLength={categoryGames?.length}
              categoryId={categoryId}
              currentPage={currentPage}
            />
          )
        })}
      </GamesBarWrapper>
    </>
  )
}
