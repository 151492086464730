import { useRef, useEffect } from 'react'

export const useEventListener = (event, callback, element = window) => {
  const callbackRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element === null) return
    element.addEventListener(event, (e) => callbackRef.current(e))
    return () =>
      element.removeEventListener(event, (e) => callbackRef.current(e))
  }, [event, element])
}
