import { apiSlice } from '../api/apiSlice'

export const userReelRecomendationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userReelRecomendation: builder.mutation({
      query: (page) => {
        const pg = page ?? 1
        return {
          url: `/game/recommendation?language=en&platform=WEB&p=${pg}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useUserReelRecomendationMutation } = userReelRecomendationSlice
