import { apiSlice } from '../api/apiSlice'

export const getCoinsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEarnCoins: builder.mutation({
      query: () => {
        return {
          url: `reward/earn`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const getCoinsPurchaseSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseHistory: builder.mutation({
      query: (pg) => {
        return {
          url: `reward/transaction/history?p=${pg}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useGetEarnCoinsMutation } = getCoinsSlice

export const { useGetPurchaseHistoryMutation } = getCoinsPurchaseSlice
