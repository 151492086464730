import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: undefined,
}

export const setStreaksSlice = createSlice({
  name: 'streaks',
  initialState,
  reducers: {
    setStreaks: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setStreaks } = setStreaksSlice.actions
export default setStreaksSlice.reducer
