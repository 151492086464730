import { Modal } from 'reactstrap'
export const PreCreateRoom = ({
  gameLogo,
  onCreateRoomClick,
  isPreCreateRoomModalOpen,
}) => {
  return (
    <Modal
      isOpen={isPreCreateRoomModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="px-7 pt-4 flex flex-col justify-center items-center gap-8 pb-7">
        <div className="styled-border"></div>
        <img
          src={gameLogo}
          className="rounded-xl h-14 w-14"
          alt="game-logo-icon"
        />
        <div className="flex flex-col justify-center items-center">
          <p className="text-xl font-semibold mb-2">
            Select the number of players
          </p>
          <p className="text-sm font-normal mb-0">Maximum 10 players allowed</p>
        </div>
        <div className="flex [&>*]:w-24 [&>*]:h-32 gap-1">
          <img src="/precreateRoom1.png" alt="createRoomImg" />
          <img src="/precreateRoom2.png" alt="createRoomImg" />
          <img src="/precreateRoom3.png" alt="createRoomImg" />
        </div>
        <button
          className="text-xl bg-[#F3D505] py-[10px] px-6 font-medium rounded-3xl"
          onClick={() => onCreateRoomClick('preCreateRoom')}
        >
          Create Room
        </button>
      </div>
    </Modal>
  )
}
