import { useDispatch, useSelector } from 'react-redux'
import { useSearch } from '../../../hooks/useSearch'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { removeAllCategory } from '../../../store/ViewAll/categorySlice'
import { isBottomBarVisible } from '../../../utils/constansts'
import { BottomBar } from '../../component/Common/BottomBar'
import { ArrowLeft, CloseXIcon, TimePassNewLogo } from '../../component/icones'
import { useNavigate } from 'react-router'
import { PremiumCard } from '../../component/Common/PremiumCard'
import { BuyPremiumGameModal } from '../../component/Common/BuyPremiumGameModal'
import { usePostPremiumConfigMutation } from '../../../store/premium/action'
import { useUserReelRecomendationMutation } from '../../../store/recommendation/action'
import { setRecommendations } from '../../../store/recommendation/slice'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { removeAllCategoryId } from '../../../store/categoryId/categoryIdSlice'
import { convertString } from '../../../utils/maniputeString'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useSearchParams } from 'react-router-dom'
import LoginModal from '../../component/Common/LoginModal'
import { useLoginInfo } from '../../../hooks/useLoginInfo'
import OtpModal from '../../component/Common/OtpModal'
import { setOtpToStore } from '../../../store/login/loginSlice'

export const SearchGames = () => {
  const allCategory = useSelector((state) => state.allCategory)

  const allCategoryId = useSelector((state) => state.allCategoryId)

  const loginState = useSelector((state) => state.login)

  const recommendation = useSelector((state) => state.recommendation)

  const [isValidCat, setIsValidCat] = useState(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [postPremiumConfig, { isLoading: isPremiumPostGameConfigLoading }] =
    usePostPremiumConfigMutation()

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    counter,
    handleGetOtp,
    handleMobileNumberChange,
    isCorrectMobileNumber,
    otp,
    handleSubmitOTP,
    setOtp,
    handleResendOTP,
    mobileNumber,
    otpSent,
    isVerifyOtpLoading,
    setCounter,
    isNormalLogin,
    setNormalLogin,
    countryCode,
  } = useLoginInfo()

  const {
    homePageModals,
    handleNavigationHistoryAndNotif,
    premiumGameConfig,
    togglePremiumGameModal,
    handleCancelBuy,
    fetchCategories,
    fetchSingleCategory,
    getAllCategoryId,
    recordHistoryAndSendNotif,
    getUserSavedGames,
    isAllCategoryLoading,
    isAllCategoriesLoading,
    setHomePageModals,
    getPremiumData,
    setPremiumGameConfig,
  } = useProfileUpdate()

  const [userReelRecomendation, { isLoading: isReelRecomendationLoading }] =
    useUserReelRecomendationMutation()

  const getUserReelRecomendation = async (page) => {
    try {
      const allRecommds = await userReelRecomendation(page).unwrap()

      const updatedRecommends = allRecommds?.results?.map((reel) => ({
        ...reel,
        muted: true,
      }))

      dispatch(
        setRecommendations({
          data:
            page === 1
              ? [...updatedRecommends]
              : [...recommendation?.data, ...updatedRecommends],
          totalpage: allRecommds.total_page,
        })
      )
      return allRecommds
    } catch (error) {
      console.log(error)
    }
  }

  const handleConsumePlay = async () => {
    try {
      const res = await postPremiumConfig(premiumGameConfig.uid).unwrap()
      if (res?.is_bought) {
        const recordHistoryRes = await recordHistoryAndSendNotif(
          premiumGameConfig.uid,
          premiumGameConfig.game_name,
          premiumGameConfig.updatedAt
        )
        const properties = {
          GAME_NAME: premiumGameConfig.game_name,
          GAME_UUID: premiumGameConfig.uid,
        }

        sendAnalytics('track', properties, 'Buy Premium Game', [
          'moengage',
          'mixpanel',
        ])
        if (recordHistoryRes.message === 'Game history saved.') {
          navigate(`/${premiumGameConfig.slug}`)
          dispatch(removeAllCategory())
          fetchCategories(allCategoryId?.data)
          getUserSavedGames(1)
          getUserReelRecomendation(1)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const catFromSearch = searchParams.get('cat')
  const termFromSearch = searchParams.get('term')

  const {
    toBeIntersectedImgRef,
    handleSearchChange,
    handleCategoryClick,
    searchInput,
    selectedCategory,
    gamesToBeShown,
    debouncedSearch,
    setGamesToBeShown,
    isSearchEmpty,
    isVisible,
    setSelectedCategory,
    setDebouncedSearch,
    setSearchInput,
    getSearchResults,
    isSearchLoading,
    setIsSearchEmpty,
  } = useSearch(allCategory)

  const isCategoryOrSearchLoading = isSearchLoading || isAllCategoriesLoading

  useEffect(() => {
    dispatch(removeAllCategoryId())
    getAllCategoryId().then((res) => {
      if (res && allCategory?.data?.length === 0) {
        dispatch(removeAllCategory())
        fetchCategories(res)
      }
    })
  }, [])

  const fetchNextPage = async (categoryId, pg) => {
    try {
      const data = await fetchSingleCategory(categoryId, pg)

      const newRes = {
        ...data,
        results: {
          ...data?.results,
          games: [...gamesToBeShown?.results?.games, ...data?.results?.games],
        },
      }
      setGamesToBeShown(newRes)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (allCategory?.data?.length > 0) {
      const isValidCat = allCategory?.data?.find(
        (games) => games.results.uuid === catFromSearch
      )

      if (!!catFromSearch && isValidCat) {
        setSelectedCategory(catFromSearch)
        setGamesToBeShown(isValidCat)
        setIsValidCat(true)
        if (!!termFromSearch) {
          getSearchResults(termFromSearch)
          setDebouncedSearch(termFromSearch)
          setSearchInput(termFromSearch)
        }
      } else {
        setSelectedCategory('all-games')
        const allGames = allCategory?.data?.find(
          (games) => games.results.uuid === 'all-games'
        )
        setGamesToBeShown(allGames)
      }
    }
  }, [catFromSearch, termFromSearch, allCategory])

  useEffect(() => {
    if (
      isVisible &&
      gamesToBeShown?.current_page < gamesToBeShown?.total_page &&
      !debouncedSearch
    ) {
      fetchNextPage(selectedCategory, gamesToBeShown?.current_page + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const handleGameClick = (
    slug,
    isPremium,
    isMultiPlayer,
    uid,
    game_name,
    updatedAt,
    expiresAt,
    categoryId,
    currentPage
  ) => {
    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }

  const isBottomBar = isBottomBarVisible()

  const areThereLessThanThreeGames =
    gamesToBeShown?.results?.games?.length < 3 ||
    gamesToBeShown?.results?.length < 3

  const areThereMoreThanSixGames =
    gamesToBeShown?.results?.games?.length > 6 ||
    gamesToBeShown?.results?.length > 6

  const handleCloseXIcon = () => {
    setDebouncedSearch('')
    setSearchInput('')
    if (isValidCat) {
      const indexOfSelectedCat = allCategory?.data?.findIndex(
        (data) => data.results.uuid === selectedCategory
      )
      const container = containerRef.current
      const element = container.childNodes[indexOfSelectedCat]
      element.scrollIntoView({ behavior: 'smooth' })
    } else {
      setSelectedCategory('all-games')
      const allGames = allCategory?.data?.find(
        (games) => games.results.uuid === 'all-games'
      )
      setGamesToBeShown(allGames)
      searchParams.delete('cat')
      searchParams.delete('term')
      setSearchParams(searchParams)
    }
  }

  const handleGoBackFromSearch = () => {
    navigate('/')
  }

  const isLarge = useMediaQuery('(min-width:768px)')

  const fetchNextPageOfSearch = () => {
    getSearchResults(debouncedSearch)
  }

  const fetchNextPageOfCat = async () => {
    try {
      const data = await fetchSingleCategory(
        selectedCategory,
        gamesToBeShown?.current_page + 1
      )
      const newRes = {
        ...data,
        results: {
          ...data?.results,
          games: [...gamesToBeShown?.results?.games, ...data?.results?.games],
        },
      }
      setGamesToBeShown(newRes)
    } catch (error) {
      console.error(error)
    }
  }

  const handleViewMore = async () => {
    if (gamesToBeShown?.results?.length > 0) {
      fetchNextPageOfSearch()
    }

    if (gamesToBeShown?.results?.games?.length > 0) {
      fetchNextPageOfCat()
    }
  }

  const containerRef = useRef(null)

  const handleClick = (uuid, index) => {
    handleCategoryClick(uuid)
    if (uuid !== selectedCategory) {
      const container = containerRef.current
      const element = container.childNodes[index]
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleEnterPhoneNumber = async () => {
    const res = await handleGetOtp()
    if (res?.message === 'OTP Sent Successfully') {
      setHomePageModals((prev) => {
        return {
          ...prev,
          isLoginModalOpen: false,
          isOtpModalOpen: true,
        }
      })
    }
  }

  const handleToggleOtp = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isOtpModalOpen: false,
      }
    })
  }

  const isNoResultsVisible = !!isSearchEmpty && !!searchInput

  useEffect(() => {
    if (!gamesToBeShown?.total_hits && !gamesToBeShown?.count) {
      const cat = allCategory?.data?.find(
        (games) => games.results.uuid === selectedCategory
      )
      setGamesToBeShown(cat)
    }
  }, [gamesToBeShown])

  const toggleLoginModal = () => {
    setNormalLogin(false)

    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: !prev.isLoginModalOpen,
      }
    })
  }

  const handleChangeNumber = () => {
    dispatch(setOtpToStore(false))
    setOtp('')
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: true,
        isOtpModalOpen: false,
      }
    })

    const properties = {
      SHOWN_FROM: 'Home',
    }

    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleSubmitOTPAndCloseModals = async () => {
    const res = await handleSubmitOTP()
    if (res?.message === 'Logged In') {
      setHomePageModals((prev) => ({
        ...prev,
        isOtpModalOpen: false,
        isLoginModalOpen: false,
      }))
      if (!!premiumGameConfig?.slug) {
        const premiumData = await getPremiumData(
          premiumGameConfig?.uid,
          premiumGameConfig?.slug
        )

        if (premiumData?.is_bought) {
          navigate(`/${premiumGameConfig?.slug}`, { state: { from: '/reels' } })
          recordHistoryAndSendNotif(
            premiumGameConfig?.uid,
            premiumGameConfig?.name,
            premiumGameConfig?.updatedAt
          )
          return
        }
        //code should always run from here
        setHomePageModals((prev) => {
          return {
            ...prev,
            isBuyPremiumGameModalOpen: true,
          }
        })

        setPremiumGameConfig({
          ...premiumData,
          uid: premiumGameConfig?.uid,
          game_name: premiumGameConfig?.game_name,
          slug: premiumGameConfig?.slug,
          updatedAt: premiumGameConfig?.updatedAt,
          categoryId: 'recommendation',
          currentPage: 1,
        })
      }
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div className="block md:flex flex-col items-center justify-center">
      <BuyPremiumGameModal
        isBuyPremiumGameModalOpen={homePageModals.isBuyPremiumGameModalOpen}
        togglePremiumGameModal={togglePremiumGameModal}
        gameName={premiumGameConfig.game_name}
        handleCancelBuy={handleCancelBuy}
        handleConsumePlay={handleConsumePlay}
        gameCost={premiumGameConfig.game_cost}
        gameDuration={premiumGameConfig?.validity}
        uid={premiumGameConfig.uid}
      />
      <LoginModal
        isOpen={homePageModals?.isLoginModalOpen}
        toggle={toggleLoginModal}
        mobileNumber={`${countryCode}${mobileNumber}`}
        handleMobileNumberChange={handleMobileNumberChange}
        handleGetOtp={handleEnterPhoneNumber}
        isCorrectMobileNumber={isCorrectMobileNumber} // Assuming this function is defined
        isNormalLogin={isNormalLogin}
      />
      <OtpModal
        isOpen={homePageModals?.isOtpModalOpen}
        toggle={handleToggleOtp}
        mobileNumber={`${countryCode}${mobileNumber}`}
        otp={otp}
        setOtp={setOtp}
        handleChangeNumber={handleChangeNumber}
        handleSubmitOTP={handleSubmitOTPAndCloseModals}
        handleResendOTP={handleResendOTP}
        otpSent={otpSent} // Assuming this variable is defined
        counter={counter} // Assuming this variable is defined
        isVerifyOtpLoading={isVerifyOtpLoading} // Assuming this variable is defined
      />
      <div className="flex flex-col gap-2 justify-center pt-4 px-2 md:w-[65vw] ">
        <div
          className="header1 hidden  md:block self-start cursor-pointer"
          onClick={() => navigate('/')}
        >
          <div className="w-[64px] h-[35px] md:w-[87px] md:h-[46px]">
            <TimePassNewLogo />
          </div>
        </div>
        <div className=" flex flex-col p-2 gap-[1rem]">
          <div className="flex justify-end relative items-center">
            <input
              onChange={handleSearchChange}
              value={searchInput}
              type="text"
              className="search-ip border-2 border-[#e5e5e6]  px-10 md:px-16 py-2 rounded-full w-full focus:outline-[#285dc6]"
              placeholder="Search games & categories"
              ref={inputRef}
            />
            <span
              className="back-icon absolute right-[97%] top-[31%] z-30"
              onClick={handleGoBackFromSearch}
            >
              <ArrowLeft
                width="20px"
                height="20px"
                stroke="black"
                fill="#fff"
                className="cursor-pointer opacity-100  absolute transition-opacity duration-300 focus:opacity-0 focus:pointer-events-none"
                strokeWidth={2.5}
              />
            </span>
            {searchInput && (
              <span
                className="search-icon absolute right-[2%] top-[25%] cursor-pointer"
                onClick={handleCloseXIcon}
              >
                <CloseXIcon
                  width="20px"
                  height="20px"
                  stroke="black"
                  fill="#fff"
                  className="cursor-pointer opacity-100 pointer-events-auto absolute transition-opacity duration-300 focus:opacity-0 focus:pointer-events-none"
                  strokeWidth={2.5}
                />
              </span>
            )}
          </div>
          <div className="font-semibold text-base">Categories</div>
          <div
            className="flex gap-2 w-full overflow-y-scroll no-scrollbar"
            ref={containerRef}
          >
            {allCategory?.data?.map((cat, index) => {
              return (
                <div
                  className={classNames(
                    'border-[1px] border-solid border-black whitespace-nowrap px-4 py-2 rounded-full cursor-pointer',
                    {
                      'bg-[#f3d505]': cat.results.uuid === selectedCategory,
                      'order-[-1]': cat.results.uuid === 'all-games',
                    }
                  )}
                  onClick={() => handleClick(cat.results.uuid, index)}
                  key={cat.results.uuid}
                >
                  {cat.results.name}
                </div>
              )
            })}
          </div>
          {!isNoResultsVisible && selectedCategory && (
            <div className="font-semibold text-base flex gap-1">
              <span>{convertString(selectedCategory)}</span>
              <span>
                ({`${gamesToBeShown?.total_hits ?? gamesToBeShown?.count}`})
              </span>
            </div>
          )}
        </div>
        <div>
          {isSearchEmpty && searchInput.length > 0 && (
            <div className="font-semibold px-2 md:px-4 mb-2">
              No search results for {searchInput}. Here are some games you may
              like.
            </div>
          )}
        </div>
        <div className="top-[20vh] left-0 right-0 bottom-[10%] no-scrollbar  overflow-y-scroll pb-20">
          <div
            className={classNames(
              `flex flex-wrap justify-center no-scrollbar gap-[1rem]  md:justify-center xl:gap-4 md:gap-y-6 lg:gap-y-6 w-full`,
              {
                'md:flex md:flex-wrap': areThereLessThanThreeGames,
                'md:flex justify-center items-center':
                  isCategoryOrSearchLoading && selectedCategory,
                'md:grid md:grid-cols-5 2xl:grid-cols-6':
                  !isCategoryOrSearchLoading && selectedCategory,
              }
            )}
          >
            {selectedCategory &&
              !isCategoryOrSearchLoading &&
              gamesToBeShown?.results?.games?.map((data, idx) => {
                const premiumGameBought = data?.is_premium && !!data?.expires_at
                const premiumGameNotBought =
                  data?.is_premium && !data?.expires_at
                return (
                  <div
                    onClick={() => {
                      handleGameClick(
                        data?.slug,
                        data?.is_premium,
                        data?.multiplayer,
                        data?.uuid,
                        data?.name,
                        data?.updated_at,
                        data?.expires_at,
                        selectedCategory
                      )
                    }}
                    key={data?.uuid}
                    className="relative cursor-pointer"
                  >
                    <PremiumCard
                      premiumGameNotBought={premiumGameNotBought}
                      premiumGameBought={premiumGameBought}
                      expiresAt={data?.expires_at}
                    />
                    <img
                      src={data.property.thumbnail}
                      className={classNames(
                        'w-40 h-36 md:h-full rounded-xl md:w-full',
                        {
                          'md:w-40 md:h-36': areThereLessThanThreeGames,
                        }
                      )}
                      alt="thumbnail"
                    />
                  </div>
                )
              })}

            {debouncedSearch &&
              !isCategoryOrSearchLoading &&
              gamesToBeShown?.results?.length > 0 &&
              gamesToBeShown?.results?.map((data, idx) => {
                const premiumGameBought = data?.is_premium && !!data?.expires_at
                const premiumGameNotBought =
                  data?.is_premium && !data?.expires_at
                return (
                  <div
                    onClick={() => {
                      handleGameClick(
                        data?.slug,
                        data?.is_premium,
                        data?.multiplayer,
                        data?.uuid,
                        data?.name,
                        data?.updated_at,
                        data?.expires_at,
                        selectedCategory
                      )
                    }}
                    className="relative cursor-pointer"
                    key={data?.uuid}
                  >
                    <PremiumCard
                      premiumGameNotBought={premiumGameNotBought}
                      premiumGameBought={premiumGameBought}
                      expiresAt={data?.expires_at}
                    />
                    <img
                      src={data.property.thumbnail}
                      className="w-40 h-36 md:h-full rounded-xl md:w-full"
                      alt="thumbnail"
                    />
                  </div>
                )
              })}

            {isCategoryOrSearchLoading && selectedCategory && (
              <div className="text-center text-[#0077FF] cursor-pointer  py-4">
                <span className="circular-loader"></span>
              </div>
            )}
          </div>

          {!isCategoryOrSearchLoading && gamesToBeShown?.next && (
            <div
              className="text-center text-[#0077FF] cursor-pointer font-medium py-4"
              onClick={handleViewMore}
            >
              View More
            </div>
          )}
        </div>
        {isBottomBar && <BottomBar token={loginState.accessToken} />}
      </div>
    </div>
  )
}
