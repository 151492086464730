import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '../store/login/loginSlice'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { Error } from '../app/component/icones'
import {
  useUserAllCategoryIdMutation,
  useUserAllCategoryMutation,
  useUserContinuePlayMutation,
  useUserHistoryMutation,
  useUserProfileMutation,
  useUserSavedGamesMutation,
} from '../store/user/actions'

import { useLocation, useNavigate } from 'react-router'
import { showNotification } from '../utils/showNotification'
import { useUserRecordHistoryMutation } from '../store/user/actions'
import { useGetPremiumConfigMutation } from '../store/premium/action'
import { useGetStreaksMutation } from '../store/streaks/action'
import { setUserHistory } from '../store/user/slice'
import { setStreaks } from '../store/streaks/streakSlice'
import { setSavedGames } from '../store/SavedGames/savedSlice'
import { setContinuePlay } from '../store/ContinuePlay/continuePlaySlice'
import { setAllCategory } from '../store/ViewAll/categorySlice'
import { setAllCategoryId } from '../store/categoryId/categoryIdSlice'
import { sendAnalytics } from '../utils/mixpannelConn'
import { useSearchParams } from 'react-router-dom'

export const useProfileUpdate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)

  const [searchParams, setSearchParams] = useSearchParams()

  const [putUserProfile, { isLoading: isPutUserProfileLoading }] =
    useUserProfileMutation()

  const [userSavedGames, { isLoading: isSavedGamesLoading }] =
    useUserSavedGamesMutation()

  const [premiumGameConfig, setPremiumGameConfig] = useState({})

  const [getPremiumConfig, { isLoading: isPremiumGameConfigLoading }] =
    useGetPremiumConfigMutation()

  const [getStreaks, { isLoading: isStreaksLoading }] = useGetStreaksMutation()

  const [isAllCategoriesLoading, setIsAllCategoryLoading] = useState(false)

  const [userRecordHistory] = useUserRecordHistoryMutation()

  const [userHistory, { isLoading: isHistoryLoading }] =
    useUserHistoryMutation()

  const [userContinuePlay, { isLoading: isContinuePlayLoading }] =
    useUserContinuePlayMutation()

  const [userAllCategory, { isLoading: isAllCategoryLoading }] =
    useUserAllCategoryMutation()

  const [userAllCategoryId] = useUserAllCategoryIdMutation()

  const savedGames = useSelector((state) => state.savedGames)

  const continuePlay = useSelector((state) => state?.continuePlay)

  const recommendation = useSelector((state) => state?.recommendation)

  const location = useLocation()

  const [homePageModals, setHomePageModals] = useState({
    isLoginModalOpen: false,
    isOtpModalOpen: false,
    isEnterNameModalOpen: false,
    isChangeAvatarModalOpen: false,
    isMoreDetailsModalOpen: false,
    isEnterYearOfBirthModalOpen: false,
    isBuyPremiumGameModalOpen: false,
    isSearchModalOpen: false,
  })

  const toggleAvatarModalOnScreen = () => {
    setHomePageModals((prev) => {
      return { ...prev, isChangeAvatarModalOpen: !prev.isChangeAvatarModalOpen }
    })
  }

  const handleChangeAvatar = () => {
    setHomePageModals((prev) => ({
      ...prev,
      isEnterNameModalOpen: false,
      isChangeAvatarModalOpen: true,
    }))
  }

  const onSelectedAvatar = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isEnterNameModalOpen: true,
        isChangeAvatarModalOpen: false,
      }
    })
  }

  const handleSaveNameAvatar = async () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isEnterNameModalOpen: false,
        isMoreDetailsModalOpen: true,
      }
    })
  }

  const handleProfile = () => {
    const properties = {
      OPENED_FROM: location.pathname === '/' ? 'Home' : 'Profile',
    }
    sendAnalytics('track', properties, 'Profile Update Sheet Shown', [
      'moengage',
      'mixpanel',
    ])

    sendAnalytics('track', properties, 'Profile Icon Pressed', [
      'moengage',
      'mixpanel',
    ])

    setHomePageModals((prev) => ({
      ...prev,
      isEnterNameModalOpen: true,
    }))
  }

  const toggleEnterNameModalOnScreen = () => {
    setHomePageModals((prev) => {
      return { ...prev, isEnterNameModalOpen: !prev.isEnterNameModalOpen }
    })
  }

  const handleNameChange = (e) => {
    dispatch(
      setUserInfo({
        name: e.target.value,
        selectedAvatar: loginState?.selectedAvatar,
        yearOfBirth: loginState?.yearOfBirth,
        gender: loginState?.gender,
        mobile: loginState?.mobile,
        code: loginState?.code,
        social_id: loginState?.social_id,
      })
    )
  }

  const goBackFromEnterModal = () => {
    console.log('goBackFromEnterModal')
  }

  const handleAvatarChange = (avatar) => {
    dispatch(
      setUserInfo({
        selectedAvatar: avatar,
        name: loginState.name,
        yearOfBirth: loginState.yearOfBirth,
        gender: loginState.gender,
        mobile: loginState?.mobile,
        code: loginState?.code,
        social_id: loginState?.social_id,
      })
    )
  }
  const onClickYearOfBirth = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isMoreDetailsModalOpen: false,
        isEnterYearOfBirthModalOpen: true,
      }
    })
  }

  const handleGenderChange = (e) => {
    dispatch(
      setUserInfo({
        selectedAvatar: loginState.selectedAvatar,
        name: loginState.name,
        yearOfBirth: loginState.yearOfBirth,
        gender: e.target.value,
        mobile: loginState?.mobile,
        code: loginState?.code,
        social_id: loginState?.social_id,
      })
    )
  }

  const goBackFromMoreDetails = () => {
    setHomePageModals((prev) => ({
      ...prev,
      isMoreDetailsModalOpen: false,
      isEnterNameModalOpen: true,
    }))
    const properties = {
      OPENED_FROM: location.pathname === '/' ? 'Home' : 'Profile',
    }
    sendAnalytics('track', properties, 'Profile Update Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const toggleMoreDetailsOnScreen = () => {
    setHomePageModals((prev) => {
      return { ...prev, isMoreDetailsModalOpen: !prev.isMoreDetailsModalOpen }
    })
  }

  const handleNextToMoreDetails = async () => {
    try {
      const res = await putUserProfile({
        name: loginState.name,
        avatar: loginState.selectedAvatar,
        gender: loginState.gender,
        year_of_birth: loginState.yearOfBirth,
      }).unwrap()
      toast.success(`Profile ${res.message}`)

      if (!!res) {
        sendAnalytics('track', {}, 'Profile Updated', ['moengage', 'mixpanel'])
        setHomePageModals((prev) => {
          return {
            ...prev,
            isMoreDetailsModalOpen: false,
          }
        })
      }
    } catch (error) {
      toast.error(error.data.message, {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
    }
  }

  const handleGoBackFromYearPick = () => {
    setHomePageModals((prev) => ({
      ...prev,
      isEnterYearOfBirthModalOpen: false,
      isMoreDetailsModalOpen: true,
    }))
  }

  const handleYearChange = (yob) => {
    dispatch(
      setUserInfo({
        selectedAvatar: loginState.selectedAvatar,
        name: loginState.name,
        yearOfBirth: yob,
        gender: loginState.gender,
        mobile: loginState?.mobile,
        code: loginState?.code,
        social_id: loginState?.social_id,
      })
    )
  }

  const toggleYearOfBirthOnScreen = () => {
    setHomePageModals((prev) => {
      return { ...prev, isMoreDetailsModalOpen: !prev.isMoreDetailsModalOpen }
    })
  }

  const getPremiumData = async (uid, slug) => {
    try {
      const res = await getPremiumConfig(uid).unwrap()
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const getUserStreakData = async () => {
    try {
      const streakData = await getStreaks().unwrap()
      dispatch(setStreaks(streakData))
      return streakData
    } catch (error) {
      console.error(error)
    }
  }

  const getUserHistory = async () => {
    try {
      const histRes = await userHistory().unwrap()
      dispatch(setUserHistory(histRes))
      return histRes
    } catch (error) {
      console.log(error)
    }
  }

  const getUserSavedGames = async (savedGamesPage) => {
    try {
      const savedRes = await userSavedGames(savedGamesPage).unwrap()
      const saveGame = savedRes.results
      dispatch(
        setSavedGames({
          data: [...savedGames.data, ...saveGame],
          total_page: savedRes.total_page,
        })
      )
      return savedRes
    } catch (error) {
      console.log(error)
    }
  }

  const getUserContinuePlay = async (continuePlayPage) => {
    try {
      const res = await userContinuePlay(continuePlayPage).unwrap()
      const nextData = res.results
      dispatch(
        setContinuePlay({
          data:
            continuePlayPage === 1
              ? [...nextData]
              : [...continuePlay.data, ...nextData],
          total_page: res.total_page,
        })
      )
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const recordHistoryAndSendNotif = async (uid, game_name, updatedAt) => {
    try {
      const res = await userRecordHistory(uid, {
        game: game_name,
        updated_at: updatedAt,
      }).unwrap()

      if (res?.notification?.length > 0) {
        showNotification(res?.notification)
        getUserStreakData()
      }

      if (res.message === 'Game history saved.') {
        getUserContinuePlay(1)
        getUserHistory()
      }

      return res
    } catch (error) {
      console.log(error)
    }
  }

  const handleNavigationHistoryAndNotif = async (
    slug,
    isPremium,
    isMultiPlayer,
    uid,
    game_name,
    updatedAt,
    expiresAt = null,
    categoryId,
    currentPage = 1
  ) => {
    // const NO_AUTH_MP = !loginState?.accessToken && isMultiPlayer
    const NO_AUTH_NOT_PREMIUM = !loginState?.accessToken && !isPremium
    const NO_AUTH_PREMIUM = !loginState?.accessToken && !!isPremium
    const AUTH_NOT_PREMIUM_GAME = !!loginState?.accessToken && !isPremium
    const AUTH_PREMIUM_GAME_NOT_BOUGHT =
      !!loginState?.accessToken && isPremium && !expiresAt
    const AUTH_PREMIUM_GAME_BOUGHT =
      !!loginState?.accessToken && isPremium && !!expiresAt

    if (isPremium) {
      const premiumProperties = {
        GAME_NAME: game_name,
        PURCHASE_STATUS: 'PAYMENT_INITIALISED',
        GAME_UUID: uid,
        GAME_FROM: 'Home',
      }

      sendAnalytics('track', premiumProperties, 'Premium Gameplay', [
        'moengage',
        'mixpanel',
      ])
    }

    const termFromSearch = searchParams.get('term')
    //NO_AUTH
    if (NO_AUTH_NOT_PREMIUM) {
      if (location.pathname === '/search') {
        const properties = {
          GAME_NAME: game_name,
          GAME_UUID: uid,
          GAME_FROM: 'Search',
          SEGMENT_NAME: categoryId,
          SEARCH_QUERY: termFromSearch,
        }

        sendAnalytics('track', properties, 'Play Game', [
          'moengage',
          'mixpanel',
        ])
        navigate(`/${slug}`, { state: { from: '/search' } })
      } else {
        navigate(`/${slug}`, { state: { from: '/search' } })
      }
    }

    if (NO_AUTH_PREMIUM) {
      setHomePageModals((prev) => ({
        ...prev,
        isLoginModalOpen: true,
      }))
      sendAnalytics('track', {}, 'Login Sheet Shown', ['moengage', 'mixpanel'])
      setPremiumGameConfig({
        uid,
        game_name,
        isMultiPlayer,
        slug,
        updatedAt,
        categoryId,
        currentPage,
      })
      //WE COULD REDIRECT THE PERSON TO THE GAME
    }

    //AUTH_PREMIUM_GAME
    if (AUTH_NOT_PREMIUM_GAME) {
      recordHistoryAndSendNotif(uid, game_name, updatedAt)
      if (location.pathname === '/search') {
        const properties = {
          GAME_NAME: game_name,
          GAME_UUID: uid,
          GAME_FROM: 'Search',
          SEGMENT_NAME: categoryId,
          SEARCH_QUERY: termFromSearch,
        }

        sendAnalytics('track', properties, 'Play Game', [
          'moengage',
          'mixpanel',
        ])
        navigate(`/${slug}`, { state: { from: '/search' } })
      } else {
        navigate(`/${slug}`, { state: { from: '/' } })
      }
    }

    //AUTH_PREMIUM_GAME_NOT_BOUGHT
    if (AUTH_PREMIUM_GAME_NOT_BOUGHT) {
      //ideally this should never happen
      const premiumData = await getPremiumData(uid, slug)
      if (premiumData?.is_bought) {
        navigate(`/${slug}`, { state: { from: '/' } })
        recordHistoryAndSendNotif(uid, game_name, updatedAt)
        return
      }
      //code should always run from here

      setHomePageModals((prev) => {
        return {
          ...prev,
          isBuyPremiumGameModalOpen: true,
          isSearchModalOpen: false,
        }
      })

      setPremiumGameConfig({
        ...premiumData,
        uid,
        isMultiPlayer,
        game_name,
        slug,
        updatedAt,
        categoryId,
        currentPage,
      })
    }

    //AUTH_PREMIUM_GAME_BOUGHT
    if (AUTH_PREMIUM_GAME_BOUGHT) {
      recordHistoryAndSendNotif(uid, game_name, updatedAt)
      const properties = {
        GAME_NAME: game_name,
        PURCHASE_STATUS: 'PAYMENT_SUCCESS',
        GAME_UUID: uid,
        GAME_FROM: location?.pathname === '/' ? 'Home' : 'Game',
      }

      sendAnalytics('track', properties, 'Premium Gameplay', [
        'moengage',
        'mixpanel',
      ])
      if (location.pathname === '/search') {
        const properties = {
          GAME_NAME: game_name,
          GAME_UUID: uid,
          GAME_FROM: 'Search',
          SEGMENT_NAME: categoryId,
          SEARCH_QUERY: termFromSearch,
        }

        sendAnalytics('track', properties, 'Play Game', [
          'moengage',
          'mixpanel',
        ])
        navigate(`/${slug}`, { state: { from: '/search' } })
      } else {
        navigate(`/${slug}`, { state: { from: '/' } })
      }
    }
  }

  const togglePremiumGameModal = () => {
    setHomePageModals((prev) => ({ ...prev, isBuyPremiumGameModalOpen: false }))
  }
  const handleCancelBuy = (game_name, uid) => {
    const properties = {
      GAME_NAME: game_name,
      PURCHASE_STATUS: 'PAYMENT_CANCELLED',
      GAME_UUID: uid,
      GAME_FROM: location?.pathname === '/' ? 'Home' : 'Game',
    }
    sendAnalytics('track', properties, 'Premium Gameplay', [
      'moengage',
      'mixpanel',
    ])
    setHomePageModals((prev) => ({
      ...prev,
      isBuyPremiumGameModalOpen: false,
    }))
    setPremiumGameConfig({})
  }

  const fetchSingleCategory = async (categoryId, page) => {
    try {
      const res = await userAllCategory({
        categoryId,
        page,
      }).unwrap()
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCategories = async (categories) => {
    setIsAllCategoryLoading(true)
    for (const categoryId of categories) {
      try {
        const res = await fetchSingleCategory(categoryId, 1)
        if (res?.results?.games?.length > 0) {
          dispatch(setAllCategory(res))
        }
      } catch (error) {
        console.log(error)
        setIsAllCategoryLoading(false)
      }
    }
    setIsAllCategoryLoading(false)
  }

  const getAllCategoryId = async () => {
    try {
      const res = await userAllCategoryId().unwrap()
      const allCategories = res?.categories?.reduce(
        (acc, val) => (acc ? [...acc, val?.uuid] : [val?.uuid]),
        []
      )
      dispatch(setAllCategoryId(allCategories))
      return allCategories
    } catch (error) {
      console.log(error)
    }
  }

  return {
    handleAvatarChange,
    handleSaveNameAvatar,
    onSelectedAvatar,
    toggleAvatarModalOnScreen,
    toggleEnterNameModalOnScreen,
    homePageModals,
    setHomePageModals,
    handleChangeAvatar,
    goBackFromEnterModal,
    handleNameChange,
    onClickYearOfBirth,
    handleProfile,
    handleGenderChange,
    goBackFromMoreDetails,
    toggleMoreDetailsOnScreen,
    handleNextToMoreDetails,
    handleGoBackFromYearPick,
    handleYearChange,
    toggleYearOfBirthOnScreen,
    handleNavigationHistoryAndNotif,
    premiumGameConfig,
    setPremiumGameConfig,
    getUserStreakData,
    recordHistoryAndSendNotif,
    togglePremiumGameModal,
    handleCancelBuy,
    getPremiumData,
    getUserHistory,
    getUserSavedGames,
    getUserContinuePlay,
    fetchCategories,
    fetchSingleCategory,
    getAllCategoryId,
    isAllCategoryLoading,
    isAllCategoriesLoading,
  }
}
