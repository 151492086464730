import { sendAnalytics } from '../../../utils/mixpannelConn'
import {
  ProfileIcon,
  ReelsIcon,
  ReelsActive,
  ProfileActive,
  HomeUnFilled,
  HomeFilled,
} from '../icones'
import { useLocation, useNavigate } from 'react-router-dom'

export const BottomBar = ({ token }) => {
  const location = useLocation()

  const naviagte = useNavigate()
  const handlePlayGameIconClick = () => {
    const properties = {
      TAB_SELECTED: 'Home',
    }
    sendAnalytics('track', properties, 'Tab Selected', ['moengage', 'mixpanel'])
    naviagte('/')
  }

  const handleReelsIconClick = () => {
    const properties = {
      TAB_SELECTED: 'Reels',
    }
    sendAnalytics('track', properties, 'Tab Selected', ['moengage', 'mixpanel'])
    naviagte('/reels')
  }

  const handleProfileIconClick = () => {
    const properties = {
      TAB_SELECTED: 'Profile',
    }
    sendAnalytics('track', properties, 'Tab Selected', ['moengage', 'mixpanel'])
    naviagte('/user/profile')
  }

  const isHomepage = location.pathname === '/'
  const isReelpage = location.pathname === '/reels'
  const isProfilePage = location.pathname === '/user/profile'

  return (
    <div className="flex z-[99] fixed items-center md:hidden right-0 left-0 w-full border-t-2 border-solid border-t-[#e5e5e6]  bg-white  bottom-0">
      <button
        onClick={handlePlayGameIconClick}
        className="cursor-pointer flex-1 flex items-center py-3 justify-center"
      >
        {isHomepage ? <HomeFilled /> : <HomeUnFilled />}
      </button>

      <button
        onClick={handleReelsIconClick}
        className="cursor-pointer flex-1 flex items-center justify-center"
      >
        {isReelpage ? <ReelsActive /> : <ReelsIcon />}
      </button>

      {!!token && (
        <button
          onClick={handleProfileIconClick}
          className="cursor-pointer flex-1 flex items-center py-3 justify-center"
        >
          {isProfilePage ? <ProfileActive /> : <ProfileIcon />}
        </button>
      )}
    </div>
  )
}
