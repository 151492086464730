import React from 'react'
import 'react-phone-input-2/lib/style.css'
import { ArrowLeft } from '../icones'
import classNames from 'classnames'

export const LeaderBoardModal = ({
  isLeaderBoardOpen,
  userRank,
  leaderBordDetails,
  handleNextLeaderBoardPage,
  handleCloseLeaderBoard,
  token,
  handleLoginFromLeaderBoard,
}) => {
  return (
    <div
      className={classNames(
        'bg-[#f9f9f9] text-black w-full md:w-[400px] z-[10] fixed h-screen md:h-[50vh] ml-0 mr-0 top-[0%] md:top-[5%] md:top right-0 md:right-[25%] left-0 md:left-[25%]  md:rounded-[22px] mx-auto',
        {
          block: isLeaderBoardOpen,
          hidden: !isLeaderBoardOpen,
        }
      )}
    >
      <div className="flex flex-col justify-center md:rounded-[22px] text-white items-center gap-6 p-4 bg-[#2B0F58]  bg-leaderboard-banner bg-cover">
        <div
          onClick={handleCloseLeaderBoard}
          className="self-start cursor-pointer"
        >
          <ArrowLeft fill="white" stroke="white" />
        </div>
        {userRank?.data?.rank === 0 ? (
          <span className="font-semibold">
            Play the game to show up in leaderboard
          </span>
        ) : (
          <div className="text-[22px] ">
            Your rank is{' '}
            {token ? (
              <span className="font-semibold">{userRank?.data?.rank}</span>
            ) : (
              <span className="blur-sm">👀</span>
            )}
          </div>
        )}
        {token ? (
          <>
            <img
              src={userRank?.data?.avatar}
              alt={userRank?.data?.avatar}
              className="w-[74px] h-[74px]"
            />
            <div className="flex flex-col items-center">
              <div>Your score</div>
              <div className="font-semibold">
                {Math.floor(userRank?.data?.score)}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="font-semibold text-[17px] text-center">
              Log in to track your rank on the leaderboard
            </div>
            <button
              className="text-black text-semibold bg-[#f3d505] rounded-[40px] px-[54px] py-[8px] cursor-pointer"
              onClick={handleLoginFromLeaderBoard}
            >
              Login
            </button>
          </div>
        )}
      </div>
      <div className="pb-4 ">
        <div className="text-[18px] whitespace-nowrap font-semibold  bg-[#f9f9f9] text-center py-7">
          Leaderboard
        </div>
        <div className="h-[40vh] bg-[#f9f9f9] overflow-y-scroll rounded-b-[22px]">
          <div className="flex flex-col items-center justify-center gap-2">
            {leaderBordDetails?.results?.map((mem) => {
              return (
                <div className="flex items-center justify-between bg-white w-[90%]  rounded-xl p-2">
                  <div className="flex items-center gap-3">
                    <div className="font-semibold">{mem.rank}</div>
                    <img
                      src={mem.avatar}
                      alt={mem.avatar}
                      className="rounded w-10 h-10"
                    />
                    <div className="font-semibold">{mem.social_id}</div>
                  </div>
                  <div className="font-semibold">{Math.floor(mem.score)}</div>
                </div>
              )
            })}
          </div>
          {leaderBordDetails?.next && (
            <div
              className="text-center text-[#0077FF] cursor-pointer underline py-4"
              onClick={handleNextLeaderBoardPage}
            >
              View Next Page
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
