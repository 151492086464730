import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './Error.css'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2 className="text-4xl font-extrabold text-black uppercase w-fit mx-auto">
          we are sorry
        </h2>
        <p className="mb-5">
          Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
          existed in the first place?
        </p>
        <NavLink to="/">Explore Fun Games!</NavLink>
      </div>
    </div>
  )
}

export default ErrorPage
