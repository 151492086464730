import { useRef } from 'react'

export const useScroll = () => {
  const scrollRef = useRef(null)

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset
  }

  return [scrollRef, scroll]
}
