import React from 'react'
import './App.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './app/view/Home/Home'
import Game from './app/view/Game/Game'
import ErrorPage from './app/view/ErrorPage/ErrorPage'
import ReelsPage from './app/view/ReelsPage/ReelsPage'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ScrollProvider } from './providers/scrollProvider'
import PrivacyPage from './app/view/PrivacyPage/PrivacyPage'
import ReturnPage from './app/view/ReturnPage/ReturnPage'
import { HMSRoomProvider } from '@100mslive/react-sdk'
import { ProfilePage } from './app/view/ProfilePage/ProfilePage'
import { useSelector } from 'react-redux'
import { StreaksPage } from './app/view/StreaksPage/StreaksPage'
import { CoinTransaction } from './app/view/CoinsPage/CoinTransaction'
import { EarnCoins } from './app/view/CoinsPage/EarnCoins'
import { useMediaQuery } from './hooks/useMediaQuery'
import SupportPage from './app/view/SupportPage/SupportPage'
import TermsPage from './app/view/TermsPage/TermsPage'
import { SearchGames } from './app/view/SearchGames/SearchGames'

function App() {
  const loginState = useSelector((state) => state.login)
  const isLarge = useMediaQuery('(min-width:768px)')

  return (
    <HMSRoomProvider>
      <ScrollProvider>
        <SkeletonTheme>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/reels" element={<ReelsPage />} />
              <Route exact path="/privacy" element={<PrivacyPage />} />
              <Route exact path="/support" element={<SupportPage />} />
              <Route exact path="/terms" element={<TermsPage />} />
              <Route exact path="/return" element={<ReturnPage />} />
              {/* More specific routes go here */}
              {!!loginState.accessToken && !isLarge && (
                <Route path="/user/profile" element={<ProfilePage />} />
              )}
              {!!loginState.accessToken && (
                <Route
                  path="/coins/transactions"
                  element={<CoinTransaction />}
                />
              )}
              {!!loginState.accessToken && (
                <Route path="/streaks" element={<StreaksPage />} />
              )}

              {!!loginState.accessToken && (
                <Route path="/coins/earn" element={<EarnCoins />} />
              )}
              <Route path="/search/*" element={<SearchGames />} />
              <Route path="/:slug" element={<Game />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </SkeletonTheme>
      </ScrollProvider>
    </HMSRoomProvider>
  )
}

export default App
