import { useEffect } from 'react'
import { CoinsPageHeader } from '../../component/Coin/CoinPageHeader'
import { setEarnCoinsData } from '../../../store/coins/slice'
import { useGetEarnCoinsMutation } from '../../../store/coins/action'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { isBottomBarVisible } from '../../../utils/constansts'
import { BottomBar } from '../../component/Common/BottomBar'
import { CoinImg } from '../../component/icones'

export const EarnCoins = () => {
  const [getEarnCoins, { isLoading: isStreaksLoading }] =
    useGetEarnCoinsMutation()

  const loginState = useSelector((state) => state.login)

  const dispatch = useDispatch()

  const coins = useSelector((state) => state.coins)

  const isBottomBar = isBottomBarVisible()

  const getRewardCoins = async () => {
    try {
      const res = await getEarnCoins().unwrap()
      dispatch(setEarnCoinsData(res.data))
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  useEffect(() => {
    if (coins?.earnCoinsData?.length === 0) {
      getRewardCoins()
    }
  }, [coins])

  return (
    <div className="p-4 flex flex-col gap-4  w-full xl:w-[60%] mx-auto fixed h-[90vh] md:static md:h-full overflow-y-scroll">
      <CoinsPageHeader />
      <div className="flex flex-col gap-4">
        {coins?.earnCoinsData?.map((data) => {
          return (
            <div className="flex justify-between items-center bg-[#f6f7f9] px-4 xl:px-5 py-2 xl:py-3  rounded-xl">
              <div className="font-semibold">{data.title}</div>
              <div className="flex flex-col items-end">
                <CoinImg className="w-6 h-6" />
                <div>+{data.coins}</div>
              </div>
            </div>
          )
        })}
      </div>
      <BottomBar token={loginState.accessToken} />
    </div>
  )
}
