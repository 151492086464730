import { useLocation, useNavigate } from 'react-router'
import { ArrowLeft, CoinImg, Error, StreakFire } from '../../component/icones'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  useGetStreaksMutation,
  usePostStreaksMutation,
} from '../../../store/streaks/action'
import { setStreaks } from '../../../store/streaks/streakSlice'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { showNotification } from '../../../utils/showNotification'
import { isBottomBarVisible } from '../../../utils/constansts'
import { BottomBar } from '../../component/Common/BottomBar'
import { sendAnalytics } from '../../../utils/mixpannelConn'

export const StreaksPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [getStreaks, { isLoading: isStreaksLoading }] = useGetStreaksMutation()

  const [postStreaks, { isLoading: isPostStreaksLoading }] =
    usePostStreaksMutation()

  const streaks = useSelector((state) => state.streaks)

  const getUserStreakData = async () => {
    try {
      const streakData = await getStreaks().unwrap()
      dispatch(setStreaks(streakData))
    } catch (error) {
      toast.error(error.message)
    }
  }
  const loginState = useSelector((state) => state.login)

  const isBottomBar = isBottomBarVisible()

  const handleClaimStreak = async (id, days, coins) => {
    try {
      const streakData = await postStreaks({
        streak_id: id,
      }).unwrap()
      showNotification(streakData?.notification)
      getUserStreakData()
      if (streakData?.notification?.length > 0) {
        const properties = {
          STREAK_ID: id,
          STREAK_DAYS: days,
          COINS_CLAIMED: coins,
        }
        sendAnalytics('track', properties, 'Streak Claimed', [
          'moengage',
          'mixpanel',
        ])
      }
    } catch (error) {
      console.error(error)
      toast.error(error?.data.message, {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
    }
  }

  useEffect(() => {
    if (!streaks.data) {
      getUserStreakData()
    }
  }, [streaks])

  useEffect(() => {
    if (!!location?.state?.from && streaks?.data) {
      const properties = {
        OPENED_FROM: location?.state?.from === '/' ? 'Home' : 'Direct Landing',
        USER_STREAKS: streaks?.data?.current_streak_days,
      }
      sendAnalytics('track', properties, 'Streaks Screen Opened', [
        'moengage',
        'mixpanel',
      ])
    }
  }, [location, streaks])

  return (
    <div className="px-4 pt-4 pb-[6rem] flex flex-col gap-4 w-full xl:w-[60%] mx-auto fixed h-[90vh] md:static md:h-full overflow-y-scroll">
      <div className="flex items-center gap-4 mb-8 xl:my-2 cursor-pointer">
        <ArrowLeft onClick={() => navigate(-1)} />
        <div className="font-semibold text-[22px]">Streaks</div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="font-bold text-[68px] leading-[60px]">
            {streaks?.data?.current_streak_days}
          </div>
          <div className="font-semibold text-lg">days streak</div>
        </div>
        <div>
          <StreakFire className="w-[50px] h-[80px]" fill="#f09819" />
        </div>
      </div>
      <div className="bg-black rounded-[28px] text-white font-semibold flex text-sm md:text-xl items-center py-3 text-center justify-center">
        {streaks?.data?.display_message}
      </div>
      <div className="font-semibold text-base"> Streak Challenge Bonus</div>
      {streaks?.data?.streak_configuration
        ?.slice()
        ?.sort((a, b) => Number(a?.days) - Number(b?.days))
        ?.map((streakConfig) => {
          const currentStreak = streaks?.data?.streak_metadata.find(
            (data) => data.id === streakConfig.id
          )

          const isStreakClaimed = currentStreak?.claimed

          const isStreakAchieved = currentStreak?.achieved

          return (
            <div className="flex bg-[#f6f7f9] rounded-xl p-[14px] justify-between items-center">
              <div className="flex gap-4 items-center">
                <div
                  className={classNames(
                    'bg-[#f09819] px-[20px] py-[12px] rounded-lg w-[68px] h-[68px] flex items-center',
                    {
                      'bg-[#f9d49f]': isStreakAchieved,
                    }
                  )}
                >
                  <StreakFire className="w-[50px] h-[80px]" fill="#f09819" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[17px]">
                    {streakConfig.days} day streak
                  </div>
                  <div>
                    {isStreakAchieved && !isStreakClaimed && (
                      <button
                        className="border-2 cursor-pointer border-[#0077ff] font-semibold text-[17px] text-[#0077ff] py-1 px-3 rounded-[28px]"
                        disabled={isPostStreaksLoading}
                        onClick={() =>
                          handleClaimStreak(
                            streakConfig.id,
                            streakConfig.days,
                            streakConfig.coins
                          )
                        }
                      >
                        Claim
                      </button>
                    )}
                    {!isStreakAchieved && !isStreakClaimed && (
                      <div>
                        {isStreakAchieved
                          ? streakConfig.days
                          : streaks?.data?.current_streak_days}
                        /{streakConfig.days} DAYS
                      </div>
                    )}
                    {isStreakAchieved && isStreakClaimed && (
                      <div className="border-2 cursor-pointer border-[#0077ff] border-dashed  font-semibold text-[17px] text-[#0077ff] py-1 px-3 rounded-[28px]">
                        Claimed
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <CoinImg className="w-6 h-6" />
                <div>+{streakConfig.coins}</div>
              </div>
            </div>
          )
        })}
      {isBottomBar && <BottomBar token={loginState.accessToken} />}
    </div>
  )
}
