import classNames from 'classnames'
import { forwardRef } from 'react'
import { useIsOnSafari } from '../../../hooks/useIsOnSafari'
import { PremiumCard } from './PremiumCard'

export const GameCard = forwardRef(
  (
    { data, idx, handleGameCardClick, allGamesLength, categoryId, currentPage },
    ref
  ) => {
    const isIOSorSafari = useIsOnSafari()
    const premiumGameBought = data?.is_premium && !!data?.expires_at
    const premiumGameNotBought = data?.is_premium && !data?.expires_at

    return (
      <div
        className={classNames('mr-4', {
          'hover-card': !isIOSorSafari,
        })}
        key={data.uuid}
      >
        <div className="hover-card-inner w-[144px] h-[144px]">
          <div
            onClick={() =>
              handleGameCardClick(
                data.name,
                data.uuid,
                data.updated_at,
                data.expires_at,
                data.is_premium,
                data.multiplayer,
                data.slug,
                categoryId
              )
            }
            className={classNames('mb-2', {
              'game-card ': !isIOSorSafari,
            })}
          >
            <PremiumCard
              premiumGameNotBought={premiumGameNotBought}
              premiumGameBought={premiumGameBought}
              expiresAt={data?.expires_at}
            />
            <div className="card">
              {idx === allGamesLength - 4 && data.slug ? (
                <img
                  src={data.property.thumbnail}
                  className="w-40 h-36"
                  alt="thumbnail"
                  ref={ref}
                />
              ) : (
                <img
                  src={data.property.thumbnail}
                  className="w-40 h-36"
                  alt="thumbnail"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)
