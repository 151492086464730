import { Modal } from 'reactstrap'

export const CoinIntroModal = ({
  isCoinIntroModalOpen,
  toggleCoinIntroModal,
  handleViewCoins,
}) => {
  return (
    <Modal
      isOpen={isCoinIntroModalOpen}
      toggle={toggleCoinIntroModal}
      style={{ width: '350px' }}
    >
      <div className="flex flex-col justify-center items-center p-4 gap-4 my-auto">
        <img
          src="/coin-group.png"
          alt="coin-group"
          className="w-[188px] h-[114px]"
        />
        <div className="flex flex-col items-center gap-2">
          <div className="font-semibold text-[22px]">Introducing Coins</div>
          <div className="text-[#777f88] text-[17px]">
            Unlock all premium content
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 w-full ">
          <div className="flex items-center justify-center gap-[21px] border-b border-b-[#e5e5e6] pb-[24px] mr-4">
            <img
              src="/play-vip.png"
              alt="vip-img"
              className="w-[96px] h-[68px]"
            />
            <div>Play VIP games</div>
          </div>
          <div className="flex items-center justify-center gap-[21px]">
            <img
              src="/unlockPremiums.png"
              alt="vip-img"
              className="w-[90px] h-[56px]"
            />
            <div>
              Unlock premium <div>features</div>
            </div>
          </div>
        </div>
        <button
          className="bg-[#0077ff] my-2 text-white font-medium text-[20px] px-6 py-2 rounded-full"
          onClick={handleViewCoins}
        >
          View Coins
        </button>
      </div>
    </Modal>
  )
}
