import React, { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const noOfColumns = 6
const noOfRows = 5

const SkeletonNameRow = () => (
  <div className="w-36 h-6 rounded-md mb-4 sm:pl-0 pl-2">
    <Skeleton width="100%" height="100%" />
  </div>
)

const SkeletonCard = () => (
  <div className="w-full h-32 rounded-md">
    <Skeleton width="100%" height="100%" />
  </div>
)

export const SkeletonCards = () => (
  <>
    {Array.from({ length: noOfRows }).map((_, row) => (
      <Fragment key={row}>
        <SkeletonNameRow />
        <div className="grid h-full w-full grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 sm:pl-0 pl-2">
          {Array.from({ length: noOfColumns }).map((_, i) => (
            <SkeletonCard key={i} />
          ))}
        </div>
        {row !== noOfRows - 1 && <div className="mb-4" />}
      </Fragment>
    ))}
  </>
)
