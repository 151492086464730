import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'
import {
  ChevronDown,
  CoinImg,
  CoinImgMobile,
  SearchIcon,
  StreakFire,
  TimePassNewLogo,
} from '../icones'
import '../LoggedInBanner/loggedInBanner.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { logout } from '../../../store/login/loginSlice'
import { useDispatch } from 'react-redux'
import { useUserRecordHistoryMutation } from '../../../store/user/actions'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { usePostLogoutMutation } from '../../../store/login/logout/actions'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { CoinIntroModal } from '../Coin/CoinIntroModal'
import { StreakIntroModal } from '../Coin/StreakIntroModal'

export const LoggedInBanner = ({
  handleNavigationHistoryAndNotif,
  handleProfile,
  firstAvatar,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const loginState = useSelector((state) => state?.login)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const dispatch = useDispatch()

  const [userRecordHistory] = useUserRecordHistoryMutation()

  const [isCoinIntroModalOpen, setCoinIntroModalOpen] = useState(false)

  const [isStreakIntroModalOpen, setStreakIntroModalOpen] = useState(false)

  const streaks = useSelector((state) => state.streaks)

  const [postLogout] = usePostLogoutMutation()

  const isLarge = useMediaQuery('(min-width:768px)')

  const handleLogout = async () => {
    dispatch(logout())
    try {
      await postLogout({
        refresh_token: loginState.refreshToken,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }

    localStorage.setItem('accessToken', null)
    localStorage.setItem('refreshToken', null)

    const properties = {
      ScreenName: 'Homepage',
    }

    sendAnalytics('track', properties, 'Logout Button Pressed', [
      'moengage',
      'mixpanel',
    ])
    sendAnalytics('reset', false, false, ['moengage', 'mixpanel'])

    window.location.reload()
  }

  const navigate = useNavigate()

  const routeSearch = () => {
    navigate('/search')
    const properties = {
      FROM: 'Home',
    }

    sendAnalytics('track', properties, 'Search Icon Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleStreakIconPress = () => {
    const userId = localStorage.getItem('mixpanelId')
    const userDetails = JSON.parse(localStorage.getItem(`${userId}`))
    if (userDetails?.isNotFirstTimeToViewStreaks) {
      navigate(`/streaks`, { state: { from: '/' } })
      const properties = {
        OPENED_FROM: 'Home',
        USER_COINS: streaks?.data?.current_streak_days,
      }
      sendAnalytics('track', properties, 'Streaks Icon Clicked', [
        'moengage',
        'mixpanel',
      ])
    } else {
      setStreakIntroModalOpen(true)
    }
  }

  const handleCoinTransactionPressed = () => {
    const userId = localStorage.getItem('mixpanelId')
    const userDetails = JSON.parse(localStorage.getItem(`${userId}`))
    if (userDetails?.isNotFirstTimeToViewTransaction) {
      navigate('/coins/transactions', { state: { from: '/' } })
      const properties = {
        OPENED_FROM: 'Home',
        USER_COINS: streaks?.data?.coin_balance,
      }
      sendAnalytics('track', properties, 'Coins Icon Clicked', [
        'moengage',
        'mixpanel',
      ])
    } else {
      setCoinIntroModalOpen(true)
    }
  }

  const toggleStreakIntroModal = () => {
    setStreakIntroModalOpen(false)
  }

  const toggleCoinIntroModal = () => {
    setCoinIntroModalOpen(false)
  }

  const handleViewCoins = () => {
    setCoinIntroModalOpen(false)
    navigate('/coins/transactions', { state: { from: '/' } })
    const userId = localStorage.getItem('mixpanelId')
    const isCoinEcoVisited = JSON.parse(localStorage.getItem(`${userId}`))
    if (isCoinEcoVisited?.isNotFirstTimeToViewStreaks) {
      localStorage.setItem(
        userId,
        JSON.stringify({
          isNotFirstTimeToViewTransaction: true,
          isNotFirstTimeToViewStreaks: true,
        })
      )
    } else {
      localStorage.setItem(
        userId,
        JSON.stringify({
          isNotFirstTimeToViewTransaction: true,
          isNotFirstTimeToViewStreaks: false,
        })
      )
    }
  }

  const handleViewStreaks = () => {
    setCoinIntroModalOpen(false)
    navigate(`/streaks`, { state: { from: '/' } })
    const userId = localStorage.getItem('mixpanelId')
    const isCoinEcoVisited = JSON.parse(localStorage.getItem(`${userId}`))
    if (isCoinEcoVisited?.isNotFirstTimeToViewTransaction) {
      localStorage.setItem(
        userId,
        JSON.stringify({
          isNotFirstTimeToViewTransaction: true,
          isNotFirstTimeToViewStreaks: true,
        })
      )
    } else {
      localStorage.setItem(
        userId,
        JSON.stringify({
          isNotFirstTimeToViewTransaction: false,
          isNotFirstTimeToViewStreaks: true,
        })
      )
    }
  }

  return (
    <div className="py-[0.75rem] md:py-[0rem]">
      <CoinIntroModal
        isCoinIntroModalOpen={isCoinIntroModalOpen}
        toggleCoinIntroModal={toggleCoinIntroModal}
        handleViewCoins={handleViewCoins}
      />
      <StreakIntroModal
        isStreakIntroModalOpen={isStreakIntroModalOpen}
        toggleStreakIntroModal={toggleStreakIntroModal}
        handleViewStreaks={handleViewStreaks}
      />
      <div className="header1 md:hidden flex items-center justify-between px-[0.5rem] sm:px-[1.5rem] md:px-0 max-w-[900px] mx-auto">
        <div className="flex md:hidden items-center gap-4">
          <div
            onClick={handleCoinTransactionPressed}
            className="flex gap-2 items-center"
          >
            {!isLarge && <CoinImgMobile className="w-[25px] h-[25px]" />}
            <div className="text-[17px] font-semibold text-[#d1800e]">
              {streaks?.data?.coin_balance}
            </div>
          </div>
          <div
            onClick={handleStreakIconPress}
            className="flex gap-2 items-center"
          >
            <StreakFire className="w-[25px] h-[25px]" fill="#f09819" />
            <div className="text-[17px] font-semibold text-[#d1800e]">
              {streaks?.data?.current_streak_days}
            </div>
          </div>
        </div>
        <div
          className="flex items-center gap-2 border border-[#e5e5e6] p-2 rounded-full"
          onClick={routeSearch}
        >
          <SearchIcon
            width="20px"
            height="20px"
            stroke="black"
            fill="#fff"
            className="cursor-pointer"
            strokeWidth={2.5}
          />
        </div>
      </div>
      <div className="header1 hidden md:block">
        <div
          className="top-left flex justify-between items-center w-full xl:w-[60%]"
          style={{ margin: 'auto' }}
        >
          <div className="w-[64px] h-[35px] md:w-[87px] md:h-[46px] m-[1rem]">
            <TimePassNewLogo />
          </div>

          <div className="flex gap-8 items-center justify-center">
            <div className="flex items-center justify-center gap-[1rem]">
              <div
                onClick={handleCoinTransactionPressed}
                className="flex gap-1 items-center"
              >
                {isLarge && <CoinImg className="w-[25px] h-[25px]" />}
                <div className="text-[17px] font-bold text-[#d1800e]">
                  {streaks?.data?.coin_balance}
                </div>
              </div>
              <div
                onClick={handleStreakIconPress}
                className="flex gap-1 items-center"
              >
                <StreakFire className="w-[30px] h-[30px]" fill="#f09819" />
                <div className="text-[17px] font-bold text-[#d1800e]">
                  {streaks?.data?.current_streak_days}
                </div>
              </div>
              <div
                className="flex items-center gap-2 border border-[#e5e5e6] p-2 rounded-full"
                onClick={() => navigate('/search')}
              >
                <SearchIcon
                  width="20px"
                  height="20px"
                  stroke="#47891D"
                  fill="white"
                  className="cursor-pointer"
                  strokeWidth={2.5}
                />
              </div>
            </div>
            <div className="flex xl:gap-4 items-center justify-center">
              <div className="flex gap-2 items-center" onClick={handleProfile}>
                <div className="user-icon-wrapper m-[0.5rem] md:m-0">
                  <img
                    src={
                      !!loginState?.selectedAvatar
                        ? loginState?.selectedAvatar
                        : firstAvatar
                    }
                    alt="selectedAvatar"
                    className="w-[45px] h-[45px] flex items-center justify-center rounded-full"
                  />
                </div>
                <div className="user-name">
                  <Col
                    style={{
                      textAlign: 'left',
                      fontWeight: '700',
                      fontSize: '1rem',
                    }}
                  >
                    {loginState?.name
                      ? `${
                          loginState?.name?.length > 25
                            ? `${loginState?.name?.substring(0, 15)}...`
                            : loginState?.name
                        }`
                      : 'Guest'}
                  </Col>
                  <Col
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      lineHeight: '20px',
                      marginLeft: '-1px',
                    }}
                  >
                    <span className="flex items-center gap-[1px]">
                      <span className="font-medium text-[18px]">@</span>
                      <span>{loginState?.social_id}</span>
                    </span>
                  </Col>
                </div>
              </div>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="black" className="px-[0.5rem]">
                  <ChevronDown
                    style={{ height: '1.25rem', width: '1.25rem' }}
                    className="chevron-down"
                  />
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    marginRight: '1rem',
                    inset: '-35% 0% auto auto !important',
                  }}
                >
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      {/* History */}
      {/* <div
        className={classNames(
          'bg-[#ffffff] h-fit md:bg-[#eaf5e4] md:h-[349px] hidden md:flex md:justify-center'
        )}
      >
        <div className="w-full xl:w-[60%] h-full md:flex  justify-between items-center sm:px-6 lg:px-0">
          <div className="leading-[55px] flex md:flex-col  gap-1 md:gap-2 px-2 md:px-0">
            <div className="text-[18px] font-bold md:font-normal md:text-[56px]">
              Welcome,
            </div>
            <div className="font-bold text-[18px] md:text-[56px]">
              {!!loginState?.name
                ? `${
                    isLarge
                      ? `${
                          loginState?.name?.length > 6
                            ? `${loginState?.name?.substring(0, 5)}...`
                            : loginState?.name
                        }`
                      : loginState?.name
                  } `
                : 'Guest'}
              👋
            </div>
          </div>
          {userReport?.data?.recently_played_game && (
            <div className="gap-4 hidden md:flex">
              <div className="relative">
                <img
                  src={
                    userReport?.data?.max_played_game?.game.property?.thumbnail
                  }
                  className="w-[155px] h-[200px] rounded-[27px]"
                  alt="max_played"
                  onClick={() => {
                    handelMostPlayedGame(
                      userReport?.data?.max_played_game?.game?.slug,
                      userReport?.data?.max_played_game?.game?.is_premium,
                      userReport?.data?.max_played_game?.game?.multiplayer,
                      userReport?.data?.max_played_game?.game?.uuid,
                      userReport?.data?.max_played_game?.game?.name,
                      userReport?.data?.max_played_game?.game?.updated_at,
                      userReport?.data?.max_played_game?.game?.expires_at
                    )
                  }}
                />
                <div className="absolute w-full top-0">
                  <span className="block styled-history-title text-black">
                    Most Played
                  </span>
                </div>
                <PremiumCard
                  premiumGameNotBought={maxPlayedpremiumGameNotBought}
                  premiumGameBought={maxPlayedpremiumGameBought}
                  expiresAt={expiresAtForMaxPlayed}
                />
              </div>
              <div className="relative">
                <img
                  src={
                    userReport?.data?.recently_played_game?.game?.property
                      ?.thumbnail
                  }
                  className="w-[155px] h-[200px] rounded-[27px]"
                  alt="recently_played"
                  onClick={() => {
                    handleRecentlyPlayedGame(
                      userReport?.data?.recently_played_game?.game?.slug,
                      userReport?.data?.recently_played_game?.game?.is_premium,
                      userReport?.data?.recently_played_game?.game?.multiplayer,
                      userReport?.data?.recently_played_game?.game?.uuid,
                      userReport?.data?.recently_played_game?.game?.name,
                      userReport?.data?.recently_played_game?.game?.updated_at,
                      userReport?.data?.recently_played_game?.game?.expires_at
                    )
                  }}
                />
                <div className="absolute w-full top-0">
                  <span className="block styled-history-title text-black">
                    Recently Played
                  </span>
                </div>
                <PremiumCard
                  premiumGameNotBought={recentlyPlayedpremiumGameNotBought}
                  premiumGameBought={recentlyPlayedpremiumGameBought}
                  expiresAt={expiresAtForRecentlyPlayed}
                />
              </div>
            </div>
          )}
        </div>
      </div> */}
    </div>
  )
}
