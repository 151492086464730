import { useTimer } from 'react-timer-hook'

export const TimeLeft = ({ endDate }) => {
  const expiryTimestamp = new Date(endDate)

  const timeLeft = useTimer({
    expiryTimestamp,
    onExpire: () => console.log(''),
  })

  const isDaysLeft = timeLeft.days > 0

  const isHoursLeft = timeLeft.days === 0 && timeLeft.hours > 0

  const isMinutesLeft =
    timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes > 0

  const isSecondsLeft =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds > 0

  const isNoTimeLeft =
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0

  const timeToBeShown = () => {
    if (isDaysLeft) {
      return `${timeLeft.days}d`
    }
    if (isHoursLeft) {
      return `${timeLeft.hours}hr`
    }
    //MINUTES
    if (isMinutesLeft) {
      if (timeLeft.minutes < 9) {
        return `0${timeLeft.minutes}: ${timeLeft.seconds}s`
      }

      if (timeLeft.seconds < 9) {
        return `${timeLeft.minutes}: 0${timeLeft.seconds}s`
      }

      if (timeLeft.seconds < 9 && timeLeft.minutes < 9) {
        return `0${timeLeft.minutes}: 0${timeLeft.seconds}s`
      }

      return `${timeLeft.minutes}: ${timeLeft.seconds}s`
    }
    //SECONDS
    if (isSecondsLeft) {
      return timeLeft.seconds < 9
        ? `0${timeLeft.seconds}s`
        : `${timeLeft.seconds}s`
    }
  }

  if (isNoTimeLeft) {
    return null
  } else {
    return (
      <div className="flex gap-2 items-center font-medium">
        <div className="bg-[#ff0000] w-2 h-2 rounded-full"></div>
        <div>Ends in {timeToBeShown()}</div>
      </div>
    )
  }
}
