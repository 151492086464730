import React from 'react'
import { Container } from 'reactstrap'
import './Footer.css'
import { useNavigate } from 'react-router'
import { sendAnalytics } from '../../../utils/mixpannelConn'

const Footer = () => {
  const navigate = useNavigate()

  const handlePrivacy = () => {
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Privacy Clicked', [
      'moengage',
      'mixpanel',
    ])

    navigate('/privacy')
  }

  const handleTerms = () => {
    navigate('/terms')
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Terms Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleSupport = () => {
    navigate('/support')
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Support Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleReturn = () => {
    navigate('/return')
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Return Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  return (
    <div className="hidden md:flex mt-auto footer-distributed justify-center">
      <footer className=" w-full xl:w-[60%] mt-4 mb-[10%] md:mb-0">
        <Container className="footer-container flex justify-between">
          <div className="footer-left">
            <p className="footer-links flex gap-2">
              <button className="button-1" onClick={handlePrivacy}>
                Privacy
              </button>
              <button onClick={handleTerms}>Terms</button>
              <button onClick={handleSupport}>Support</button>
              <button onClick={handleReturn}>Return</button>
            </p>

            <p>
              <a
                className="footer-brand-copyright"
                target="_blank"
                href="https://simpleviralgames.com"
                rel="noreferrer"
              >
                &copy; RAHSOU SIMPLE VIRAL GAMES PVT LTD, 2022-2023
              </a>
            </p>
          </div>

          <div className="footer-right">
            <a
              href="https://www.facebook.com/simpleviralgames"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://twitter.com/simpleviralgame"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/simpleviralgames"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <p className="version">3.1.0</p>
          </div>
        </Container>
      </footer>
    </div>
  )
}

export default Footer
