import classNames from 'classnames'
import { Fragment, useEffect } from 'react'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import { useGetTournamentsMutation } from '../../../store/tournament/action'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { CarouselCard } from './CarouselCard'
import { setTournament } from '../../../store/tournament/slice'
import { useDispatch, useSelector } from 'react-redux'

export const Carousal = ({ handlePlayInTournamentFromActive }) => {
  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('100% 0% 100% 0%')
  const activeTournament = useSelector((state) => state.tournament)
  const [
    getActiveTournamentsFromMutation,
    { isLoading: isActiveTournamentsLoading },
  ] = useGetTournamentsMutation()

  const dispatch = useDispatch()

  const getActiveTournaments = async (pg) => {
    try {
      const res = await getActiveTournamentsFromMutation(pg).unwrap()
      if (
        !activeTournament?.data ||
        activeTournament?.data?.results?.length === 0
      ) {
        dispatch(setTournament(res))
      } else {
        dispatch(
          setTournament({
            ...res,
            results: [...activeTournament?.data?.results, ...res?.results],
          })
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      !activeTournament.data ||
      activeTournament?.data?.results?.length === 0
    ) {
      getActiveTournaments(1)
    }
  }, [])

  useEffect(() => {
    if (isVisible && activeTournament?.data?.next) {
      getActiveTournaments(activeTournament?.data?.current_page + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, activeTournament])

  const carouselSettings = {
    autoplay: activeTournament?.data?.results?.length === 3 ? false : true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    accesibility: false,
    swipe: false,
    touchMove: false,
    afterChange: (index) => {
      if (
        activeTournament?.data?.results?.length - 4 === index &&
        activeTournament?.data?.next
      ) {
        getActiveTournaments(activeTournament?.data?.current_page + 1)
      }
    },
  }

  if (activeTournament?.data?.results?.length > 2) {
    return (
      <Fragment>
        <div className="w-full flex lg:hidden flex-col justify-center gap-4 mb-2 mt-4 px-[8px] sm:px-[0px] md:px-[0px]">
          <div className="text-lg text-black font-bold ">
            Active Tournaments
          </div>
          <div className="text-white no-scrollbar overflow-x-scroll flex gap-2 md:p-0 w-full  items-center scroll-smooth">
            {activeTournament?.data?.results?.map((active, idx) => {
              return (
                <div
                  key={active?.uuid}
                  style={{ backgroundColor: `${active?.bg_colour}` }}
                  className={classNames(
                    `px-[20px]  no-scrollbar rounded-[8px] flex gap-4 md:justify-between w-[350px] md:min-w-[500px] h-[180px] md:h-[220px] relative touramentId-${active?.uuid} tornament-item`
                  )}
                >
                  <CarouselCard
                    active={active}
                    handlePlayInTournamentFromActive={
                      handlePlayInTournamentFromActive
                    }
                  />
                </div>
              )
            })}
            <div
              ref={toBeIntersectedImgRef}
              className="min-w-[10px] h-[100px]"
            ></div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center w-full absolute top-[85%] custombp:top-[90%] tournament-wrapper cursor-pointer">
          <Slider {...carouselSettings} className="w-[1200px]">
            {activeTournament?.data?.results?.map((active) => (
              <div key={active?.uuid}>
                <div
                  style={{ backgroundColor: `${active?.bg_colour}` }}
                  className={classNames(
                    ` px-[20px] no-scrollbar rounded-[22px] overflow-hidden  whitespace-nowrap text-white flex md:justify-between w-[350px] md:min-w-[100%] h-[180px] md:h-[220px]  relative touramentId-${active?.uuid} tornament-item `
                  )}
                >
                  <CarouselCard
                    active={active}
                    handlePlayInTournamentFromActive={
                      handlePlayInTournamentFromActive
                    }
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Fragment>
    )
  } else if (
    activeTournament?.data?.results?.length > 0 &&
    activeTournament?.data?.results?.length <= 2
  ) {
    return (
      <Fragment>
        <div className="w-full flex lg:hidden flex-col justify-center gap-4 mb-2 mt-4 px-[8px] sm:px-[0px] md:px-[0px]">
          <div className="text-lg text-black font-bold ">
            Active Tournaments
          </div>
          <div className="text-white no-scrollbar overflow-x-scroll flex gap-2 md:p-0 w-full  items-center scroll-smooth">
            {activeTournament?.data?.results?.map((active, idx) => {
              return (
                <div
                  key={active?.uuid}
                  style={{ backgroundColor: `${active?.bg_colour}` }}
                  className={classNames(
                    `px-[20px]  no-scrollbar rounded-[8px] flex gap-4 md:justify-between w-[350px] md:min-w-[500px] h-[180px] md:h-[220px] relative touramentId-${active?.uuid} tornament-item`
                  )}
                >
                  <CarouselCard
                    active={active}
                    handlePlayInTournamentFromActive={
                      handlePlayInTournamentFromActive
                    }
                  />
                </div>
              )
            })}
            <div
              ref={toBeIntersectedImgRef}
              className="min-w-[10px] h-[100px]"
            ></div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center w-full absolute top-[85%] custombp:top-[90%] tournament-wrapper cursor-pointer">
          <div
            style={{
              backgroundColor: `${activeTournament?.data?.results?.[0]?.bg_colour}`,
            }}
            className={classNames(
              ` px-[20px] no-scrollbar rounded-[22px] overflow-hidden  whitespace-nowrap text-white flex md:justify-between w-[600px] h-[180px] md:h-[220px]  relative  tornament-item `
            )}
          >
            <CarouselCard
              active={activeTournament?.data?.results?.[0]}
              handlePlayInTournamentFromActive={
                handlePlayInTournamentFromActive
              }
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
