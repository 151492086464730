import { createSlice } from '@reduxjs/toolkit'

export const allGamesSlice = createSlice({
  name: 'all-games',
  initialState: {
    data: [],
    isLoading: false,
    isSuccess: false,
    totalpage: 0,
  },
  reducers: {
    setAllGames: (state, action) => {
      state.data = action.payload.data
      state.totalpage = action.payload.total_page
      state.isSuccess = true
    },
  },
})

export const { setAllGames } = allGamesSlice.actions
export default allGamesSlice.reducer
