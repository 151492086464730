import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
}

export const deleteSlice = createSlice({
  name: 'deleteR',
  initialState,
  reducers: {
    deleteReel: (state, action) => {
      state.data = action.payload
      state.isSuccess = true
    },
  },
})

export const { deleteReel } = deleteSlice.actions
export default deleteSlice.reducer
