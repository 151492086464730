import { GameCard } from './GameCard'
import { GamesBarWrapper } from './GamesBarWrapper'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import { useUserSavedGamesMutation } from '../../../store/user/actions'
import { setSavedGames } from '../../../store/SavedGames/savedSlice'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

const SavedGames = ({ savedGames, handleSavedGameClick, totalPage }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('100% 0% 100% 0%')
  const [userSavedGames, { isLoading: isSavedGamesLoading }] =
    useUserSavedGamesMutation()
  const dispatch = useDispatch()

  const getUserSavedGames = async (savedGamesPage) => {
    try {
      const savedRes = await userSavedGames(savedGamesPage).unwrap()
      const saveGame = savedRes.results
      dispatch(
        setSavedGames({
          data: [...savedGames.data, ...saveGame],
          total_page: savedRes.total_page,
        })
      )
      setCurrentPage((prev) => prev + 1)
      return savedRes
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isVisible && totalPage >= currentPage + 1) {
      getUserSavedGames(currentPage + 1)
    }
  }, [isVisible])

  return (
    <>
      <p className="text-lg text-black font-bold leading-6 sm:pl-0 pl-2 pt-4">
        Saved Games
      </p>
      <GamesBarWrapper>
        {savedGames?.data?.map((data, idx) => {
          return (
            <GameCard
              data={data.game}
              idx={idx}
              ref={toBeIntersectedImgRef}
              handleGameCardClick={handleSavedGameClick}
              allGamesLength={savedGames?.data?.length}
            />
          )
        })}
      </GamesBarWrapper>
    </>
  )
}
export default SavedGames
