import { useEffect, useRef } from 'react'

export const useIntersectionObserver = (callback, options) => {
  const targetRef = useRef(null)
  const observerRef = useRef(null) // Store the observer instance

  useEffect(() => {
    if (targetRef.current) {
      observerRef.current = new IntersectionObserver(callback, options)
      observerRef.current.observe(targetRef.current)
    }

    return () => {
      if (observerRef.current && targetRef.current) {
        observerRef.current.unobserve(targetRef.current)
      }
    }
  }, [callback, options])

  const observeElement = (element) => {
    if (element) {
      targetRef.current = element
      // If the observer already exists, unobserve the previous element and start observing the new one
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current.observe(element)
      }
    }
  }

  return observeElement
}
