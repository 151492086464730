import React, { useEffect, useRef, useState } from 'react'
import { useUserReelRecomendationMutation } from '../../../store/recommendation/action'
import { scrollend } from 'scrollyfills'
import {
  Link,
  json,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { FaChevronCircleDown, FaChevronCircleUp, FaHome } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  Error,
  GameShare,
  Like,
  Liked,
  Mute,
  Play,
  PremiumCrown,
  Unmute,
  Whatsapp,
} from '../../component/icones'
import { toast } from 'react-hot-toast'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import ReactPlayer from 'react-player'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { BuyPremiumGameModal } from '../../component/Common/BuyPremiumGameModal'
import { ChangeAvatarModal } from '../../component/Multiplayer/ChangeAvatarModal'
import { EnterNameModal } from '../../component/Multiplayer/EnterNameModal'
import { MoreDetailsModal } from '../../component/Home/MoreDetailsModal'
import { YearPickerModal } from '../../component/Common/YearPickerModal'
import { usePostPremiumConfigMutation } from '../../../store/premium/action'
import LoginModal from '../../component/Common/LoginModal'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import OtpModal from '../../component/Common/OtpModal'
import { useLoginInfo } from '../../../hooks/useLoginInfo'
import { setRecommendations } from '../../../store/recommendation/slice'
import { setOtpToStore } from '../../../store/login/loginSlice'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import {
  useUserAddGamesMutation,
  useUserDeleteGamesMutation,
} from '../../../store/user/actions'
import { showNotification } from '../../../utils/showNotification'
import { removeAllCategory } from '../../../store/ViewAll/categorySlice'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { useIsOnSafari } from '../../../hooks/useIsOnSafari'
import classNames from 'classnames'

const ReelsPage = () => {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)
  const navigate = useNavigate()
  const isLarge = useMediaQuery('(min-width:579px)')
  const [isMuted, setIsMuted] = useLocalStorage('muted', null)

  const isIOSorSafari = useIsOnSafari()

  const [currentPage, setCurrentPage] = useState(1)

  const location = useLocation()

  const videoRefs = useRef({})
  const [
    userReelRecomendation,
    { isLoading: isReelRecomendationLoading, isSuccess, isError },
  ] = useUserReelRecomendationMutation()

  const [postPremiumConfig, { isLoading: isPremiumPostGameConfigLoading }] =
    usePostPremiumConfigMutation()

  const {
    counter,
    handleGetOtp,
    handleMobileNumberChange,
    isCorrectMobileNumber,
    otp,
    handleSubmitOTP,
    setOtp,
    handleResendOTP,
    mobileNumber,
    otpSent,
    isVerifyOtpLoading,
    countryCode,
  } = useLoginInfo()

  const [userAddGames, { isLoading: isSaveLoading }] = useUserAddGamesMutation()

  const [userDeleteGames, { isLoading: isDeleteLoading }] =
    useUserDeleteGamesMutation()

  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('200px', 0.5)

  const [idForView, setIdForView] = useState({ id: '', isMuted: true })

  const reelContainerRef = useRef(null)

  const recommendation = useSelector((state) => state.recommendation)

  const allCategory = useSelector((state) => state.allCategory)

  const allCategoryId = useSelector((state) => state.allCategoryId)

  const [isNormalLogin, setIsNormalLogin] = useState(false)

  const {
    handleAvatarChange,
    handleSaveNameAvatar,
    onSelectedAvatar,
    toggleAvatarModalOnScreen,
    toggleEnterNameModalOnScreen,
    homePageModals,
    setHomePageModals,
    handleChangeAvatar,
    goBackFromEnterModal,
    handleNameChange,
    onClickYearOfBirth,
    handleProfile,
    handleGenderChange,
    goBackFromMoreDetails,
    toggleMoreDetailsOnScreen,
    handleNextToMoreDetails,
    handleGoBackFromYearPick,
    handleYearChange,
    toggleYearOfBirthOnScreen,
    handleNavigationHistoryAndNotif,
    premiumGameConfig,
    setPremiumGameConfig,
    getUserStreakData,
    recordHistoryAndSendNotif,
    togglePremiumGameModal,
    handleCancelBuy,
    getPremiumData,
    getUserSavedGames,
    fetchCategories,
    getAllCategoryId,
    getUserContinuePlay,
  } = useProfileUpdate()

  const muteRef = useRef([])

  const getUserReelRecomendation = async (page) => {
    try {
      const allRecommds = await userReelRecomendation(page).unwrap()
      const updatedRecommends = allRecommds?.results?.reduce((acc, val) => {
        return [...acc, { uuid: val.uuid, muted: true }]
      }, [])

      muteRef.current = [...muteRef.current, ...updatedRecommends]

      dispatch(
        setRecommendations({
          data: [...recommendation.data, ...allRecommds?.results],
          totalpage: allRecommds?.total_page,
        })
      )
      return allRecommds
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isVisible && recommendation?.totalpage >= currentPage + 1) {
      getUserReelRecomendation(currentPage + 1)
      setCurrentPage((prev) => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  useEffect(() => {
    if (recommendation?.data?.length > 0 && !!location?.state?.from) {
      const reelElement = document.querySelector(
        `.reelId-${location?.state?.from}`
      )
      reelElement?.scrollIntoView({ behavior: 'instant' })
      const reelIdx = recommendation?.data?.findIndex(
        (reel) => reel?.uuid === location?.state?.from
      )
      if (
        reelIdx > recommendation?.data?.length - 5 &&
        recommendation?.totalpage >= currentPage + 1
      ) {
        getUserReelRecomendation(currentPage + 1)
        setCurrentPage((prev) => prev + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, recommendation])

  useEffect(() => {
    if (recommendation?.data?.length === 0) {
      getUserReelRecomendation(1)
    }
  }, [recommendation])

  const handleMuteInUI = (isMuted) => {
    if (isMuted) {
      setIsMuted(true)
    } else {
      setIsMuted(null)
    }
  }

  const handleMuteToggle = (name, id) => {
    let videoMutedState = true
    reelContainerRef?.current?.children?.forEach((reel) => {
      const videoTag = reel?.firstChild?.firstChild
      const isVideoTag = videoTag?.tagName === 'VIDEO'
      const idOnView = reel?.classList[0]?.split('reelId-')[1]
      if (!!isVideoTag && id === idOnView) {
        videoMutedState = videoTag.muted
        if (videoTag.muted) {
          videoTag.muted = false
          handleMuteInUI(false)
        } else {
          handleMuteInUI(true)
          videoTag.muted = true
        }
      }
    })
    const properties = {
      GAME_NAME: name,
      GAME_UUID: id,
      SOUND_MUTED: videoMutedState,
    }

    sendAnalytics('track', properties, 'Sound Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  useEffect(() => {
    if (!!idForView?.id) {
      const reelElement = document.querySelector(`.reelId-${idForView?.id}`)
      if (!!reelElement) {
        reelElement?.scrollIntoView({ behavior: 'instant' })
      }
    }
  }, [idForView])

  const handlePlayNowClick = (
    slug,
    isPremium,
    isMultiPlayer,
    uid,
    game_name,
    updatedAt,
    expiresAt = null,
    categoryId = 'recommendation',
    currentPage = 1
  ) => {
    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }

  const handleCopyUrl = (name, uuid, url) => {
    const properties = {
      GAME_NAME: name,
      GAME_UUID: uuid,
      SHEET_SHOW_FROM: 'Reelspage',
    }
    sendAnalytics('track', properties, 'Share Pressed', [
      'moengage',
      'mixpanel',
    ])
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.dismiss()
        toast.success('Link copied to clipboard')
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error)
      })
  }
  const handleShareOnWhatsApp = (name, uuid, shareableText) => {
    const properties = {
      GAME_NAME: name,
      GAME_UUID: uuid,
      SHEET_SHOW_FROM: 'Reelspage',
    }

    sendAnalytics('track', properties, 'Share Pressed', [
      'moengage',
      'mixpanel',
    ])

    const encodedText = encodeURIComponent(shareableText)
    const url = `https://api.whatsapp.com/send?text=${encodedText}`
    window.open(url, '_blank')
  }

  const handleReelAudio = () => {
    reelContainerRef?.current?.children?.forEach((reel) => {
      const videoTag = reel?.firstChild?.firstChild
      const isVideoTag = videoTag?.tagName === 'VIDEO'
      if (!!isVideoTag) {
        if (reel?.getBoundingClientRect()?.top === 0) {
          videoTag.play()
        } else {
          videoTag.pause()
        }
        videoTag.muted = true
        handleMuteInUI(true)
      }
    })
  }

  useEffect(() => {
    if (recommendation?.data?.length > 0) {
      const currentRef = reelContainerRef.current

      currentRef.addEventListener('scrollend', handleReelAudio)

      return () => currentRef.removeEventListener('scrollend', handleReelAudio)
    }
  }, [recommendation])

  const handleConsumePlay = async () => {
    try {
      const res = await postPremiumConfig(premiumGameConfig.uid).unwrap()
      if (res.is_bought) {
        const recordHistoryRes = recordHistoryAndSendNotif(
          premiumGameConfig.uid,
          premiumGameConfig.game_name,
          premiumGameConfig.updatedAt
        )

        const properties = {
          GAME_NAME: premiumGameConfig.game_name,
          GAME_UUID: premiumGameConfig.uid,
        }

        sendAnalytics('track', properties, 'Buy Premium Game', [
          'moengage',
          'mixpanel',
        ])

        if (recordHistoryRes.message === 'Game history saved.') {
          navigate(`/${premiumGameConfig.slug}`)
          dispatch(removeAllCategory())

          if (allCategoryId?.data?.length === 0) {
            getAllCategoryId().then((res) => {
              if (res) {
                fetchCategories(res)
              }
            })
          } else {
            fetchCategories(allCategoryId?.data)
          }
          getUserSavedGames(1)
        }

        navigate(`/${premiumGameConfig.slug}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleLoginModal = (screenName) => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: false,
      }
    })
  }

  const handleEnterPhoneNumber = async () => {
    const res = await handleGetOtp()
    if (res?.message === 'OTP Sent Successfully') {
      setHomePageModals((prev) => {
        return {
          ...prev,
          isLoginModalOpen: false,
          isOtpModalOpen: true,
        }
      })
    }
  }

  const handleSubmitOTPAndCloseModals = async () => {
    const res = await handleSubmitOTP()
    if (res?.message === 'Logged In') {
      setHomePageModals((prev) => ({
        ...prev,
        isOtpModalOpen: false,
        isLoginModalOpen: false,
      }))
      if (!!premiumGameConfig?.slug) {
        const premiumData = await getPremiumData(
          premiumGameConfig?.uid,
          premiumGameConfig?.slug
        )

        if (premiumData?.is_bought) {
          navigate(`/${premiumGameConfig?.slug}`, { state: { from: '/reels' } })
          recordHistoryAndSendNotif(
            premiumGameConfig?.uid,
            premiumGameConfig?.name,
            premiumGameConfig?.updatedAt
          )
          return
        }
        //code should always run from here
        setHomePageModals((prev) => {
          return {
            ...prev,
            isBuyPremiumGameModalOpen: true,
          }
        })

        setPremiumGameConfig({
          ...premiumData,
          uid: premiumGameConfig?.uid,
          game_name: premiumGameConfig?.game_name,
          slug: premiumGameConfig?.slug,
          updatedAt: premiumGameConfig?.updatedAt,
          categoryId: 'recommendation',
          currentPage: 1,
        })
      }
    }
  }

  const handleChangeNumber = () => {
    dispatch(setOtpToStore(false))
    setOtp('')
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: true,
        isOtpModalOpen: false,
      }
    })
    const properties = {
      SHOWN_FROM: 'ReelPage',
    }

    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleSaveClick = async (name, uuid, isFavorite) => {
    if (!loginState?.accessToken) {
      toast.dismiss()
      toast.error('Please login to save the game', {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
      setHomePageModals((prev) => {
        return {
          ...prev,
          isLoginModalOpen: true,
        }
      })
      setIsNormalLogin(true)
      return
    }
    if (!isFavorite) {
      try {
        const res = await userAddGames({ game: uuid }).unwrap()

        if (res?.message === 'Added to Favorite List') {
          const modifiedReels = recommendation?.data?.map((reel) => {
            return reel.uuid === uuid ? { ...reel, favorite: true } : reel
          })
          const properties = {
            GAME_NAME: name,
            GAME_UUID: uuid,
            GAME_FROM: 'Reel',
            LOGIN_STATUS: true,
          }
          sendAnalytics('track', properties, 'Save Button Pressed', [
            'moengage',
            'mixpanel',
          ])
          dispatch(
            setRecommendations({
              data: modifiedReels,
              totalpage: recommendation?.totalpage,
            })
          )
          setIdForView({ id: uuid, isMuted: !idForView?.isMuted })
          getUserSavedGames()

          toast.dismiss()
          toast.success('Added to saved games')

          if (res?.notification?.length > 0) {
            showNotification(res?.notification)
            getUserStreakData()
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleUnSaveReel = async (uuid, isFavorite) => {
    if (isFavorite) {
      try {
        const res = await userDeleteGames({
          game: uuid,
        }).unwrap()

        const modifiedReels = recommendation?.data?.map((reel) => {
          return reel.uuid === uuid ? { ...reel, favorite: false } : reel
        })
        dispatch(
          setRecommendations({
            data: modifiedReels,
            totalpage: recommendation?.totalpage,
          })
        )
        setIdForView({ id: uuid, isMuted: !idForView?.isMuted })
        getUserSavedGames()

        toast.dismiss()
        toast.success('Removed from saved games')
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleHomePressClick = () => {
    if (!!loginState?.accessToken) {
      getUserStreakData()
      getUserSavedGames(1)
      getUserContinuePlay(1)
      getAllCategoryId().then((res) => {
        if (res) {
          dispatch(removeAllCategory())
          fetchCategories(res)
        }
      })
    }
    navigate('/')
  }

  return (
    <div className="w-screen bg-white relative overflow-y-auto h-screen">
      <LoginModal
        isOpen={homePageModals.isLoginModalOpen}
        toggle={toggleLoginModal}
        mobileNumber={`${countryCode}${mobileNumber}`}
        handleMobileNumberChange={handleMobileNumberChange}
        handleGetOtp={handleEnterPhoneNumber}
        isCorrectMobileNumber={isCorrectMobileNumber} // Assuming this function is defined
        isNormalLogin={isNormalLogin}
      />
      <OtpModal
        isOpen={homePageModals.isOtpModalOpen}
        mobileNumber={`${countryCode}${mobileNumber}`}
        otp={otp}
        setOtp={setOtp}
        handleChangeNumber={handleChangeNumber} //HANDLE HERE
        handleSubmitOTP={handleSubmitOTPAndCloseModals}
        handleResendOTP={handleResendOTP}
        otpSent={otpSent} // Assuming this variable is defined
        counter={counter} // Assuming this variable is defined
        isVerifyOtpLoading={isVerifyOtpLoading} // Assuming this variable is defined
      />
      <EnterNameModal
        goBackFromEnterModal={goBackFromEnterModal}
        handleChangeAvatar={handleChangeAvatar}
        handleJoinCreatedRoom={handleSaveNameAvatar}
        handleNameChange={handleNameChange}
        isEnterNameModalOpen={homePageModals.isEnterNameModalOpen}
        selectedAvatar={loginState?.selectedAvatar}
        userNameFromAgora={loginState?.name}
        toggleModalOnScreen={toggleEnterNameModalOnScreen}
        firstAvatar={loginState?.avatars?.[0]?.src}
      />
      <MoreDetailsModal
        isMoreDetailsModalOpen={homePageModals.isMoreDetailsModalOpen}
        onClickYearOfBirth={onClickYearOfBirth}
        gender={loginState.gender}
        yob={loginState.yearOfBirth}
        handleGenderChange={handleGenderChange}
        goBackFromMoreDetails={goBackFromMoreDetails}
        toggleMoreDetailsOnScreen={toggleMoreDetailsOnScreen}
        handleNextToMoreDetails={handleNextToMoreDetails}
      />
      <YearPickerModal
        isEnterYearOfBirthModalOpen={homePageModals.isEnterYearOfBirthModalOpen}
        handleGoBackFromYearPick={handleGoBackFromYearPick}
        handleYearChange={handleYearChange}
        yob={loginState.yearOfBirth}
        toggleYearOfBirthOnScreen={toggleYearOfBirthOnScreen}
      />
      <BuyPremiumGameModal
        isBuyPremiumGameModalOpen={homePageModals.isBuyPremiumGameModalOpen}
        togglePremiumGameModal={togglePremiumGameModal}
        gameName={premiumGameConfig.game_name}
        handleCancelBuy={handleCancelBuy}
        handleConsumePlay={handleConsumePlay}
        gameCost={premiumGameConfig.game_cost}
        gameDuration={premiumGameConfig?.validity}
        uid={premiumGameConfig.uid}
      />
      <ChangeAvatarModal
        avatars={loginState?.avatars}
        handleAvatarChange={handleAvatarChange}
        isChangeAvatarModalOpen={homePageModals.isChangeAvatarModalOpen}
        onSelectedAvatar={onSelectedAvatar}
        selectedAvatar={loginState?.selectedAvatar}
        firstAvatar={loginState?.avatars?.[0]?.src}
        toggleModalOnScreen={toggleAvatarModalOnScreen}
      />
      <EnterNameModal
        goBackFromEnterModal={goBackFromEnterModal}
        handleChangeAvatar={handleChangeAvatar}
        handleJoinCreatedRoom={handleSaveNameAvatar}
        handleNameChange={handleNameChange}
        isEnterNameModalOpen={homePageModals.isEnterNameModalOpen}
        selectedAvatar={loginState?.selectedAvatar}
        userNameFromAgora={loginState?.name}
        toggleModalOnScreen={toggleEnterNameModalOnScreen}
      />
      <MoreDetailsModal
        isMoreDetailsModalOpen={homePageModals.isMoreDetailsModalOpen}
        onClickYearOfBirth={onClickYearOfBirth}
        gender={loginState.gender}
        yob={loginState.yearOfBirth}
        handleGenderChange={handleGenderChange}
        goBackFromMoreDetails={goBackFromMoreDetails}
        toggleMoreDetailsOnScreen={toggleMoreDetailsOnScreen}
        handleNextToMoreDetails={handleNextToMoreDetails}
      />
      <YearPickerModal
        isEnterYearOfBirthModalOpen={homePageModals.isEnterYearOfBirthModalOpen}
        handleGoBackFromYearPick={handleGoBackFromYearPick}
        handleYearChange={handleYearChange}
        yob={loginState.yearOfBirth}
        toggleYearOfBirthOnScreen={toggleYearOfBirthOnScreen}
      />
      <div
        onClick={handleHomePressClick}
        className="fixed w-11 h-11 top-[20%] left-0 bg-green-700 text-white mx-auto text-center z-50 "
      >
        <FaHome className="mt-2.5 mx-auto" color="white" size={20} />
      </div>
      <div className="md:absolute md:top-2/4 flex items-center justify-center md:left-2/4 md:text-center md:transform md:-translate-x-1/2 md:-translate-y-1/2">
        <div
          className="reels relative h-screen w-screen max-w-md md:max-w-[60%] lg:max-w-[35%]  my-auto overflow-y-scroll snap-y snap-mandatory"
          ref={reelContainerRef}
        >
          {recommendation?.data?.map((reel, index) => {
            return (
              <div
                className={`reelId-${reel.uuid} videos relative h-full w-full aspect-video snap-always snap-center`}
                key={reel?.uuid}
              >
                {reel?.property?.reel_mode === 'video' && (
                  <ReactPlayer
                    playing
                    width={'100%'}
                    height={'100%'}
                    controls={false}
                    loop
                    muted={true}
                    playsinline
                    url={reel.property.video}
                    onReady={(player) => {
                      videoRefs.current[reel.uuid] = player.getInternalPlayer()
                    }}
                    config={{
                      file: {
                        attributes: {
                          style: {
                            objectFit: 'fill',
                            width: '100%',
                            height: '100%',
                          },
                        },
                      },
                    }}
                  />
                )}
                {reel?.property?.reel_mode === 'image' && (
                  <img
                    src={reel.property.image}
                    className="h-full w-full"
                    alt=""
                  />
                )}

                <div
                  className={classNames(
                    'absolute z-50 gap-2 sm:gap-4 flex flex-row  justify-between items-end bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent px-4 pt-2 text-left',
                    {
                      'pb-32 sm:pb-8': isIOSorSafari,
                      'pb-16 sm:pb-8': !isIOSorSafari,
                    }
                  )}
                >
                  <div className="flex flex-col items-center gap-3 w-[70%]  ">
                    <div className="flex flex-row items-center gap-3 w-full  ">
                      <div className="h-12 rounded-md w-[17%] self-end">
                        <img
                          src={reel?.property?.thumbnail}
                          className="h-12 w-12 rounded-md"
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col w-[83%]">
                        <div className="flex items-center gap-2">
                          <h2 className="text-white text-base  xl:text-xl font-bold mb-0">
                            {reel?.property?.name}
                          </h2>
                          {reel?.is_premium && (
                            <PremiumCrown width="30px" height="35px" />
                          )}
                        </div>
                        <div className="text-white text-xs xl:text-sm font-bold mb-0 ">
                          {`${reel?.property?.subtitle?.substring(0, 60)}...`}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        handlePlayNowClick(
                          reel?.slug,
                          reel?.is_premium,
                          reel?.multiplayer,
                          reel?.uuid,
                          reel?.name,
                          reel?.updated_at,
                          reel?.expires_at
                        )
                      }
                      className="cursor-pointer w-full flex flex-row items-center px-4 py-2 justify-between  rounded-full bg-[#f3d505]"
                    >
                      <span className="rounded-full text-start text-lg sm:text-xl md:text-2xl lg:text-xl font-semibold">
                        Play Now
                      </span>
                      <Play />
                    </button>
                  </div>
                  <div className="flex flex-col items-center mb-0 sm:mb-3 gap-2">
                    {reel?.property?.reel_mode === 'video' && (
                      <div
                        className="flex flex-col mb-0 sm:mb-3 gap-2"
                        onClick={() => handleMuteToggle(reel.name, reel?.uuid)}
                      >
                        <div
                          className="flex flex-col items-center px-2 cursor-pointer"
                          style={{ width: '60px' }}
                        >
                          {!!isMuted ? <Unmute /> : <Mute />}
                          <span className="text-white font-bold">
                            {!!isMuted ? 'Unmute' : 'Mute'}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col items-center cursor-pointer">
                      {isLarge ? (
                        <GameShare
                          onClick={() =>
                            handleCopyUrl(
                              reel?.name,
                              reel?.uuid,
                              reel?.property?.shareable_text
                            )
                          }
                        />
                      ) : (
                        <Whatsapp
                          onClick={() =>
                            handleShareOnWhatsApp(
                              reel?.name,
                              reel?.uuid,
                              reel?.property?.shareable_text
                            )
                          }
                        />
                      )}
                      <span className="text-white font-bold">Share</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer">
                      {reel?.favorite ? (
                        <Liked
                          onClick={() => {
                            handleUnSaveReel(reel.uuid, reel.favorite)
                          }}
                        />
                      ) : (
                        <Like
                          onClick={() => {
                            handleSaveClick(reel.name, reel.uuid, reel.favorite)
                          }}
                        />
                      )}
                      {reel?.favorite ? (
                        <span className="text-white font-bold">Saved</span>
                      ) : (
                        <span className="text-white font-bold">Save</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          <div ref={toBeIntersectedImgRef} className="w-40 h-[10vh]"></div>
        </div>
      </div>
    </div>
  )
}
export default ReelsPage
