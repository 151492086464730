import { configureStore } from '@reduxjs/toolkit'
import gameReducer from './game/gameSlice'
import allGameReducer from './games/allGameSlice'
import loginReducer from './login/loginSlice'
// import historyReducer from './user/userSlice'
import { apiSlice } from './api/apiSlice'
import reelsReducer from './reels/reelsSlice'
import savedGamesReducer from './SavedGames/savedSlice'
import deleteReducer from './DeleteGames/deleteSlice'
import continuePlayReducer from './ContinuePlay/continuePlaySlice'
import categoryReducer from './ViewAll/categorySlice'
import categoryIdReducer from './categoryId/categoryIdSlice'
import allGamesReducer from './allGames/allGamesSlice'
import streaksReucer from './streaks/streakSlice'
import coinsReducer from './coins/slice'
import userHistoryReducer from './user/slice'
import recommendationReducer from './recommendation/slice'
import tournamentReducer from './tournament/slice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    game: gameReducer,
    games: allGameReducer,
    login: loginReducer,
    reels: reelsReducer,
    savedGames: savedGamesReducer,
    deleteR: deleteReducer,
    continuePlay: continuePlayReducer,
    allCategory: categoryReducer,
    allCategoryId: categoryIdReducer,
    allGames: allGamesReducer,
    streaks: streaksReucer,
    coins: coinsReducer,
    userHistory: userHistoryReducer,
    recommendation: recommendationReducer,
    tournament: tournamentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})
