import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  totalpage: 0, // New field to store the total number of pages
}

export const continuePlaySlice = createSlice({
  name: 'continuePlay',
  initialState,
  reducers: {
    setContinuePlay: (state, action) => {
      state.data = action.payload.data
      state.totalpage = action.payload.total_page // Assuming your API response has a property named "total_page"
      state.isSuccess = true
    },
  },
})

export const { setContinuePlay } = continuePlaySlice.actions
export default continuePlaySlice.reducer
