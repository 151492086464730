import { apiSlice } from '../api/apiSlice'

export const activeTournamentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTournaments: builder.mutation({
      query: (pg) => {
        return {
          url: `game/tournament?active=true&page_number=${pg}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const sendDownlaodLinkSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendDownloadLink: builder.mutation({
      query: (data) => {
        return {
          url: `game/send-download-link-message`,
          method: 'POST',
          body: { ...data },
        }
      },
    }),
  }),
})

export const { useGetTournamentsMutation } = activeTournamentSlice
export const { useSendDownloadLinkMutation } = sendDownlaodLinkSlice
