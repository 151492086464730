import { Link } from 'react-router-dom'
import Footer from '../../component/Footer/Footer'
import { useEffect } from 'react'

const ReturnPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="max-w-[960px] px-4 mx-auto sm:px-6 lg:px-8 mt-10">
        <h1>
          About <b>Timepass Games</b>
        </h1>
        <p>
          We&rsquo;re growing fast to achieve a big mission that drives us every
          day.
        </p>
        <p>
          Redefining games like never before. We build simple and engaging games
          which are lightweight, simple in mechanics and free to play.
        </p>
        <h1>Return, Refund and Cancellation Policies</h1>
        <p>
          In the event, that there is an error in the services provided by us,
          we may refund the subscription or purchase fee, provided the reasons
          are genuine and proved after investigation by Timepass Games.
        </p>
        <p>
          Please read the rules of tournaments, challenges, subscriptions and
          other purchases before participating.
        </p>
        <p>
          We do not cancel registrations for tournaments once entered, however,
          in case of exceptional circumstances wherein the fault may lie from
          our side, we will cancel your participation fee on request and refund
          the amount to Timepass Wallet within a reasonable amount of time.
        </p>
        <p>
          In case we cancel your participation in any game or tournament as a
          result of this, we will return coins to you within a reasonable amount
          of time for you to redeem the same by playing other tournaments on
          Timepass Games.
        </p>
        <p>
          We will try our best to create the best user experience for you. If
          paid by credit card, refunds will be issued to the original credit
          card provided at the time of purchase and in case of payments made
          through a payment gateway, payment refunds will be made to the same
          account.
        </p>
        <p>
          If you are not entirely satisfied with our services and want to
          request a refund, we will evaluate your request and will notify you
          about the status of your refund. If your refund request is approved,
          we will initiate a refund to your original method of payment within 7
          working days. You will receive the credit within a certain amount of
          days, depending on your bank/payment processor/bank issuers policies.
          The final decision lies with Timepass Games.
        </p>
      </div>
      <Footer />
    </>
  )
}
export default ReturnPage
