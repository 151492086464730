import { apiSlice } from '../../api/apiSlice'

export const logoutSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postLogout: builder.mutation({
      query: (credentials) => ({
        url: '/user/logout',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
})

export const { usePostLogoutMutation } = logoutSlice
