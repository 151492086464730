import React, { Fragment, useEffect, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router'
import ErrorPage from '../ErrorPage/ErrorPage'
import { FaHome, FaInfo } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useGetGameMutation } from '../../../store/game/actions'
import { use100ms } from '../../../hooks/use100ms'
import { eventHandler } from '../../../utils/touchEventHandler'
import {
  useGetAgoraTokenMutation,
  useGetAgoraUsersMutation,
  useGetJoinRoomInfoMutation,
  useGetParticipantsInfoMutation,
  useGetRoomInfoMutation,
  useLeaveRoomMutation,
  useStartRoomInfoMutation,
  useGetHmsTokenMutation,
  useGetMpVcConfigMutation,
  usePostMpVcConfigMutation,
} from '../../../store/communication/action'

import classNames from 'classnames'
import './Game.css'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { setGame } from '../../../store/game/gameSlice'
import {
  Mic,
  MicOff,
  Error,
  ExpanCircleIcon,
  MicBlocked,
  GameShare,
  Whatsapp,
  PremiumIcon,
  LeaderboardIcon,
} from '../../component/icones'
import { v4 as uuidv4 } from 'uuid'

import {
  Tooltip,
  CardImg,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
} from 'reactstrap'
import { useState } from 'react'
import {
  useUserNameMutation,
  useUserRecordHistoryMutation,
} from '../../../store/user/actions'
import { useAgoraUtils } from '../../../hooks/useAgoraUtils'
import { toast } from 'react-hot-toast'
import { CreateOrJoinRoom } from '../../component/Multiplayer/CreateOrJoinRoom'
import { CreateRoom } from '../../component/Multiplayer/CreateRoom'
import { JoinRoom } from '../../component/Multiplayer/JoinRoom'
import { LobbyRoom } from '../../component/Multiplayer/LobbyRoom'
import { PreCreateRoom } from '../../component/Multiplayer/PreCreateRoom'
import { PreJoinRoom } from '../../component/Multiplayer/PreJoinRoom'
import { EnterNameModal } from '../../component/Multiplayer/EnterNameModal'
import { ChangeAvatarModal } from '../../component/Multiplayer/ChangeAvatarModal'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { AreYouSureModal } from '../../component/Multiplayer/AreYouSureModal'
import Draggable from 'react-draggable'
import { DragIndicatorIcon } from '../../component/icones'
import { MicFun } from '../../component/Multiplayer/MicFun'
import { GameModeModal } from '../../component/Multiplayer/GameModeModal'
import { useUnload } from '../../../hooks/useUnload'
import { useMultiPlayerId } from '../../../hooks/useMultiPlayerId'
import { JoinRoomWithCode } from '../../component/Multiplayer/JoinRoomWithCode'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { Link } from 'react-router-dom'
import {
  ROOM_DEACTIVATED,
  GAME_STARTED,
} from '../../../utils/muliplayerMessages'
import { getLocalStream } from '../../../utils/getLocalStream'
import { Player } from '@lottiefiles/react-lottie-player'
import loginSlice, {
  setUserInfo,
  setAvatars,
  setOtpToStore,
} from '../../../store/login/loginSlice'
import { handleCopyUrl } from '../../../utils/copyToClipBoard'
import { BottomBar } from '../../component/Common/BottomBar'
import { ActivateVoiceModal } from '../../component/Multiplayer/ActivateVoiceModal'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import LoginModal from '../../component/Common/LoginModal'
import { LeaderBoardModal } from '../../component/Common/LeaderBoardModal'
import OtpModal from '../../component/Common/OtpModal'
import { useLoginInfo } from '../../../hooks/useLoginInfo'
import { BuyPremiumGameModal } from '../../component/Common/BuyPremiumGameModal'
import { usePostPremiumConfigMutation } from '../../../store/premium/action'
import { removeAllCategory } from '../../../store/ViewAll/categorySlice'
import { CheckAppInstalled } from '../../component/Common/CheckAppInstalled'
import { useGetLeaderBoardMutation } from '../../../store/leaderboard/actions'
import { useGetUsersRankMutation } from '../../../store/leaderboard/actions'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import { TP_CLIENT_ID } from '../../../constants'
import Home from '../Home/Home'
import { convertDateFormat } from '../../../utils/formatDate'
import GameProductPurchaseBottomSheet from '../../component/BottomSheet/GameProductPurchaseBottomSheet'

const Game = () => {
  const dispatch = useDispatch()

  const [getGame, { isLoading: isGameLoading, isError, isSuccess }] =
    useGetGameMutation()

  const [getUsersRank, { isLoading: isUsersRankLoading }] =
    useGetUsersRankMutation()

  const loginState = useSelector((state) => state.login)

  const [getJoinRoomInfo] = useGetJoinRoomInfoMutation()

  const [getRoomInfo] = useGetRoomInfoMutation()

  const [roomCode, setRoomCode] = useState('')

  const [gameStartCounter, setGameStartCounter] = useState(6)

  const [allMultiPlayerUsers, setAllMultiPlayerUsers] = useState([])

  const [isSheetOpen, setSheetOpen] = useState(false)

  const [gameProductId, setGameProductId] = useState(null)

  const [growthFactor, setGrowthFactor] = useState(null)

  const game = useSelector((state) => state.game)

  const location = useLocation()

  const { slug } = useParams()

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [isLeaderBoardOpen, setLeaderBoardOpen] = useState(false)

  const toggleLeaderBoard = () => {
    setLeaderBoardOpen((prev) => !prev)
  }

  const getUserNameFromStorage = () => {
    const userNameFromStorage = JSON.parse(localStorage.getItem('userName'))
    return userNameFromStorage ?? ''
  }

  const getUserAvatarFromStorage = () => {
    const userAvatar = JSON.parse(localStorage.getItem('avatar'))
    return userAvatar ?? ''
  }
  const [getAgoraToken, { isLoading: isTokenLoading }] =
    useGetAgoraTokenMutation()

  const cName = location.search.split('=')[1]
  const [channelName, setChannelName] = useState(cName)
  const uid = JSON.parse(localStorage.getItem('mobileNumber'))

  const [username, setUserName] = useState(getUserNameFromStorage())

  const [usedAvatar, setUsedAvatar] = useState(getUserAvatarFromStorage())

  const [userNameFromAgora, setAgoraUserName] = useLocalStorage(
    'agoraUserName',
    null
  )

  const [selectedAvatar, setSelectedAvatar] = useLocalStorage(
    'selectedAvatar',
    null
  )

  const [userName] = useUserNameMutation()
  const [showLoader, setShowLoader] = useState(true)

  const [areAllAvatarsVisible, setAllAvatarsVisible] = useState(false)

  const [createRoomDetails, setCreateRoomDetails] = useState({
    current_count: '',
    invite_link: '',
    max_capacity: '',
    message: '',
    room_id: '',
    shareable_text: '',
  })

  const [getAgoraUsers, { isLoading: isAvatarsLoading }] =
    useGetAgoraUsersMutation()

  const [getParticipantsInfo, { isLoading: isPartcipantsLoading }] =
    useGetParticipantsInfoMutation()

  const [modalOpen, setModalOpen] = useState(false)

  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const [isCreateRoomJoinRoomPopupOpen, setCreateRoomJoinRoomPopUpOpen] =
    useState(true)

  const [channelMessage, setChannelMessage] = useState('')

  const [isLobbyRoomModalOpen, setIsLobbyRoomModalOpen] = useState(false)

  const [isCreateRoomModalOpen, setCreateRoomModalOpen] = useState(false)

  const [isJoinRoomModalOpen, setJoinRoomModalOpen] = useState(false)

  const [isEnterNameModalOpen, setEnterNameModalOpen] = useState(false)

  const [isPreCreateRoomModalOpen, setPreCreateRoomModalOpen] = useState(false)

  const [isPreJoinRoomModalOpen, setPreJoinRoomModalOpen] = useState(false)

  const [isChangeAvatarModalOpen, setChangeAvatarModalOpen] = useState(false)

  const [isOpenAreYouSureModal, setOpenAreYouSureModal] = useState(false)

  const [isGameModeModalOpen, setGameModeModalOpen] = useState(false)

  const [gameParticipants, setGameParticipants] = useState([])

  const [isAudioChatEnabled, setIsAudioChatEnabled] = useState(false)

  const [coinsData, setCoinData] = useState({})

  const [roomIsFull, setRoomIsFull] = useState(false)

  const [postPremiumConfig, { isLoading: isPremiumPostGameConfigLoading }] =
    usePostPremiumConfigMutation()

  const [leaderBordDetails, setLeaderBoardDetails] = useState({})

  const [getLeaderBoard, { isLoading: isLeaderBoardLoading }] =
    useGetLeaderBoardMutation()

  const [isActivateVoiceChatModalOpen, setActivateVoiceChatModalOpen] =
    useState(false)

  const [host, setHost] = useState({})

  const inputRef = useRef()

  const navigate = useNavigate()

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const [roomKey, room_id] = location?.search?.split('=')

  const [joinRoomDetails, setJoinRoomDetails] = useState({})

  const [inviteLink, setInviteLink] = useState('')

  const allCategory = useSelector((state) => state.allCategory)

  const [maxCapacity, setMaxCapacity] = useState('')

  const [gameStarted, setGameStarted] = useState(false)

  const multiplayerId = useMultiPlayerId(room_id)

  const [agoraUid, setAgoraUid] = useState('')

  const [gameGameSuccessfullyStarted, setGameSuccessfullyStarted] =
    useState(false)

  const [gameIframe, setGameIframe] = useState('')

  const [selectedGameScreenPopup, setSelectedGameScreenPopup] = useState('')

  const [userRecordHistory] = useUserRecordHistoryMutation()

  const allCategoryId = useSelector((state) => state.allCategoryId)

  const [getMpVcConfig, { isMpVcConfigLoading: isLoading }] =
    useGetMpVcConfigMutation()

  const [postMpVcConfig] = usePostMpVcConfigMutation()

  const [startRoomInfo] = useStartRoomInfoMutation()

  const [leaveRoom] = useLeaveRoomMutation()

  const [getHmsToken] = useGetHmsTokenMutation()

  const [isJoinWithCodeModalOpen, setJoinWithCodeModalOpen] = useState(false)

  const [selectedGameMode, setSelectedGameMode] = useState('')

  const [joinedOrCreatedRoomFromStart, setJoinedOrCreatedRoomFromStart] =
    useState(false)

  const [shareableLink, setSharableLink] = useState('')

  const [userRank, setUserRank] = useState()

  const isMobile = useMediaQuery('(max-width:579px)')

  const [isHost, setIsHost] = useState(false)

  const [isRejoining, setIsRejoining] = useState(false)

  const [micMuted, setMicMuted] = useState(true)

  const [isSelectedAudioAgora, setIsSelectedAudioAgora] = useState(true)

  const api_url = `${process.env.REACT_APP_API_SERVER_URL}`

  const [selectedGameProduct, setSelectedGameProduct] = useState(null)

  const [fetchLeaderboard, setFetchLeaderboard] = useState(false)

  const encodeData = (data) => {
    return Object.keys(data)
      .map(function (key) {
        return [key, data[key]].map(encodeURIComponent).join('=')
      })
      .join('&')
  }

  const token = JSON.parse(localStorage.getItem('accessToken'))

  const queryParamData = {
    game_id: game?.data?.uuid,
    auth_type: 'standard',
    score_sync: !!token ? game?.data?.score_sync : false,
    state_sync: !!token ? game?.data?.state_sync : false,
    user_id: localStorage.getItem('tp-id') ?? '',
    client_id: TP_CLIENT_ID,
    tournament_mode: false,
    auth_token: token ?? '',
  }

  const {
    counter,
    handleGetOtp,
    handleMobileNumberChange,
    isCorrectMobileNumber,
    otp,
    handleSubmitOTP,
    setOtp,
    handleResendOTP,
    mobileNumber,
    otpSent,
    isVerifyOtpLoading,
    setCounter,
    isNormalLogin,
    setNormalLogin,
    countryCode,
  } = useLoginInfo()

  const {
    homePageModals,
    setHomePageModals,
    handleNavigationHistoryAndNotif,
    premiumGameConfig,
    recordHistoryAndSendNotif,
    togglePremiumGameModal,
    handleCancelBuy,
    getUserSavedGames,
    fetchCategories,
    getAllCategoryId,
    getUserStreakData,
  } = useProfileUpdate()

  const {
    handleLeaveAgoraRoom,
    initRTC,
    handleAgoraMicToggle,
    initRTM,
    newUserInfo,
    audioTracks,
    handleAgoraMessage,
    isLocalMicEnabled,
    setIsLocalMicEnabled,
  } = useAgoraUtils(
    allMultiPlayerUsers,
    setAllMultiPlayerUsers,
    agoraUid,
    setAgoraUid,
    micMuted,
    setMicMuted,
    setChannelMessage
  )

  const {
    init100ms,
    renderPeers,
    leaveRoomFrom100ms,
    toggleHmsMicToggle,
    handle100msMessage,
    handleLeaveHms,
  } = use100ms(
    setAllMultiPlayerUsers,
    selectedAvatar,
    agoraUid,
    allMultiPlayerUsers,
    micMuted,
    setMicMuted,
    setChannelMessage
  )

  const isLarge = useMediaQuery('(min-width:579px)')

  const handlePressHome = (game_name, uuid) => {
    const properties = {
      GAME_NAME: game_name,
      GAME_UUID: uuid,
    }
    sendAnalytics('track', properties, 'Play Game Ended', ['mixpanel'])
    sendAnalytics('track', properties, 'Home Button Pressed', [
      'moengage',
      'mixpanel',
    ])
    if (!!loginState.accessToken) {
      getUserStreakData()
      getUserSavedGames()
    }
    if (game?.data?.multiplayer) {
      handleClose()
    } else {
      navigate('/')
    }
  }

  const getGamee = async (slug) => {
    try {
      const res = await getGame(slug).unwrap()
      return res.data
    } catch (error) {
      console.error(error)
    }
  }

  const getGameLeaderBoard = async (pg) => {
    try {
      const res = await getLeaderBoard({ uuid: game.data.uuid, pg }).unwrap()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const getGameUserRanking = async () => {
    try {
      const res = await getUsersRank({ uuid: game?.data?.uuid }).unwrap()
      return res
    } catch (error) {
      console.log(error)
    }
  }
  const getUserName = async () => {
    try {
      const nameRes = await userName().unwrap()
      return nameRes
    } catch (error) {
      console.log(error)
    }
  }

  const setUserDetails = () => {
    getUserName().then((userResp) => {
      dispatch(
        setUserInfo({
          name: userResp?.data?.name,
          selectedAvatar: userResp?.data?.avatar,
          yearOfBirth: userResp?.data?.year_of_birth,
          gender: userResp?.data?.gender,
          mobile: userResp?.data?.mobile,
          code: userResp?.data?.country_code,
          social_id: userResp?.data?.social_id,
        })
      )
      setSelectedAvatar(userResp?.data?.avatar)
      setAgoraUserName(userResp?.data?.name)
    })
  }

  useEffect(() => {
    if (!!loginState?.accessToken) {
      setUserDetails()
    }
  }, [])

  const recordHistory = async (data) => {
    try {
      await userRecordHistory(data?.uuid, {
        game: data?.name,
        updated_at: data?.updated_at,
      }).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const getHunderedmsToken = async (id, roomId) => {
    try {
      const res = await getHmsToken({
        body: {
          room: roomId,
          game_uuid: game.data.uuid,
          role: 'speaker',
        },
        id,
      }).unwrap()
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const getAgoraRTCToken = async (id, roomId) => {
    try {
      const res = await getAgoraToken({
        body: {
          room: roomId,
          game_uuid: game.data.uuid,
          type: 'RTC',
        },
        id,
      }).unwrap()

      const data = {
        channelNameFromRTC: res.channel,
        rtcId: id,
        rtcToken: res.token,
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const getAvatars = async () => {
    try {
      const res = await getAgoraUsers().unwrap()
      // return res.token
      const randomNumber = Math.floor(Math.random() * res?.data?.length - 1)

      const ava = res?.data?.reduce(
        (acc, val, idx) =>
          idx === randomNumber
            ? [...acc, { src: val, selected: true }]
            : [...acc, { src: val, selected: false }],
        []
      )
      dispatch(setAvatars(ava))

      if (loginState?.selectedAvatar?.length === 0) {
        const randomAvatar = ava[randomNumber]?.src
        dispatch(setUserInfo({ ...loginState, selectedAvatar: randomAvatar }))
        setSelectedAvatar(randomAvatar)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAgoraRTMToken = async (id, roomId) => {
    try {
      const res = await getAgoraToken({
        body: {
          room: roomId,
          game_uuid: game.data.uuid,
          type: 'RTM',
        },
        id,
      }).unwrap()

      const data = {
        channelNameFromRTM: res.channel,
        rtmId: id,
        rtmToken: res.token,
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const handleAvatarClick = (e) => {
    setAvatars((prev) =>
      prev.map((ava) =>
        ava.src === e.target.alt
          ? { src: ava.src, selected: !ava.selected }
          : { src: ava.src, selected: false }
      )
    )
  }

  const getParticipantsFromRoom = async (id) => {
    try {
      const res = await getParticipantsInfo({
        body: {
          game_id: game?.data?.uuid,
        },
        room_id,
        id,
      }).unwrap()
      setGameParticipants(res?.data?.participants)
      const isHost = res?.data?.participants?.find(
        (participant) => participant.is_host
      )
      const is_rejoining = res?.data?.participants?.find(
        (participant) => participant.is_rejoining
      )
      setIsRejoining(is_rejoining)
      setHost(isHost)
      return res?.data
    } catch (error) {
      toast.dismiss()
      toast.error(error?.data?.message, {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
      navigate(location.pathname)
      setPreJoinRoomModalOpen(false)
      setGameModeModalOpen(true)
      setSelectedGameScreenPopup('gameModeModal')
      const properties = {
        GAME_NAME: game.data?.name,
        GAME_UUID: game.data?.uuid,
      }
      sendAnalytics('track', properties, 'Room Code expired', [
        'moengage',
        'mixpanel',
      ])
    }
  }

  const joinRoom = async (id) => {
    try {
      const res = await getJoinRoomInfo({
        body: {
          game_id: game.data.uuid,
          name: loginState?.name,
          avatar: loginState?.selectedAvatar,
        },
        roomId: room_id,
        id,
      }).unwrap()
      setJoinRoomDetails(res.data)
      setInviteLink(res.data?.invite_link)
      setMaxCapacity(res.data?.max_capacity)
      return res.data
    } catch (error) {
      toast.dismiss()
      toast.error(error?.data?.message, {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
      navigate(location.pathname)
      setEnterNameModalOpen(false)
      setGameModeModalOpen(true)
      setSelectedGameScreenPopup('gameModeModal')
    }
  }

  const createRoom = async (id) => {
    try {
      const res = await getRoomInfo({
        body: {
          game_id: game.data.uuid,
          mode: 'play-with-friends',
          name: loginState?.name,
          avatar: loginState?.selectedAvatar,
          duration: selectedGameMode,
        },
        id,
      }).unwrap()
      setCreateRoomDetails(res.data)
      setInviteLink(res.data?.invite_link)
      setMaxCapacity(res.data?.max_capacity)
      return res.data
    } catch (error) {
      console.error(error)
    }
  }

  const handleRoomJoinOnRouteChange = (id) => {
    getParticipantsFromRoom(id)
    setSelectedGameScreenPopup('preJoinRoom')
    setGameModeModalOpen(false)
    setPreJoinRoomModalOpen(true)
    setJoinWithCodeModalOpen(false)
  }

  const closeLobbyRoomAndStartGame = () => {
    setIsLobbyRoomModalOpen(false)
    setSelectedGameScreenPopup('')
  }

  const handleMpAgoraFns = (type, data) => {
    switch (type) {
      case 'initEngagment':
        const { id, roomId } = data
        initAgora(id, roomId)
        break
      case 'send_message':
        handleAgoraMessage(data)
        break
      case 'leave_room':
        handleLeaveAgoraRoom()
        break
      case 'mic_toggle':
        handleAgoraMicToggle()
        break
      default:
        return
    }
  }

  const handleMpHmsFns = (type, data) => {
    switch (type) {
      case 'initEngagment':
        const { id, roomId } = data
        initHms(id, roomId)
        break
      case 'send_message':
        handle100msMessage(data)
        break
      case 'leave_room':
        handleLeaveHms()
        break
      case 'mic_toggle':
        toggleHmsMicToggle()
        break
      default:
        return
    }
  }

  const mpFns = (type, data) => {
    if (isSelectedAudioAgora) {
      handleMpAgoraFns(type, data)
    } else {
      handleMpHmsFns(type, data)
    }
  }

  const initHms = (id, roomId) => {
    getHunderedmsToken(id, roomId).then((hmsResponse) => {
      setAgoraUid(id)
      const { token } = hmsResponse
      init100ms(
        token,
        loginState?.name ?? userNameFromAgora,
        loginState?.selectedAvatar ?? selectedAvatar
      )
    })
  }

  const initAgora = (id, roomId) => {
    getAgoraRTMToken(id, roomId).then((rtmResponse) => {
      const { channelNameFromRTM, rtmId, rtmToken } = rtmResponse
      initRTM(
        channelNameFromRTM,
        rtmId,
        rtmToken,
        loginState?.name ?? userNameFromAgora,
        loginState?.selectedAvatar ?? selectedAvatar,
        selectedAvatar
      )
      if (isAudioChatEnabled) {
        getAgoraRTCToken(id, roomId).then((rtcResponse) => {
          const { channelNameFromRTC, rtcId, rtcToken } = rtcResponse
          initRTC(channelNameFromRTC, rtcId, rtcToken)
        })
      }
    })
  }

  const leaveChannel = async () => {
    const id = !!multiplayerId
      ? multiplayerId
      : localStorage.getItem(`${room_id}`)
    try {
      await leaveRoom({
        body: {
          game_id: game?.data?.uuid,
        },
        id,
        roomId: room_id,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const toggleBottomSheet = () => {
    setSheetOpen(!isSheetOpen)
  }

  useEffect(() => {
    if (fetchLeaderboard) {
      handleOpenLeaderBoard()
      setFetchLeaderboard(false)
    }
  }, [fetchLeaderboard])

  useEffect(() => {
    if (gameProductId) {
      setSheetOpen(true)
    }
  }, [gameProductId])

  const startGame = async () => {
    try {
      const res = await startRoomInfo({
        body: {
          game_id: game?.data?.uuid,
        },
        id: multiplayerId,
        roomId: room_id,
      }).unwrap()
      if (res.message === 'Game started successfully') {
        const jsonMsg = {
          type: 'STATECHANGE',
          message: GAME_STARTED,
        }
        mpFns('send_message', {
          text: JSON.stringify(jsonMsg),
        })
        setChannelMessage(GAME_STARTED)
        const gameUrl = `${game?.data?.url}?roomId=${room_id}&playerId=${multiplayerId}`
        setGameIframe(gameUrl)
        setGameStarted(true)
      }
    } catch (error) {
      toast.dismiss()
      toast.error(error?.data?.message, {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
    }
  }

  useEffect(() => {
    // Show the loader for 6 seconds
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false)
    }, 2000) // 6 seconds delay in milliseconds

    return () => clearTimeout(loaderTimeout)
  }, [])

  useEffect(() => {
    if (
      game?.data?.url &&
      room_id &&
      roomKey === '?roomId' &&
      !joinedOrCreatedRoomFromStart
    ) {
      if (multiplayerId) {
        handleRoomJoinOnRouteChange(multiplayerId)
      } else {
        const id = uuidv4()
        const tid = localStorage.getItem('tp-id')
        if (!!tid) {
          localStorage.setItem(`${room_id}`, tid)
          handleRoomJoinOnRouteChange(tid)
        } else {
          localStorage.setItem(`${room_id}`, id)
          handleRoomJoinOnRouteChange(id)
          localStorage.setItem('tp-id', id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, game, multiplayerId])

  const getUserBuyGame = (
    slug,
    is_premium,
    multiplayer,
    uuid,
    name,
    updated_at,
    expires_at = null
  ) => {
    handleNavigationHistoryAndNotif(
      slug,
      is_premium,
      multiplayer,
      uuid,
      name,
      updated_at,
      expires_at,
      'game-page'
    )
  }

  function showAds() {
    console.log('Showing Interstitial Ad!')
    window.adBreak({
      type: 'next',
    })
  }

  function showRewardedAds() {
    console.log('Showing Rewarded Ad!')
    window.adBreak({
      type: 'reward',
    })
  }

  var postFunctions = {
    gameStarted: function () {
      console.log('[Timepass Web] Game Started Called!')
    },
    gameOver: function () {
      console.log('[Timepass Web] Game Over Called!')
    },
    showAds: function () {
      console.log('[Timepass Web] Show Ads Called!')
      showAds()
    },
    showRewardedAds: function () {
      console.log('[Timepass Web] Show Rewarded Ads Called!')
      showRewardedAds()
    },
    exitGame: function (game_name, game_uuid) {
      console.log('[Timepass Web] Exit Game Called!')
      handlePressHome(game_name, game_uuid)
    },
    showLeaderboard: function () {
      console.log('[Timepass Web] Show Leaderboard Called!')
      setFetchLeaderboard(true)
    },
    logGameEvents: function (message) {
      console.log('[Timepass Web] Log Game Events Called! Message: ', message)
    },
    showPurchaseOptions: function (productId, growthFactor) {
      setGameProductId(productId)
      setGrowthFactor(growthFactor)
    },
    sendDesignEvent: function (
      game_name,
      game_uuid,
      design01,
      design02,
      design03,
      design04,
      design05,
      value
    ) {
      console.log(
        '[Timepass Web] Send Design Event Called! Message: ',
        design01,
        design02,
        design03,
        design04,
        design05,
        value
      )

      console.log(game)

      const properties = {
        GAME_NAME: game_name,
        GAME_UUID: game_uuid,
        DESIGN01: design01,
        DESIGN02: design02,
        DESIGN03: design03,
        DESIGN04: design04,
        DESIGN05: design05,
        VALUE: value,
      }
      sendAnalytics('track', properties, 'Design Record', ['mixpanel'])
    },
    sendProgressionEvent: function (
      game_name,
      game_uuid,
      status,
      progression01,
      progression02,
      progression03,
      value
    ) {
      console.log(
        '[Timepass Web] Send Progression Event Called! Message: ',
        status,
        progression01,
        progression02,
        progression03,
        value
      )
      const properties = {
        GAME_NAME: game_name,
        GAME_UUID: game_uuid,
        STATUS: status,
        PROGRESSION01: progression01,
        PROGRESSION02: progression02,
        PROGRESSION03: progression03,
        VALUE: value,
      }
      sendAnalytics('track', properties, 'Progression Record', ['mixpanel'])
    },
  }

  useEffect(() => {
    try {
      getGamee(slug).then((data) => {
        if (data) {
          window.addEventListener('message', function (event) {
            console.log(
              '[Timepass Web] Game -> Platform Call Received: ',
              event.data.func
            )
            if (
              postFunctions[event.data.func] &&
              event.data.func === 'gameStarted'
            ) {
              postFunctions[event.data.func]()
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'gameOver'
            ) {
              postFunctions[event.data.func]()
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'showAds'
            ) {
              postFunctions[event.data.func]()
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'showRewardedAds'
            ) {
              postFunctions[event.data.func]()
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'exitGame'
            ) {
              postFunctions[event.data.func](data?.name, data?.uuid)
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'showLeaderboard'
            ) {
              postFunctions[event.data.func]()
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'logGameEvents'
            ) {
              postFunctions[event.data.func](event.data.message)
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'sendDesignEvent'
            ) {
              postFunctions[event.data.func](
                data?.name,
                data?.uuid,
                event.data.design01,
                event.data.design02,
                event.data.design03,
                event.data.design04,
                event.data.design05,
                event.data.value
              )
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'sendProgressionEvent'
            ) {
              postFunctions[event.data.func](
                data?.name,
                data?.uuid,
                event.data.status,
                event.data.progression01,
                event.data.progression02,
                event.data.progression03,
                event.data.value
              )
            } else if (
              postFunctions[event.data.func] &&
              event.data.func === 'showPurchaseOptions'
            ) {
              postFunctions[event.data.func](
                event.data.productId,
                event.data.growthFactor
              )
            }
          })

          const queryParamData = {
            game_id: data?.uuid,
            auth_type: 'standard',
            score_sync: !!token ? data?.score_sync : false,
            state_sync: !!token ? data?.state_sync : false,
            user_id: localStorage.getItem('tp-id') ?? '',
            client_id: TP_CLIENT_ID,
            tournament_mode: false,
            auth_token: token ?? '',
          }
          const queryParam = encodeData(queryParamData)
          setGameIframe(`${data?.url}?api_url=${api_url}&${queryParam}`)
          setIsAudioChatEnabled(data.multiplayer_config.audio_chat_enabled)
          setIsSelectedAudioAgora(
            data.multiplayer_config.supported_service === 'agora'
          )

          dispatch(setGame(data))

          if (data?.multiplayer) {
            setGameModeModalOpen(true)
            setSelectedGameScreenPopup('gameModeModal')
          }

          if (data?.is_premium && !data?.expires_at) {
            getUserBuyGame(
              data?.slug,
              data?.is_premium,
              data?.multiplayer,
              data?.uuid,
              data?.name,
              data?.updated_at,
              data?.expires_at
            )
          } else {
            if (!location.state) {
              const properties = {
                GAME_NAME: data?.name,
                GAME_FROM: 'Game',
                GAME_UUID: data?.uuid,
                SEGMENT_NAME: 'Direct Landing',
              }
              sendAnalytics('track', properties, 'Play Game', [
                'moengage',
                'mixpanel',
              ])
            }
            sendAnalytics('time_event', false, 'Play Game Ended', ['mixpanel'])

            if (data?.is_premium) {
              const properties = {
                GAME_NAME: data?.name,
                PURCHASE_STATUS: 'PAYMENT_SUCCESS',
                GAME_UUID: data?.uuid,
                GAME_FROM: 'Game',
              }

              sendAnalytics('track', properties, 'Premium Gameplay', [
                'moengage',
                'mixpanel',
              ])
            }

            if (!!loginState.accessToken) {
              recordHistory(data)
            }
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserRankAndLeaderBoard = () => {
    getGameLeaderBoard(1).then((res) => {
      setLeaderBoardDetails(res)
    })
    if (loginState?.accessToken) {
      getGameUserRanking().then((res) => {
        setUserRank(res)
      })
    }
  }

  useEffect(() => {
    if (modalOpen) {
      inputRef?.current?.focus()
    }
  }, [modalOpen])

  useEffect(() => {
    if (maxCapacity === allMultiPlayerUsers?.length) {
      setRoomIsFull(true)
    }
    if (
      maxCapacity === allMultiPlayerUsers?.length &&
      !gameGameSuccessfullyStarted &&
      isHost
    ) {
      startGame()
      const properties = {
        GAME_NAME: game?.data.name,
        GAME_UUID: game.data.uuid,
        AUTO_START: true,
      }
      sendAnalytics('track', properties, 'Start Game Room Pressed', [
        'moengage',
        'mixpanel',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxCapacity, allMultiPlayerUsers])

  useEffect(() => {
    if (loginState?.avatars?.length === 0) {
      getAvatars()
    }
  }, [])

  useEffect(() => {
    if (
      loginState?.accessToken &&
      game?.data?.leaderboard_enabled &&
      !game?.data?.leaderboard_enabled?.is_premium &&
      isNormalLogin
    ) {
      const queryParam = encodeData(queryParamData)
      setGameIframe(`${game?.data?.url}?api_url=${api_url}&${queryParam}`)
      getUserRankAndLeaderBoard()
      setUserDetails()
      setLeaderBoardOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game])

  useEffect(() => {
    const id = localStorage.getItem(`${room_id}`)
    let messageTimerId
    if ((channelMessage === GAME_STARTED || gameStarted) && id) {
      if (roomIsFull) {
        if (gameStartCounter > 0) {
          messageTimerId = setTimeout(
            () => setGameStartCounter(gameStartCounter - 1),
            1000
          )
        }
        if (gameStartCounter === 0) {
          const gameUrl = `${game?.data?.url}?roomId=${room_id}&playerId=${id}`
          setGameIframe(gameUrl)
          closeLobbyRoomAndStartGame()
          setGameSuccessfullyStarted(true)
        }
      } else {
        const gameUrl = `${game?.data?.url}?roomId=${room_id}&playerId=${id}`
        setGameIframe(gameUrl)
        closeLobbyRoomAndStartGame()
        setGameSuccessfullyStarted(true)
      }
    }
    return () => clearTimeout(messageTimerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelMessage, gameStarted, multiplayerId, gameStartCounter, roomIsFull])

  useEffect(() => {
    if (channelMessage === ROOM_DEACTIVATED && room_id) {
      toast.success('Host ended the game')
      handleLeaveChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelMessage, multiplayerId])

  const handleCreateRoom = () => {
    setCreateRoomJoinRoomPopUpOpen(false)
    setCreateRoomModalOpen(true)
    setSelectedGameScreenPopup('createRoom')
  }

  const handleJoinRoom = () => {
    setCreateRoomJoinRoomPopUpOpen(false)
    setJoinRoomModalOpen(true)
    setSelectedGameScreenPopup('joinRoom')
  }

  const onCreateRoomClick = () => {
    setGameModeModalOpen(false)
    setEnterNameModalOpen(true)
    setSelectedGameScreenPopup('enterNameModal')
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
      GAME_DURATION_MODE: selectedGameMode,
    }
    sendAnalytics('track', properties, 'Create Room Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const onJoinRoomClick = async () => {
    setEnterNameModalOpen(true)
    setJoinRoomModalOpen(false)
    setSelectedGameScreenPopup('enterNameModal')
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
      IS_REJOINING: !!isRejoining,
    }
    sendAnalytics('track', properties, 'Participant Join Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleMpNameChange = (e) => {
    dispatch(
      setUserInfo({
        selectedAvatar: loginState?.selectedAvatar,
        name: e.target.value,
      })
    )
    setAgoraUserName(e.target.value)
    setSelectedAvatar(loginState?.selectedAvatar)
  }

  const handleAvatarChange = (src) => {
    dispatch(
      setUserInfo({
        selectedAvatar: src,
        name: loginState?.name,
      })
    )
    setAgoraUserName(loginState?.name)
    setSelectedAvatar(src)
  }

  const onSelectedAvatar = () => {
    setEnterNameModalOpen(true)
    setChangeAvatarModalOpen(false)
    setSelectedGameScreenPopup('enterNameModal')
  }

  const handleChangeAvatar = () => {
    setEnterNameModalOpen(false)
    setChangeAvatarModalOpen(true)
    setSelectedGameScreenPopup('changeAvatarModal')
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareableLink)
    toast.success('Game link is copied to clipboard')
  }

  const handleClose = () => {
    setIsLobbyRoomModalOpen(false)
    setOpenAreYouSureModal(true)
    setSelectedGameScreenPopup('areYouSureRoom')
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
      IS_HOST: isHost,
      GAME_STARTED: gameGameSuccessfullyStarted,
    }
    sendAnalytics('track', properties, 'Leave Room Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleModalAfterJoinOrCreate = (isStarted, id) => {
    if (isStarted) {
      setSelectedGameScreenPopup('')
      const gameUrl = `${game?.data?.url}?roomId=${room_id}&playerId=${id}`
      setGameIframe(gameUrl)
    } else {
      setIsLobbyRoomModalOpen(true)
      setSelectedGameScreenPopup('lobbyRoom')
    }
    setEnterNameModalOpen(false)
    setJoinedOrCreatedRoomFromStart(true)
  }

  const handleJoinCreatedRoom = async () => {
    const uName = loginState?.name?.trim()?.length !== 0
    if (!uName || !loginState?.selectedAvatar) {
      toast.dismiss()
      const wrongDetails =
        loginState?.name?.length === 0 &&
        loginState?.selectedAvatar?.length === 0
      if (wrongDetails) {
        toast.error('Please select an avatar and fill your name', {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                color: 'black',
              }}
            />
          ),
        })
        return
      }
      if (!uName) {
        toast.error('Please fill your name', {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                color: 'black',
              }}
            />
          ),
        })
        return
      }
      if (!loginState.selectedAvatar) {
        toast.error('Please select an avatar', {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                color: 'black',
              }}
            />
          ),
        })
        return
      }
    }

    const id = uuidv4()
    const isItCreateRoom = !(roomKey === '?roomId' && !!room_id)

    if (isItCreateRoom) {
      const properties = {
        GAME_NAME: game.data.name,
        GAME_UUID: game.data.uuid,
        GAME_DURATION_MODE: selectedGameMode,
      }
      sendAnalytics('track', properties, 'Save And Create Room Pressed', [
        'moengage',
        'mixpanel',
      ])

      let tid = localStorage.getItem('tp-id')
      if (!tid) {
        localStorage.setItem('tp-id', id)
        tid = id
      }

      // Save And Create Room Pressed
      createRoom(tid).then((res) => {
        mpFns('initEngagment', { id: tid, roomId: res.room_id })
        setSharableLink(res.shareable_text)
        navigate(`?roomId=${res.room_id}`)
        localStorage.setItem(`${res.room_id}`, tid)
        handleModalAfterJoinOrCreate(false)
        setIsHost(true)
      })
    } else {
      const properties = {
        GAME_NAME: game.data.name,
        GAME_UUID: game.data.uuid,
      }
      const multiplayerId = localStorage.getItem(`${room_id}`)
      sendAnalytics('track', properties, 'Save And Join Room Pressed', [
        'moengage',
        'mixpanel',
      ])
      if (multiplayerId) {
        const res = await joinRoom(multiplayerId)
        if (res) {
          setIsHost(res.is_host)
          handleModalAfterJoinOrCreate(res.is_started, multiplayerId)
          setGameStarted(res.is_started)
          mpFns('initEngagment', { id: multiplayerId, roomId: room_id })
          setSharableLink(res.shareable_text)
        }
      }
    }
  }

  const handleDontLeave = () => {
    setOpenAreYouSureModal(false)
    if (gameGameSuccessfullyStarted) {
      closeLobbyRoomAndStartGame()
    } else {
      setSelectedGameScreenPopup('lobbyRoom')
      setIsLobbyRoomModalOpen(true)
    }
  }

  const handleStartRoom = () => {
    startGame()
    const properties = {
      GAME_NAME: game?.data.name,
      GAME_UUID: game.data.uuid,
      AUTO_START: false,
    }
    sendAnalytics('track', properties, 'Start Game Room Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleLoginFromLeaderBoard = () => {
    setLeaderBoardOpen(false)
    setNormalLogin(true)
    setHomePageModals((prev) => ({
      ...prev,
      isLoginModalOpen: true,
    }))

    const properties = {
      SHOWN_FROM: 'Game',
    }
    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleLoginFromBottomSheet = (selectedGameProduct) => {
    toggleBottomSheet()
    setGameProductId(null)
    setGrowthFactor(null)
    setNormalLogin(true)
    setHomePageModals((prev) => ({
      ...prev,
      isLoginModalOpen: true,
    }))
    if (selectedGameProduct) {
      setSelectedGameProduct(selectedGameProduct)
    }
  }

  const onSelectMode = (mode) => {
    setSelectedGameMode(mode)
  }

  const handleJoinWithCode = () => {
    setGameModeModalOpen(false)
    setJoinWithCodeModalOpen(true)
    setSelectedGameScreenPopup('joinRoomWithCode')
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
    }
    sendAnalytics('track', properties, 'Join With Code Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleLeaveChannel = () => {
    if (isHost && !isGameStarted) {
      //Dual dependency
      const jsonMsg = {
        type: 'STATECHANGE',
        message: ROOM_DEACTIVATED,
      }
      mpFns('send_message', {
        text: JSON.stringify(jsonMsg),
      })
    }
    mpFns('leave_room')
    setJoinedOrCreatedRoomFromStart(false)
    setChannelMessage('')
    setIsLobbyRoomModalOpen(false)
    leaveChannel()
    setOpenAreYouSureModal(false)
    setGameModeModalOpen(true)
    setSelectedGameScreenPopup('gameModeModal')
    localStorage.removeItem(`${room_id}`)
    navigate(location.pathname, { replace: true })
    const queryParam = encodeData(queryParamData)
    setGameIframe(`${game?.data?.url}?api_url=${api_url}&${queryParam}`)
    setGameSuccessfullyStarted(false)
    setGameStartCounter(6)
    setRoomIsFull(false)
    setGameStarted(false)
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
      IS_HOST: isHost,
      GAME_STARTED: gameGameSuccessfullyStarted,
    }
    sendAnalytics('track', properties, 'Room Left', ['moengage', 'mixpanel'])
  }

  const handleRoomCodeChange = (e) => {
    setRoomCode(e.target.value)
  }

  const handleShareableLink = () => {
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
    }
    sendAnalytics('track', properties, 'Share Room Link Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleJoinRoomFromCode = () => {
    const isValidRoomCode = roomCode?.length === 6
    if (!isValidRoomCode) {
      toast.dismiss()
      toast.error('Please enter valid room code', {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
      return
    }
    navigate(`?roomId=${roomCode}`)
    setJoinWithCodeModalOpen(false)
    setRoomCode('')
  }

  const goBackFromJoinWithCode = () => {
    setJoinRoomModalOpen(false)
    setJoinWithCodeModalOpen(false)
    setSelectedGameScreenPopup('gameModeModal')
    setGameModeModalOpen(true)
  }

  const goBackFromEnterModal = () => {
    if (room_id) {
      setSelectedGameScreenPopup('preJoinRoom')
      setGameModeModalOpen(false)
      setPreJoinRoomModalOpen(true)
      setJoinWithCodeModalOpen(false)
    } else {
      setJoinRoomModalOpen(false)
      setJoinWithCodeModalOpen(false)
      setSelectedGameScreenPopup('gameModeModal')
      setGameModeModalOpen(true)
    }
  }

  const getMicAccessedDetails = async (id) => {
    try {
      const res = await getMpVcConfig(id).unwrap()
      return res
    } catch (error) {
      console.error(error)
      toast.dismiss()
      toast.error(error.message)
    }
  }

  const postMicBuyAceess = async (id) => {
    try {
      const res = await postMpVcConfig(id).unwrap()
      return res
    } catch (error) {
      console.error(error)
      toast.dismiss()
      toast.error(error.message)
    }
  }

  const closeActivateModal = () => {
    if (!gameGameSuccessfullyStarted) {
      setIsLobbyRoomModalOpen(true)
      setSelectedGameScreenPopup('lobbyRoom')
    } else {
      setIsLobbyRoomModalOpen(false)
      setSelectedGameScreenPopup('')
    }
    setActivateVoiceChatModalOpen(false)
  }

  const handleMicIfBought = () => {
    if (!isLocalMicEnabled) {
      toast.dismiss()
      toast.error('Multiplayer games requires access to your microphone', {
        icon: <MicBlocked />,
      })
      return
    }
    mpFns('mic_toggle')
    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
      ENABLED: micMuted,
      IS_HOST: isHost,
      GAME_STARTED: gameGameSuccessfullyStarted,
    }
    sendAnalytics('track', properties, 'Voice Chat Button Toggled', [
      'moengage',
      'mixpanel',
    ])

    closeActivateModal()
  }

  const handleMic = async () => {
    if (!loginState?.accessToken) {
      setHomePageModals((prev) => ({
        ...prev,
        isLoginModalOpen: true,
      }))

      const properties = {
        SHOWN_FROM: 'Game',
      }

      sendAnalytics('track', properties, 'Login Sheet Shown', [
        'moengage',
        'mixpanel',
      ])
      setSelectedGameScreenPopup('loginModal')
      return
    }
    if (!coinsData?.is_bought) {
      const micDetails = await getMicAccessedDetails(game?.data?.uuid)
      setCoinData(micDetails)
      if (!micDetails?.is_bought) {
        setIsLobbyRoomModalOpen(false)
        setActivateVoiceChatModalOpen(true)
        setSelectedGameScreenPopup('voiceChatModal')
        return
      }
    }
    handleMicIfBought()
  }

  const handleCircleClose = () => {
    setAllAvatarsVisible((prev) => !prev)
  }

  const handleActivate = async () => {
    const res = await postMicBuyAceess(game?.data?.uuid)
    if (res?.is_bought) {
      handleMicIfBought()
      const properties = {
        GAME_NAME: game?.data.name,
        GAME_UUID: game?.data?.uuid,
      }
      sendAnalytics('track', properties, 'Buy Voice Chat', [
        'mixpanel',
        'moengage',
      ])
    }
  }

  const isGameStarted = channelMessage === GAME_STARTED

  navigator.permissions.query({ name: 'microphone' }).then((permissionObj) => {
    if (permissionObj.state === 'granted') {
      setIsLocalMicEnabled(true)
    }
  })

  const handleShareOnWhatsApp = (shareableText) => {
    const encodedText = encodeURIComponent(shareableText)
    const url = `https://api.whatsapp.com/send?text=${encodedText}`
    window.open(url, '_blank')
  }

  useUnload(() => {
    mpFns('leave_room')
  })

  const toggleLoginModal = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: false,
      }
    })
    const queryParam = encodeData(queryParamData)
    setGameIframe(`${game?.data?.url}?api_url=${api_url}&${queryParam}`)
  }

  const handleEnterPhoneNumber = async () => {
    const res = await handleGetOtp()
    if (res?.message === 'OTP Sent Successfully') {
      setCounter(0)
      setSelectedGameScreenPopup('otpModal')
      setHomePageModals((prev) => {
        return {
          ...prev,
          isLoginModalOpen: false,
          isOtpModalOpen: true,
        }
      })
    }
  }

  const handleChangeNumber = () => {
    dispatch(setOtpToStore(false))
    setOtp('')
    setHomePageModals((prev) => {
      return {
        ...prev,
        isLoginModalOpen: true,
        isOtpModalOpen: false,
      }
    })
    const properties = {
      SHOWN_FROM: 'Game',
    }

    sendAnalytics('track', properties, 'Login Sheet Shown', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleSubmitOTPAndCloseModals = async () => {
    const res = await handleSubmitOTP()
    if (res?.message === 'Logged In') {
      setHomePageModals((prev) => ({
        ...prev,
        isOtpModalOpen: false,
        isLoginModalOpen: false,
      }))
      if (selectedGameProduct) {
        setGameProductId(selectedGameProduct.productId)
        setGrowthFactor(selectedGameProduct.growthFactor)
        setSelectedGameProduct(null)
      }
      if (game.data.multiplayer) {
        const micDetails = await getMicAccessedDetails(game?.data?.uuid)
        setCoinData(micDetails)
        if (!micDetails?.is_bought) {
          setIsLobbyRoomModalOpen(false)
          setActivateVoiceChatModalOpen(true)
          setSelectedGameScreenPopup('voiceChatModal')
        } else {
          handleMicIfBought()
        }
      }
    }
  }

  const handleConsumePlay = async () => {
    try {
      const res = await postPremiumConfig(game?.data?.uuid).unwrap()
      if (res?.is_bought) {
        const recordHistoryRes = await recordHistoryAndSendNotif(
          game?.data?.uuid,
          game?.data?.name,
          game?.data?.updated_at
        )

        const properties = {
          GAME_NAME: premiumGameConfig.game_name,
          GAME_UUID: premiumGameConfig.uid,
        }

        sendAnalytics('track', properties, 'Buy Premium Game', [
          'moengage',
          'mixpanel',
        ])

        if (recordHistoryRes.message === 'Game history saved.') {
          getGamee(slug).then((data) => {
            dispatch(setGame(data))
          })

          dispatch(removeAllCategory())
          if (allCategoryId?.data?.length === 0) {
            getAllCategoryId().then((res) => {
              if (res) {
                fetchCategories(res)
              }
            })
          } else {
            fetchCategories(allCategoryId?.data)
          }
          getUserSavedGames(1)
          setHomePageModals((prev) => {
            return {
              ...prev,
              isBuyPremiumGameModalOpen: false,
            }
          })
          const queryParam = encodeData(queryParamData)
          setGameIframe(`${game?.data?.url}?api_url=${api_url}&${queryParam}`)
        }
      }
    } catch (error) {
      console.error(error)
      toast.error(error?.data?.message)
    }
  }

  const handleClosePremiumModal = () => {
    navigate('/')
    handleCancelBuy()
  }

  const gameScreenPopUps = {
    preCreateRoom: (
      <PreCreateRoom
        gameLogo={game?.data?.property?.thumbnail}
        onCreateRoomClick={onCreateRoomClick}
        isPreCreateRoomModalOpen={isPreCreateRoomModalOpen}
      />
    ),
    preJoinRoom: (
      <PreJoinRoom
        isPreJoinRoomModalOpen={isPreJoinRoomModalOpen}
        gameLogo={game?.data?.property?.thumbnail}
        onJoinRoomClick={onJoinRoomClick}
        gameParticipants={gameParticipants}
        host={host}
        gameName={game?.data?.name}
      />
    ),
    enterNameModal: (
      <EnterNameModal
        isEnterNameModalOpen={isEnterNameModalOpen}
        handleNameChange={handleMpNameChange}
        userNameFromAgora={loginState?.name}
        handleChangeAvatar={handleChangeAvatar}
        selectedAvatar={loginState?.selectedAvatar}
        handleJoinCreatedRoom={handleJoinCreatedRoom}
        goBackFromEnterModal={goBackFromEnterModal}
        firstAvatar={loginState?.avatars?.[0]?.src}
      />
    ),
    changeAvatarModal: (
      <ChangeAvatarModal
        isChangeAvatarModalOpen={isChangeAvatarModalOpen}
        avatars={loginState?.avatars}
        handleAvatarChange={handleAvatarChange}
        onSelectedAvatar={onSelectedAvatar}
        selectedAvatar={selectedAvatar}
        firstAvatar={loginState?.avatars?.[0]?.src}
      />
    ),
    createOrJoinRoom: (
      <CreateOrJoinRoom
        handleCreateRoom={handleCreateRoom}
        handleJoinRoom={handleJoinRoom}
        isCreateRoomJoinRoomPopupOpen={isCreateRoomJoinRoomPopupOpen}
      />
    ),
    lobbyRoom: (
      <LobbyRoom
        isLobbyRoomModalOpen={isLobbyRoomModalOpen}
        gameName={game.data.name}
        allMultiPlayerUsers={allMultiPlayerUsers}
        handleCopyLink={handleCopyLink}
        isMobile={isMobile}
        code={room_id}
        handleMicToggle={handleMic}
        micMuted={micMuted}
        handleClose={handleClose}
        isOpenAreYouSureModal={isOpenAreYouSureModal}
        newUserInfo={newUserInfo}
        maxCapacity={maxCapacity}
        agoraUid={agoraUid}
        isHostInLobby={isHost}
        handleStartRoom={handleStartRoom}
        shareableLink={shareableLink}
        isAudioChatEnabled={isAudioChatEnabled}
        handleShareableLink={handleShareableLink}
        isLocalMicEnabled={isLocalMicEnabled}
        counter={gameStartCounter}
        renderPeers={renderPeers}
        leaveRoomFrom100ms={leaveRoomFrom100ms}
      />
    ),
    areYouSureRoom: (
      <AreYouSureModal
        isOpenAreYouSureModal={isOpenAreYouSureModal}
        handleDontLeave={handleDontLeave}
        handleLeaveRoom={handleLeaveChannel}
        isHost={isHost}
        gameGameSuccessfullyStarted={gameGameSuccessfullyStarted}
      />
    ),
    gameModeModal: (
      <GameModeModal
        isGameModeModalOpen={isGameModeModalOpen}
        onSelectMode={onSelectMode}
        handleJoinWithCode={handleJoinWithCode}
        gameLogo={game?.data?.property?.thumbnail}
        setSelectedGameMode={setSelectedGameMode}
        selectedGameMode={selectedGameMode}
        onCreateRoomClick={onCreateRoomClick}
        multiplayerDurationData={game?.data?.multiplayer_config?.duration_data}
      />
    ),
    joinRoomWithCode: (
      <JoinRoomWithCode
        gameLogo={game?.data?.property?.thumbnail}
        gameName={game?.data?.name}
        gameRoomValue={roomCode}
        handleJoinRoomFromCode={handleJoinRoomFromCode}
        handleRoomCodeChange={handleRoomCodeChange}
        isJoinWithCodeModalOpen={isJoinWithCodeModalOpen}
        goBackFromJoinWithCode={goBackFromJoinWithCode}
      />
    ),

    voiceChatModal: (
      <ActivateVoiceModal
        cancelActivate={closeActivateModal}
        handleActivate={handleActivate}
        coinsData={coinsData}
        isActivateVoiceChatModalOpen={isActivateVoiceChatModalOpen}
      />
    ),
  }

  const handleNextLeaderBoardPage = () => {
    if (leaderBordDetails.current_page < leaderBordDetails.total_page) {
      getGameLeaderBoard(leaderBordDetails.current_page + 1).then((res) => {
        const result = {
          ...res,
          results: [...leaderBordDetails.results, ...res.results],
        }
        setLeaderBoardDetails(result)
      })
    }
  }

  const handleCloseLeaderBoard = () => {
    setLeaderBoardOpen(false)
  }

  const handleOpenLeaderBoard = () => {
    getUserRankAndLeaderBoard()

    const properties = {
      GAME_NAME: game.data.name,
      GAME_UUID: game.data.uuid,
    }
    sendAnalytics('track', properties, 'Open Leaderboard', [
      'mixpanel',
      'moengage',
    ])

    setLeaderBoardOpen(true)
  }

  const toggleOtpModal = () => {
    setHomePageModals((prev) => {
      return {
        ...prev,
        isOtpModalOpen: false,
      }
    })
    const queryParam = encodeData(queryParamData)
    setGameIframe(`${game?.data?.url}?api_url=${api_url}&${queryParam}`)
  }

  useEffect(() => {
    if (isError) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notifyPurchaseCompleted = (productId, purchaseType) => {
    const gameFrame = document.getElementById('timepass-gameframe')
    gameFrame.contentWindow.postMessage(
      {
        func: 'purchaseCompletedCallback',
        productId: productId,
        purchaseType: purchaseType,
      },
      '*'
    )
  }

  return (
    <div className="mx-auto">
      {game?.data?.deeplink && (
        <CheckAppInstalled
          isInstalled={false}
          deeplink={game?.data?.deeplink}
          gameName={game?.data?.name}
          uuid={game?.data?.uuid}
        />
      )}
      <LeaderBoardModal
        isLeaderBoardOpen={isLeaderBoardOpen}
        userRank={userRank}
        leaderBordDetails={leaderBordDetails}
        handleNextLeaderBoardPage={handleNextLeaderBoardPage}
        handleCloseLeaderBoard={handleCloseLeaderBoard}
        token={loginState?.accessToken}
        handleLoginFromLeaderBoard={handleLoginFromLeaderBoard}
      />
      {(homePageModals.isLoginModalOpen || homePageModals.isOtpModalOpen) && (
        <div className="w-screen bg-white relative overflow-y-auto h-[100vh]">
          <LoginModal
            isOpen={homePageModals.isLoginModalOpen}
            toggle={toggleLoginModal}
            mobileNumber={`${countryCode}${mobileNumber}`}
            handleMobileNumberChange={handleMobileNumberChange}
            handleGetOtp={handleEnterPhoneNumber}
            isCorrectMobileNumber={isCorrectMobileNumber} // Assuming this function is defined
            isNormalLogin={isNormalLogin}
          />
          <OtpModal
            isOpen={homePageModals.isOtpModalOpen}
            toggle={toggleOtpModal}
            mobileNumber={`${countryCode}${mobileNumber}`}
            otp={otp}
            setOtp={setOtp}
            handleChangeNumber={handleChangeNumber} //HANDLE HERE
            handleSubmitOTP={handleSubmitOTPAndCloseModals}
            handleResendOTP={handleResendOTP}
            otpSent={otpSent} // Assuming this variable is defined
            counter={counter} // Assuming this variable is defined
            isVerifyOtpLoading={isVerifyOtpLoading} // Assuming this variable is defined
          />
        </div>
      )}
      {showLoader && (
        <Player
          src="/timepass-games-loader.json"
          className="player fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          loop
          autoplay
          style={{ height: '300px', width: '300px' }}
        />
      )}
      {!showLoader && (
        <div className="p-0 max-w-full">
          <Helmet>
            <title>
              {`${game?.data?.property?.name} - Play Online for Free!`}
            </title>
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': [`${game?.data?.category}`],
                name: `${game?.data?.name}`,
                datePublished: `${convertDateFormat(game?.data?.created_at)}`,
                dateUpdated: `${convertDateFormat(game?.data?.updated_at)}`,
                description: `${game?.data?.property?.subtitle}`,
                rating: '5',
                image: [
                  `${game?.data?.property?.image}`,
                  `${game?.data?.property?.thumbnail}`,
                  `${game?.data?.property?.fav_thumbnail}`,
                ],
                video: [`${game?.data?.property?.video}`],
              })}
            </script>
            <meta
              name="description"
              content={`${game?.data?.property?.subtitle}`}
            />
          </Helmet>
          <BuyPremiumGameModal
            isBuyPremiumGameModalOpen={homePageModals.isBuyPremiumGameModalOpen}
            togglePremiumGameModal={togglePremiumGameModal}
            gameName={premiumGameConfig.game_name}
            handleCancelBuy={handleClosePremiumModal}
            handleConsumePlay={handleConsumePlay}
            gameCost={premiumGameConfig?.game_cost}
            gameDuration={premiumGameConfig?.validity}
            uid={premiumGameConfig.uid}
          />
          <GameProductPurchaseBottomSheet
            gameId={game?.data?.uuid}
            gameName={game?.data?.name}
            isOpen={isSheetOpen}
            onClose={() => {
              toggleBottomSheet()
              setGameProductId(null)
              setGrowthFactor(null)
            }}
            productId={gameProductId}
            growthFactor={growthFactor}
            handleLoginFromBottomSheet={handleLoginFromBottomSheet}
            notifyPurchaseCompleted={notifyPurchaseCompleted}
            isLoggedIn={!!loginState.accessToken}
          />
          <div className="fixed top-1/4 left-0 z-[9999]">
            <Link
              to="/"
              className=" bg-[#47891e] text-white flex w-fit items-center p-2 cursor-pointer"
            >
              <FaHome
                color="white"
                size={20}
                onClick={() =>
                  handlePressHome(game?.data?.name, game?.data?.uuid)
                }
              />
            </Link>
            <div
              id="details-tooltip"
              className="bg-[#de5d00] text-white hidden lg:flex w-fit items-center p-2 cursor-pointer "
            >
              <FaInfo color="white" size={20} />
            </div>
            <div className="bg-[#0072b1] text-white flex w-fit items-center justify-center p-1.5 cursor-pointer mr-4">
              {isLarge ? (
                <GameShare
                  color="white"
                  size={20}
                  onClick={() =>
                    handleCopyUrl(game?.data?.property?.shareable_text, toast)
                  }
                />
              ) : (
                <Whatsapp
                  onClick={() =>
                    handleShareOnWhatsApp(game?.data?.property?.shareable_text)
                  }
                />
              )}
            </div>
            {game?.data?.leaderboard_enabled && (
              <div
                id="leaderboard-tooltip"
                className="bg-[#2f3034] w-9 h-9 flex justify-center items-center cursor-pointer"
                onClick={handleOpenLeaderBoard}
              >
                <LeaderboardIcon />
              </div>
            )}
          </div>
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="details-tooltip"
            toggle={toggle}
            className="rounded-none"
          >
            <Card className="rounded-none mb-2">
              <CardImg
                src={game?.data?.property?.thumbnail}
                width="100%"
                height="auto"
                className="rounded-none"
              />
              <CardBody>
                <CardSubtitle
                  className="mb-2 text-black font-bold mx-auto text-center"
                  tag="h6"
                >
                  {game?.data?.property?.name}
                </CardSubtitle>
                <CardText className="text-black">
                  {game?.data?.property?.subtitle}
                </CardText>
              </CardBody>
            </Card>
          </Tooltip>

          {!game?.data?.multiplayer &&
            !!loginState.accessToken &&
            game?.data?.is_premium &&
            !!game?.data?.expires_at && (
              <iframe
                id={'timepass-gameframe'}
                title={slug}
                className="game-frame no-scrollbar absolute top-0 h-full sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden"
                src={gameIframe}
                key={gameIframe}
              />
            )}

          {!game?.data?.multiplayer && !game?.data?.is_premium && (
            <iframe
              id={'timepass-gameframe'}
              title={slug}
              className="game-frame no-scrollbar absolute top-0 h-full sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden"
              src={gameIframe}
              key={gameIframe}
            />
          )}

          {game?.data?.multiplayer && gameGameSuccessfullyStarted && (
            <iframe
              id={'timepass-gameframe'}
              title={slug}
              className="game-frame no-scrollbar absolute top-[10%] h-[90%] sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden flex items-center justify-center m-0"
              src={gameIframe}
              key={gameIframe}
            />
          )}
        </div>
      )}
      {gameGameSuccessfullyStarted && allMultiPlayerUsers?.length > 0 && (
        <Fragment>
          <div className="w-screen h-screen">
            <div className=" w-full sm:w-fit justify-between text-white sm:justify-start h-[10vh] bg-black bg-opacity-80 sm:border-2 sm:relative sm:border-white gap-4 flex items-center  px-4 py-2 md:cursor-grabbing">
              <div
                className="font-semibold text-white text-base z-20 cursor-pointer"
                onClick={eventHandler(handleClose, isMobile)}
                onTouchStart={eventHandler(handleClose, isMobile)}
              >
                X
              </div>
              <div className="text-white text-base z-20">
                {game?.data?.name}
              </div>
              {isAudioChatEnabled && (
                <div className="flex flex-col items-center gap-1">
                  {micMuted ? (
                    <Fragment>
                      <MicOff
                        className="text-white cursor-pointer w-6 h-6 z-20"
                        onClick={eventHandler(handleMic, isMobile)}
                        onTouchStart={eventHandler(handleMic, isMobile)}
                      />
                      <div className="text-white text-xs z-[999]">
                        Mic is Off
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Mic
                        className="text-white cursor-pointer w-6 h-6 z-20"
                        onClick={eventHandler(handleMic, isMobile)}
                        onTouchStart={eventHandler(handleMic, isMobile)}
                      />
                      <div className="text-white text-xs z-[999]">
                        Mic is On
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
            </div>
            <Draggable>
              <div className="md:cursor-grabbing w-fit h-16 bg-gameroom-lobby-card border-2 gap-3 flex items-center border-white px-4 py-1">
                <DragIndicatorIcon />
                <div className="flex gap-3">
                  {allMultiPlayerUsers?.map((user, idx) => {
                    const splittedUserName = user?.name?.split(' ')[0]
                    const userName =
                      splittedUserName?.length > 5
                        ? `${splittedUserName.substring(0, 4)}...`
                        : splittedUserName

                    if (areAllAvatarsVisible && idx > 0) return null

                    return (
                      <div
                        className="flex flex-col gap-1 justify-center items-center"
                        key={user.uid}
                      >
                        <div className="relative">
                          <img
                            src={user.avatar}
                            alt={user.name}
                            className={`w-9 h-9 rounded-full border-2 border-black user-rtc-${user.uid}`}
                          />
                          {isAudioChatEnabled && (
                            <div className="bg-black p-1 h-fit rounded-full absolute top-[60%] left-[70%] z-20">
                              <MicFun
                                agoraUid={agoraUid}
                                userUID={user.uid}
                                localMicMuted={micMuted}
                                remoteMicMuted={user.isMicMuted}
                              />
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-white z-10">
                          {userName}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <ExpanCircleIcon
                  onClick={handleCircleClose}
                  onTouchStart={handleCircleClose}
                />
              </div>
            </Draggable>
          </div>
        </Fragment>
      )}
      <Fragment>{gameScreenPopUps[selectedGameScreenPopup]}</Fragment>
    </div>
  )
}

export default Game
