import { useEffect, useState } from 'react'

export const useIsOnSafari = () => {
  const [isIOSorSafari, setIsIOSorSafari] = useState(true)

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
    setIsIOSorSafari(isIOS || isSafari)
  }, [])

  return isIOSorSafari
}
