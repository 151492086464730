import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { PremiumCrown } from '../icones'

const LoginModal = ({
  isOpen,
  toggle,
  mobileNumber,
  handleMobileNumberChange,
  handleGetOtp,
  isCorrectMobileNumber,
  isNormalLogin = false,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleGetOtp()
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="login-modal"
      style={{ zIndex: '999', opacity: 1 }}
    >
      <>
        <ModalHeader
          className="pop_up_header"
          style={{
            margin: 'auto',
            fontWeight: '600',
            border: '0',
            paddingBottom: '20px',
          }}
        >
          <div className="styled-border"></div>
          <div style={{ marginTop: '27px' }}>
            {isNormalLogin ? (
              <div className="font-semibold">Login with OTP</div>
            ) : (
              <div className="flex flex-col items-center gap-6">
                <div className="bg-black h-[64px]  w-[64px] rounded-full z-50 flex items-center justify-center ">
                  <PremiumCrown width="30px" height="30px" />
                </div>
                <div className="font-semibold">Login to play premium games</div>
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <p id="p">One time password will be sent to your mobile number</p>
          <PhoneInput
            className="react-tel-input"
            placeholder="Enter phone number"
            country="in"
            value={mobileNumber}
            countryCodeEditable={false}
            onChange={handleMobileNumberChange}
            onEnterKeyPress={handleGetOtp}
          />

          <Button
            id="otp"
            onClick={handleGetOtp}
            onKeyDown={handleKeyDown}
            style={{
              marginTop: '1rem',
              backgroundColor: isCorrectMobileNumber() ? '#F3D505' : '#979797',
            }}
          >
            Get OTP
          </Button>
        </ModalBody>
        <ModalFooter
          style={{
            padding: '1.5rem',
            textAlign: 'center',
            fontSize: '10px',
            whiteSpace: 'pre-wrap',
          }}
        >
          <span style={{ margin: '0' }}>
            By continuing, you agree to our <Link to="/privacy">Terms</Link> and{' '}
            <Link to="/privacy">Privacy Policy</Link>. You may receive SMS
            notifications from us and can opt out at any time.
          </span>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default LoginModal
