import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Col } from 'reactstrap'
import ReactPlayer from 'react-player'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { PremiumCrown } from '../icones'
import { PremiumCard } from './PremiumCard'

const ReelRecomendation = ({
  reelRecomendation,
  handleReelRecomendationClick,
  handleNextPage,
  recomm,
}) => {
  const vidRefs = useRef([])
  const reelWrapperRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isWrapperVisible, setWrapperVisible] = useState(false)
  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.9,
  }

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setWrapperVisible(true)
      } else {
        setWrapperVisible(false)
      }
    })
  }

  const targetRef = useIntersectionObserver(handleIntersection, observerOptions)

  const handleVideoEnd = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      let nextIndex = (prevIndex + 1) % recomm?.length
      while (
        !recomm[nextIndex]?.property?.video &&
        !recomm[nextIndex]?.property?.image
      ) {
        nextIndex = (nextIndex + 1) % recomm?.length
        if (nextIndex === prevIndex) {
          // If no more videos or images found, break the loop
          break
        }
      }
      return nextIndex
    })
    setCurrentImageIndex((prevIndex) => {
      let nextIndex = (prevIndex + 1) % recomm?.length
      while (!recomm[nextIndex]?.property?.image) {
        nextIndex = (nextIndex + 1) % recomm?.length
        if (nextIndex === prevIndex) {
          // If no more images found, break the loop
          break
        }
      }
      return nextIndex
    })
  }, [recomm])

  useEffect(() => {
    if (isWrapperVisible) {
      const timeout = setTimeout(() => {
        if (recomm[currentIndex]?.property?.video) {
          handleVideoEnd()
        } else if (recomm[currentImageIndex]?.property?.image) {
          setCurrentIndex(currentImageIndex)
          handleVideoEnd()
        }
      }, 5000)
      vidRefs.current.forEach((videoRef, index) => {
        if (videoRef && videoRef.getInternalPlayer) {
          if (currentIndex === index) {
            videoRef.getInternalPlayer().play()
          } else {
            videoRef.getInternalPlayer().pause()
          }
        }
      })
      const nextVideoRef = vidRefs.current[currentIndex]
      if (nextVideoRef && nextVideoRef.scrollIntoView) {
        nextVideoRef.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [currentIndex, currentImageIndex, handleVideoEnd, isWrapperVisible])

  return (
    <>
      <p className="text-lg text-black font-bold leading-6 sm:pl-0 pl-2 pt-3">
        Suggested Games
      </p>
      <div
        ref={targetRef}
        id="video-container"
        className="gap-2 wrapper flex overflow-x-auto sm:pl-0 pl-2"
        style={{
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',
        }}
      >
        {recomm?.map((data, index) => {
          const premiumGameBought = data?.is_premium && !!data?.expires_at
          const premiumGameNotBought = data?.is_premium && !data?.expires_at
          return (
            <Col className="h-full w-full" key={data.uuid}>
              <div
                onClick={() =>
                  handleReelRecomendationClick(
                    data?.name,
                    data?.uuid,
                    data?.updated_at
                  )
                }
                className="videos mb-2 w-36 h-64 relative cursor-pointer"
                style={{ scrollSnapAlign: 'center' }}
                ref={(ref) => (vidRefs.current[index] = ref)}
              >
                <PremiumCard
                  premiumGameNotBought={premiumGameNotBought}
                  premiumGameBought={premiumGameBought}
                  expiresAt={data?.expires_at}
                />
                {data?.property?.video && currentIndex === index ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    muted={true}
                    loop={false}
                    controls={false}
                    playsinline={true}
                    playing={currentIndex === index}
                    onEnded={handleVideoEnd}
                    url={data?.property?.video}
                    config={{
                      file: {
                        attributes: {
                          style: {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            borderRadius: '8px',
                          },
                          autoplay: true,
                        },
                      },
                    }}
                  />
                ) : (
                  <div className="relative w-full h-full">
                    <img
                      src={data?.property?.image}
                      alt="reel-bg"
                      className="w-full h-full cursor-pointer"
                      style={{ borderRadius: '8px' }}
                    />
                  </div>
                )}
                {currentIndex !== index && (
                  <div
                    className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent"
                    style={{ borderRadius: '8px' }}
                  >
                    <p className="text-white absolute bottom-0 left-0 pl-3 pb-0">
                      {data?.property?.name?.length > 15
                        ? `${data?.property?.name.substring(0, 15)}...`
                        : data?.property?.name}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          )
        })}
      </div>
    </>
  )
}
export default ReelRecomendation
