import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
}

export const reelSlice = createSlice({
  name: 'reels',
  initialState,
  reducers: {
    selectReel: (state, action) => {
      state.data = action.payload
      state.isSuccess = true
    },
  },
})

export const { selectReel } = reelSlice.actions
export default reelSlice.reducer
