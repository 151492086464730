import React from 'react'
import { Modal } from 'reactstrap'
import 'react-phone-input-2/lib/style.css'
import { CoinImg } from '../icones'
import { convertDateFormat } from '../../../utils/formatDate'

export const CoinTransactionModal = ({
  isOpen,
  toggle,
  currentTransactionData,
}) => {
  const formattedDateString = convertDateFormat(
    currentTransactionData?.created_at
  )
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="cointran-modal">
      <div className="flex flex-col justify-center items-center gap-4 p-6">
        <div className="styled-border"></div>
        <div className="flex font-semibold text-[22px] items-center gap-2">
          <CoinImg className="w-6 h-6" />
          <div>
            <span>
              {currentTransactionData?.transaction_type === 'credit'
                ? '+'
                : '-'}
            </span>
            <span>{currentTransactionData?.transaction_amount}</span>
          </div>
        </div>
        <div className="text-[17px] whitespace-nowrap text-sm md:text-base">
          {currentTransactionData?.metadata?.message}
        </div>
        <img
          src="/coin-wallet.png"
          className="w-[125px] h-[125px]"
          alt="coin-wallet"
        />
        <div className="flex gap-1 md:gap-2 whitespace-nowrap">
          <span className="text-sm md:text-base">TXN ID:</span>
          <span className="uppercase font-semibold text-sm md:text-base">
            {currentTransactionData?.uuid}
          </span>
        </div>
        <div className="text-semibold ">{formattedDateString}</div>
      </div>
    </Modal>
  )
}
