import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
} from 'reactstrap'
import OtpInput from 'react-otp-input'

const OtpModal = ({
  isOpen,
  toggle,
  mobileNumber,
  otp,
  setOtp,
  handleChangeNumber,
  handleSubmitOTP,
  handleResendOTP,
  otpSent,
  counter,
  isVerifyOtpLoading,
}) => {
  const handlePaste = (event) => {
    const data = event.clipboardData.getData('text')
    setOtp(data)
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      handleSubmitOTP()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="otp-modal">
      <div>
        <ModalHeader id="header" style={{ border: '0' }}>
          <div className="styled-border"></div>
          <div style={{ marginTop: '27px' }}>Enter OTP</div>
        </ModalHeader>
        <ModalBody>
          <div id="box">
            <p style={{ fontSize: '14px', marginBottom: '0' }}>
              OTP sent to <b>+{mobileNumber}</b>
            </p>
            <button
              className="otp-change-btn"
              onClick={handleChangeNumber}
              onKeyDown={(e) => console.log(e)}
            >
              CHANGE NUMBER
            </button>
          </div>
          <Container style={{ padding: 0 }}>
            <div onKeyDown={handleKeyDown}>
              <OtpInput
                value={otp}
                onPaste={handlePaste}
                onChange={setOtp}
                numInputs={4}
                separator={<span>&nbsp;&nbsp;</span>}
                isInputNum={true}
                renderInput={(props) => <input {...props} type="submit" />}
                shouldAutoFocus={true}
                containerStyle={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '0.5rem 0',
                }}
                inputStyle={{
                  width: '3.5rem',
                  height: '56px',
                  fontSize: '24px',
                  margin: '10px 0',
                  borderRadius: '4px 4px 0px 0px',
                  border: '1px solid #e7e0ec',
                  borderBottomColor: '#0077ff',
                  background: '#e7e0ec',
                }}
              />
            </div>
          </Container>
          <Button
            style={{
              opacity: isVerifyOtpLoading ? '0.5' : '1',
              backgroundColor: otp?.length === 4 ? '#F3D505' : '#979797',
            }}
            id="otp"
            onClick={handleSubmitOTP}
            type="submit"
          >
            {isVerifyOtpLoading ? 'Verifying OTP...' : 'Verify'}
          </Button>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            {otpSent ? (
              counter === 0 ? (
                <button className="otp-change-btn" onClick={handleResendOTP}>
                  RESEND OTP
                </button>
              ) : (
                <p>Resend OTP in {counter} seconds</p>
              )
            ) : (
              <p>OTP Sent Successfully</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter
          style={{ padding: '1.5rem', textAlign: 'center', fontSize: '10px' }}
        >
          <span style={{ margin: '0' }}>
            By continuing, you agree to our{' '}
            <a
              href="https://simpleviralgames.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>{' '}
            and{' '}
            <a
              href="https://simpleviralgames.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            . You may receive SMS notifications from us and can opt out at any
            time.
          </span>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default OtpModal
