import { useState } from 'react'
import { CloseIcon } from '../icones'
import classNames from 'classnames'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { useLocation } from 'react-router'

export const CheckAppInstalled = ({
  isInstalled,
  deeplink,
  gameName,
  uuid,
}) => {
  const location = useLocation()

  const redirectLink = process.env.REACT_APP_TIMEPASS_DOWNLOAD_URL

  if (deeplink === '') {
    deeplink = redirectLink
  }

  const handleClick = () => {
    const properties = {}
    if (location.pathname === '/') {
      properties.OPEN_FROM = 'Home'
    } else {
      properties.OPEN_FROM = 'Game'
      properties.GAME_NAME = gameName
      properties.GAME_UUID = uuid
    }
    sendAnalytics('track', properties, 'Open App', ['moengage', 'mixpanel'])
    window.location.href = deeplink
  }

  const handleCloseInstalledApps = () => {
    const properties = {}
    if (location.pathname === '/') {
      properties.OPEN_FROM = 'Home'
    } else {
      properties.OPEN_FROM = 'Game'
      properties.GAME_NAME = gameName
      properties.GAME_UUID = uuid
    }
    sendAnalytics('track', properties, 'Close Open App', [
      'moengage',
      'mixpanel',
    ])
    setIsInstalledAppsHidden(true)
  }

  const [isInstalledAppsHidden, setIsInstalledAppsHidden] = useState(false)

  return (
    <div
      className={classNames(
        'h-[7vh] w-full sticky z-[99999] justify-between p-2 bg-white text-white items-center border-b',
        {
          'flex sm:hidden': !isInstalledAppsHidden,
          hidden: isInstalledAppsHidden,
        }
      )}
    >
      <div className="flex gap-2 items-center">
        <CloseIcon
          width="18px"
          height="18px"
          className="cursor-pointer"
          fill="black"
          stroke="black"
          onClick={handleCloseInstalledApps}
        />
        <img
          src="/timepassquare-logo.png"
          alt="timepass-logo"
          className="w-8 h-8"
        />
        <div>
          <div className="text-black font-semibold text-sm">Timepass</div>
          <div className="text-slate-600 text-xs">
            Games Khelo Anytime & Anywhere
          </div>
        </div>
      </div>
      <button
        onClick={handleClick}
        className={classNames(
          'bg-blue-500 rounded-full  font-semibold px-3 py-2  flex items-center text-sm',
          {
            'text-slate-200': !isInstalled,
            'text-white': isInstalled,
          }
        )}
      >
        Open
      </button>
    </div>
  )
}
