import { apiSlice } from '../api/apiSlice'

export const premiumSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPremiumConfig: builder.mutation({
      query: (id) => ({
        url: `reward/premium/${id}`,
        method: 'GET',
      }),
    }),
    postPremiumConfig: builder.mutation({
      query: (id) => ({
        url: `reward/premium/${id}`,
        method: 'POST',
      }),
    }),
  }),
})

export const { useGetPremiumConfigMutation, usePostPremiumConfigMutation } =
  premiumSlice
