import { Modal } from 'reactstrap'
import classNames from 'classnames'
import { ArrowLeft, ChevronRight, GoBack } from '../icones'
// import { useState } from 'react'

export const MoreDetailsModal = ({
  isMoreDetailsModalOpen,
  onClickYearOfBirth,
  handleNextToMoreDetails,
  gender,
  yob,
  goBackFromMoreDetails,
  handleGenderChange,
  toggleMoreDetailsOnScreen,
}) => {
  return (
    <Modal
      isOpen={isMoreDetailsModalOpen}
      style={{ width: 'fit-content', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
      toggle={toggleMoreDetailsOnScreen}
    >
      <div className="px-7 pt-4 flex flex-col gap-8 pb-7">
        <div className="styled-border"></div>
        <div className="text-[22px] font-semibold text-center flex items-center justify-start">
          <span onClick={goBackFromMoreDetails}>
            <ArrowLeft />
          </span>
          <span className="text-center w-full">A few more details</span>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-[15px] font-semibold mb-3">Gender</div>
            <fieldset className="flex gap-4">
              <div>
                <input
                  type="radio"
                  name="lan"
                  value="male"
                  id="male"
                  className="hidden"
                  checked={gender === 'male'}
                  onChange={handleGenderChange}
                />
                <label
                  htmlFor="male"
                  className={classNames(
                    'block border border-[#0094FF] cursor-pointer select-none text-sm font-semibold rounded-xl px-3 py-2 text-center',
                    {
                      'bg-[#0094FF] text-white ': gender === 'male',
                      'bg-white text-[#0094FF]  ': gender !== 'male',
                    }
                  )}
                >
                  Male
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  name="lan"
                  value="female"
                  id="female"
                  className="hidden"
                  checked={gender === 'female'}
                  onChange={handleGenderChange}
                />
                <label
                  htmlFor="female"
                  className={classNames(
                    'block border border-[#0094FF]  text-sm font-semibold   cursor-pointer select-none rounded-xl px-3 py-2 text-center',
                    {
                      'bg-[#0094FF] text-white': gender === 'female',
                      'bg-white text-[#0094FF]': gender !== 'female',
                    }
                  )}
                >
                  Female
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  name="lan"
                  value="other"
                  id="other"
                  className="hidden"
                  checked={gender === 'other'}
                  onChange={handleGenderChange}
                />
                <label
                  htmlFor="other"
                  className={classNames(
                    'block border border-[#0094FF]  text-sm font-semibold   cursor-pointer select-none rounded-xl px-3 py-2 text-center',
                    {
                      'bg-[#0094FF] text-white': gender === 'other',
                      'bg-white text-[#0094FF]': gender !== 'other',
                    }
                  )}
                >
                  Other
                </label>
              </div>
            </fieldset>
          </div>
          <button
            onClick={onClickYearOfBirth}
            className="flex justify-between items-center w-full border border-black p-3 rounded-[4px]"
          >
            <span className="text-base">
              {!!yob ? `Year of birth: ${yob}` : 'Year of birth'}
            </span>
            <ChevronRight
              fill="white"
              stroke="rgba(2, 2, 3, 0.32)"
              width="12px"
              height="12px"
            />
          </button>
          <button
            className="text-xl bg-[#F3D505] py-[10px] px-6 font-medium rounded-3xl"
            onClick={handleNextToMoreDetails}
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  )
}
