import { useState, useContext, createContext } from 'react'

const ScrollContext = createContext(undefined)

const ScrollProvider = (props) => {
  const [
    scrollHomepageForNotLoggedInUser,
    setScrollHomepageForNotLoggedInUser,
  ] = useState(0)

  const [scrollHomePageForLoggedInUser, setScrollHomePageForLoggedInUser] =
    useState(0)

  return (
    <ScrollContext.Provider
      value={{
        scrollHomepageForNotLoggedInUser,
        setScrollHomepageForNotLoggedInUser,
        scrollHomePageForLoggedInUser,
        setScrollHomePageForLoggedInUser,
      }}
    >
      {props.children}
    </ScrollContext.Provider>
  )
}

const useScroll = () => useContext(ScrollContext)

export { ScrollProvider, useScroll }
