export const ShowToast = ({ toastData }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-3">
      <img
        src={toastData.image}
        alt="toast-img"
        className="w-[45px] h-[45px]"
      />
      <div className="text-[22px] font-bold text-center">{toastData.title}</div>
      <div className="text-[17px] text-center">{toastData.description}</div>
    </div>
  )
}
