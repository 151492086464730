import { Modal } from 'reactstrap'
import { CoinImg, MicOff } from '../icones'

// cancelActivate={cancelActivate}
// handleActivate={handleActivate}
// coinsData={coinsData}
// isActivateVoiceChatModalOpen={isActivateVoiceChatModalOpen}

export const ActivateVoiceModal = ({
  cancelActivate,
  handleActivate,
  coinsData,
  isActivateVoiceChatModalOpen,
}) => {
  return (
    <Modal
      isOpen={isActivateVoiceChatModalOpen}
      style={{ width: 'fit-content', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="flex flex-col justify-center my-4 items-center gap-8 px-5">
        <div className="styled-border  rounded-full"></div>

        <div className="flex items-center justify-center bg-[#000c1a63] py-[6px] px-[10px] gap-[2px] rounded-[21px]">
          <CoinImg height="30px" width="30px" />
          <div className="text-[#F3D505] font-semibold">
            {coinsData?.coin_balance}
          </div>
        </div>
        <div className="bg-black w-[64px] h-[64px] rounded-full flex items-center justify-center">
          <MicOff className="text-white cursor-pointer w-6 h-6 mt-1" />
        </div>
        <div className="font-bold text-[22px]">Activate voice chat</div>
        <div className="flex gap-[2px] items-center justify-center">
          <CoinImg width="30px" height="30px" />
          <div>Consume {coinsData?.cost} coins</div>
        </div>

        <div className="flex gap-4">
          <button
            className="border-2 border-black font-medium  rounded-[22px] text-xl px-8 py-2 whitespace-nowrap"
            onClick={cancelActivate}
          >
            Cancel
          </button>
          <button
            className="border border-black font-medium rounded-[22px] text-xl bg-[#F3D505] px-8 py-2 whitespace-nowrap"
            onClick={handleActivate}
          >
            Activate
          </button>
        </div>
      </div>
    </Modal>
  )
}
