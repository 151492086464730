import { Modal } from 'reactstrap'
import { LeaveChannel } from '../icones'
// import classNames from 'classnames'
// import { ArrowRightLobby, GoBack, Mic, MicOff } from '../icones'
// import LobbyImage from './../../../images/lobby-card.png'

export const AreYouSureModal = ({
  isOpenAreYouSureModal,
  handleLeaveRoom,
  handleDontLeave,
  isHost,
  gameGameSuccessfullyStarted,
}) => {
  return (
    <Modal
      isOpen={isOpenAreYouSureModal}
      style={{ width: 'fit-content', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="flex flex-col justify-center items-center p-4 z-[9999]">
        <div className="styled-border"></div>
        <div className="flex flex-col gap-10 mt-10">
          <div className="flex flex-col justify-center items-center gap-3">
            <div className="bg-[#FF3729] p-4 rounded-full w-fit">
              <LeaveChannel className="w-6 h-6 text-white font-bold" />
            </div>
            <div className="font-semibold text-xl">
              {isHost && !gameGameSuccessfullyStarted ? (
                <span>Are you sure you want to close the room? </span>
              ) : (
                <span>Are you sure you want to leave the room?</span>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center gap-3">
            <button
              className="border-[#020303] border-2 py-[10px] px-8 font-semibold text-xl rounded-3xl"
              onClick={handleLeaveRoom}
            >
              {isHost && !gameGameSuccessfullyStarted ? (
                <span>Close</span>
              ) : (
                <span>Leave</span>
              )}
            </button>
            <button
              className="bg-[#F3D505] py-[10px] px-8 font-semibold text-xl rounded-3xl"
              onClick={handleDontLeave}
            >
              {isHost && !gameGameSuccessfullyStarted ? (
                <span>Don't close</span>
              ) : (
                <span>Don't leave</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
