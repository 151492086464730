import { useEffect, useState } from 'react'
import { useDebounce } from './useDebounce'
import { useRefOnScreen } from './useRefOnScreen'
import { useGetSearchResultsMutation } from '../store/game/actions'
import { useLocation, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export const useSearch = (allCategory, toggleSearchModal) => {
  const [searchInput, setSearchInput] = useState('')

  const [selectedCategory, setSelectedCategory] = useState('')

  const [debouncedSearch, setDebouncedSearch] = useState('')

  const [isSearchEmpty, setIsSearchEmpty] = useState(false)

  const [gamesToBeShown, setGamesToBeShown] = useState({})

  const location = useLocation()

  const params = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const allGames = allCategory?.data?.find(
    (cat) => cat.results.uuid === 'all-games'
  )

  const catFromSearch = searchParams.get('cat')
  const termFromSearch = searchParams.get('term')

  useEffect(() => {
    if (location.pathname === '/search' && !catFromSearch && !termFromSearch) {
      setGamesToBeShown(allGames)
    }
  }, [allGames, location])

  useDebounce(() => setDebouncedSearch(searchInput), 500, [searchInput])

  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('20%')

  useEffect(() => {
    if (
      !!debouncedSearch &&
      gamesToBeShown?.current_page < gamesToBeShown?.total_page
    ) {
      console.log('first')
      getSearchResults(debouncedSearch)
    }
  }, [isVisible])

  const [searchResults, { isLoading: isSearchLoading }] =
    useGetSearchResultsMutation()

  const handleEmptySearchState = () => {
    if (!selectedCategory) {
      setSelectedCategory(() =>
        location.pathname === '/search' ? 'all-games' : ''
      )
      setGamesToBeShown(() =>
        location.pathname === '/search' ? allGames : undefined
      )
    } else {
      if (!searchInput || !debouncedSearch) {
        const filteredGames = allCategory?.data?.find(
          (games) => games.results.uuid === selectedCategory
        )
        setGamesToBeShown(filteredGames)
      } else {
        setGamesToBeShown(undefined)
      }
    }
  }

  const getSearchResults = async (query, cat = '') => {
    try {
      const res = await searchResults({
        query,
        category: !cat ? selectedCategory : cat,
        page: !!gamesToBeShown?.results?.games
          ? 1
          : gamesToBeShown?.current_page + 1,
      }).unwrap()

      if (res?.results?.length === 0) {
        handleEmptySearchState()
        setIsSearchEmpty(true)
      } else {
        setIsSearchEmpty(false)
        if (res?.current_page === 1) {
          setGamesToBeShown(res)
        } else {
          const newRes = {
            ...res,
            results: [...gamesToBeShown?.results, ...res?.results],
          }
          setGamesToBeShown(newRes)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!searchInput) {
      console.log('first')
      handleEmptySearchState()
    }
  }, [searchInput])

  const handleSearchChange = (e) => {
    console.log(e)
    if (e.target.value.length === 0) {
      const filteredGames = allCategory?.data?.find(
        (games) => games.results.uuid === selectedCategory
      )
      setGamesToBeShown(filteredGames)
      setDebouncedSearch('')
      setSearchInput('')
    } else {
      setSearchInput(e.target.value)
    }
    setSearchParams({ cat: selectedCategory, term: e.target.value })
    setIsSearchEmpty(false)
  }

  const handleCategoryClick = (cat) => {
    if (selectedCategory === cat && location?.pathname === '/') {
      setSelectedCategory('')
      setGamesToBeShown(undefined)
    } else {
      setSearchParams({ cat: cat, term: searchInput })
      setSelectedCategory(cat)
      if (!searchInput) {
        const filteredGames = allCategory?.data?.find(
          (games) => games.results.uuid === cat
        )
        setGamesToBeShown(filteredGames)
      } else {
        getSearchResults(searchInput, cat)
      }
      setIsSearchEmpty(false)
    }
  }

  return {
    toBeIntersectedImgRef,
    handleSearchChange,
    handleCategoryClick,
    isSearchEmpty,
    searchInput,
    selectedCategory,
    gamesToBeShown,
    debouncedSearch,
    setGamesToBeShown,
    isVisible,
    getSearchResults,
    setSelectedCategory,
    setDebouncedSearch,
    setSearchInput,
    isSearchLoading,
    setIsSearchEmpty,
  }
}
