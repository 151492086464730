import Footer from '../../component/Footer/Footer'
import { useEffect } from 'react'

const TermsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="max-w-[960px] px-4 mx-auto sm:px-6 lg:px-8 mt-10">
        <h1>
          <b>Timepass</b> Terms of Service (“TOS”)
        </h1>
        <h3>Quick summary</h3>
        <ul>
          <li>
            1. These are the legally binding terms of services (“TOS”) for
            Timepass games, websites, forums and other related services
            (collectively “Services”).
          </li>
          <li>
            2. You are given a personal license to use and/or play and access
            our Services it, but Simple Viral Games owns and continues to own
            all rights related to the Services.
          </li>
          <li>
            3. These TOS include your right to use the Services - the do’s and
            the don’ts.
          </li>
          <li>
            4. Depending on where you live, there are important terms regarding
            liability and dispute resolution (including mandatory arbitration
            and waiver of jury trial if you live in the USA).
          </li>
          <li>
            5. Use of the Services is also governed by our Privacy Policy
            available at https://timepass.games/privacy (which explains what
            personal information we may collect and how we use and protect it).
          </li>
        </ul>
        <p>
          This Terms of Service (or “TOS” for short) apply to you and Timepass
          regarding your access and use of Timepass's Services (which we explain
          below). Because we know legal wording is not much fun, we have
          included a short and informal summary of each section (but the full
          version is the legally binding one).
        </p>
        <h2>1. About these TOS</h2>
        <p>
          Agreeing to these TOS is a precondition for accessing and using our
          Services. If you accept these TOS, you represent that you are age 13
          or older (or under 16 in certain jurisdictions in the European Union).
          If you do not agree to these TOS, you may not use or otherwise access
          our Services.
        </p>
        <p>
          Timepass may change and/or update these TOS at any time in response to
          changing legal, technical or business development reasons. Then we
          will put the changed/updated version online and we will take
          appropriate measures to inform you via our Services or otherwise. You
          agree to be bound by the updated TOS, by your continued access and/or
          use of the Services after said updates.
        </p>
        <p>
          1.1. What are these TOS? These TOS are a legally binding contract
          between you and RAHSOU SIMPLE VIRAL GAMES PRIVATE limited
          (incorporated and registered in Bangalore, India) whose registered
          office is at 1st Floor, 1213, 22nd Cross Rd, Sector 3, HSR Layout,
          Bengaluru, Karnataka 560102 (SVG) .
        </p>

        <p>
          1.2. What do these TOS apply to? ? These TOS apply to our Services,
          including (but not limited to) any interactive entertainment products,
          game key or code giving you access to it or any parts of it, Virtual
          Goods and Virtual Currency (defined below), any Timepass products and
          services related to our games, user accounts, customer and technical
          support, official forums, wikis, blogs and social media services.
        </p>

        <p>
          1.3. When do these TOS apply to you? These TOS will be binding once
          you access, download, install or use any of our Services. If you do
          not agree to these TOS, please do not use or access our Services.
        </p>

        <p>
          1.4. Will we ever change these TOS? We may change and/or update these
          TOS from time to time, for example to reflect changes in our Services
          or to reflect applicable laws. If we do, we will make the
          changed/updated TOS available online and make reasonable efforts to
          notify you of it. You are welcome to contact us at
          hi@simpleviralgames.com if you have specific questions about the
          changes. If you do not agree to those changes (regardless of whether
          you email us), then unfortunately you must cease using our Services:
          in order to make all of our Services work properly we need to have
          everyone using them under the same TOS instead of different people
          having different rules.
        </p>

        <p>
          1.5. Are there any other important documents you should read? Please
          also read our Timepass Privacy Policy available at
          https://timepass.games/privacy.
        </p>

        <h2>2. Accessing and using our services, your rights</h2>

        <p>
          You have the personal right to access and use our Services. If
          Services involve a user account, then you are responsible for it.
        </p>

        <p>
          Regardless of your age, you can access and use our Services, However,
          if you are 13 years or older (or 16 in certain jurisdictions in the
          European Union), we may collect personal information with your
          consent.
        </p>

        <p>
          2.1. We give you a personal, limited, revocable, non-exclusive,
          non-transferable, non-sublicensable and non-assignable license to use
          the Services. This license is for your personal non-commercial use
          only (so you cannot give, ‘sell’, lend, gift, assign, sublicense or
          otherwise transfer it to someone else) and does not give you any
          ownership rights in the Services. You do not receive any other
          license. We own and continue to own all of our Services including (but
          not limited to) all copyrights, computer codes, characters, etc.
        </p>

        <p>
          2.2. What about user accounts? In order to access the Services, you
          may need to create a user account or use an existing account (if you
          have one). You are solely responsible for protecting your user account
          and for your account activities. In particular, keep your account and
          password secure. In order to protect our Services, users and Timepass
          itself, we reserve the right, if necessary, to reject any user account
          if it would breach these TOS or other applicable rules.
        </p>

        <p>
          2.3. Are there any age restrictions? Regardless of your age, you can
          access and use our Services, However, if you are 13 years or older (or
          16 in certain jurisdictions in the European Union), we may collect
          personal information with your consent within our Services.
        </p>

        <p>
          2.4. Are there any minimum requirements? Some of our Services may have
          minimum requirements depending on your chosen device/platform or other
          similar issues, of which you will be notified at the time. It is your
          responsibility to ensure you meet these requirements before accessing
          or using the Services. To access or use some of our Services, you will
          need Internet access too – again, this is your responsibility.
        </p>

        <p>
          2.5. What about monitoring? In order to protect Timepass, Timepass
          users and to enforce these TOS, Timepass may deploy anti-cheat and/or
          other software tools that run in the background of your device or
          related devices/peripherals when you use the Services.
        </p>

        <p>
          2.6. What about third-party access and content? You might get links
          from us to third party websites or content through the Services. You
          may also access the Services through different social platforms and
          devices. Your use of them is your responsibility – we cannot promise
          they will work, what they will be like or if they are free. The
          Services may contain third party services such as gameplay recording
          and sharing, social media connectivity, video offers and in-game
          advertisements. While we try to pick the best partners to work with,
          we are not responsible for their content nor services (that is for the
          partner itself). These are services are subject to respective
          third-party terms and conditions and constitutes agreement between you
          and that relevant third party (to which Timepass is not a party).
        </p>

        <p>
          2.7. Are there any health & safety issues to be aware of? Please be
          aware our Services may contain flashing images which may potentially
          trigger seizures for people with epilepsy. Player discretion is
          advised.
        </p>

        <p>
          2.8. What can and can’t you do? In order for you to be able to access
          and use the Services, we ask you to follow some rules not limited to
          the following. Please read these rules carefully since failure to
          follow them (particularly those in relation to cheating) will be
          considered a material breach of these TOS, which may lead to
          suspension or cancellation (temporary or permanent) of your access and
          use of the Services. Here are the main rules that you agree to (under
          all circumstances):
          <ul>
            <li>
              i. Personal enjoyment. Only access and use our Services for your
              personal enjoyment and not for any advertising (or transmission of
              any commercial advertisements such as spam emails), other
              commercial or political purposes.
            </li>
            <li>
              ii. Restricted access. Do not attempt to copy, rent, sell, lend,
              lease, sublicense, distribute, publish or publicly display the
              Services, Virtual Currency or Virtual Goods (defined below), any
              user account or any of your rights under these TOS to any other
              party in any way not expressly authorized herein.
            </li>
            <li>
              iii. Technical misuse. Do not modify, merge, distribute,
              translate, reverse engineer, or attempt to obtain or use source
              code of, decompile or disassemble the Services unless you are
              specifically allowed by applicable law.
            </li>
            <li>
              iv. Hacking/griefing. Do not hack, harm, grief, harass, threaten
              or misuse the Services, other users of our Services of any
              Timepass products, games, services, community members or staff.
            </li>
            <li>
              v. Cheating. Do not create, use, make available and/or distribute
              cheats, exploits, automation software, robots, bots, mods, hacks,
              spiders, spyware, cheats, scripts, trainers, extraction tools or
              other software that interact with or affect the Services in any
              way (including, without limitation, any unauthorized third party
              programs that intercept, emulate, or redirect any communication
              between Timepass or its partners and our Services and/or any
              unauthorized third party programs that collect information about
              the Services by reading areas of memory used by the Services to
              store information).
            </li>
            <li>
              vi. Account misuse. Do not share, ‘buy’, ‘sell’, transfer, gift,
              lend, steal or misappropriate user accounts or the Services access
              keys/codes (all of which are our property). If you are concerned
              that any of this has happened to you, contact customer support at
              hi@timepass.games.
            </li>
            <li>
              vii. Power-leveling. Do not perform in-game services for others
              like power-leveling, boosting or ladder-climbing, whether or not
              in exchange for payment (real money or otherwise) from others.
            </li>
            <li>
              viii. No advertising. . Do not communicate or facilitate any
              commercial advertisement, promotion, spam or unsolicited messages
              through the Services.
            </li>
            <li>
              ix. Timepass services. Do not deliberately or maliciously
              interrupt or interfere with the Services, any other Timepass
              services like customer or technical support or impersonate
              Timepass staff.
            </li>
            <li>
              x. Interfering with servers. Do not overburden, interfere with or
              disrupt the Services or third party network software or servers,
              including via tunneling, code injection or insertion, denial of
              service, modifying or changing the software, using any other
              similar software together with Timepass software, through protocol
              emulation, or through creation or use of private servers or any
              analogous services regarding the Services.
            </li>
            <li>
              xi. Accessing servers. Do not access or attempt to access areas of
              the Services or servers that have not been made available to the
              public.
            </li>
            <li>
              xii. Data mining. Do not intercept, mine or otherwise collect any
              data or information from the Services, other people using the
              Services, including, but not limited to using unauthorized
              third-party software or use of pixel tags, cookies GIFs or similar
              items sometimes also referred to as spyware.
            </li>
            <li>
              xiii. Accounts and virtual content. Only use user accounts,
              Virtual Goods or Virtual Currency (defined below) for their
              intended purpose.
            </li>
            <li>
              xiv. Names/trademarks.Do not use Timepass, Timepass Games or any
              other names of any Services or other Timepass names or logos or
              trademarks for any unauthorized purposes.
            </li>
            <li>
              xv. Infringing Content. Do not do anything in connection with the
              Services that infringes any copyright, trademark, patent, trade
              secret, privacy, publicity, or other right of others, such as
              images, photographs, sound files, text files, graphics files, and
              any other material or information.
            </li>
            <li>
              xvi. Malicious Code. Do not post or upload any files that contain
              any malicious code, including viruses, spyware, Trojan horses,
              worms, time bombs, intentionally corrupted data, any other files
              that contain malicious code or that may in any way damage or
              interfere with the operation of the Services.
            </li>
            <li>
              xvii. Geographic/regional restrictions. You must follow any
              applicable geographic or regional, language or location-based
              restrictions, requirements or rules regarding the Services.
            </li>
            <li>
              xviii. Conduct.Do not do, post or say anything that is or may be
              considered threatening, racist, harassing, xenophobic, sexist,
              discriminatory, abusive, defamatory or otherwise offensive or
              illegal. This includes in any chat or other communications with
              users. Timepass reserves the right to monitor the content of any
              of your messages and prevent your use of any such chat or other
              communication systems for any reason. Please report any behavior
              you think is in breach of this rule by sending a message to
              hi@timepass.games.
            </li>
          </ul>
        </p>
        <h2>3. Your commitments</h2>
        <p>
          In return for our commitment to provide the Service, we require you to
          make the below commitments to us.
        </p>
        <p>
          <b>Who can use Timepass</b>. We want our Service to be as open and
          inclusive as possible, but we also want it to be safe, secure and in
          accordance with the law. So, we need you to commit to a few
          restrictions in order to be part of the Timepass community.
          <ul>
            <li>
              1. You must be at least 3 years old or the minimum legal age in
              your country to use Timepass.
            </li>
            <li>
              2. You must not be prohibited from receiving any aspect of our
              Service under applicable laws or engaging in payments-related
              Services if you are on an applicable denied party listing.
            </li>
            <li>
              3. We must not have previously disabled your account for violation
              of law or any of our policies.
            </li>
            <li>4. You must not be a convicted sex offender.</li>
          </ul>
        </p>
        <p>
          <b>How you can't use Timepass</b>. Providing a safe and open Service
          for a broad community requires that we all do our part.
          <ul>
            <li>
              1.
              <b>
                You can't impersonate others or provide inaccurate information
              </b>
              . You don't have to disclose your identity on Timepass, but you
              must provide us with accurate and up-to-date information
              (including registration information), which may include providing
              personal data. Also, you may not impersonate someone or something
              you aren't, and you can't create an account for someone else
              unless you have their express permission.
            </li>
            <li>
              2.
              <b>
                You can't do anything unlawful, misleading or fraudulent or for
                an illegal or unauthorised purpose.
              </b>
            </li>
            <li>
              3.
              <b>
                You can't violate (or help or encourage others to violate) these
                Terms or our policies, including in particular the Timepass
                Community Guidelines, Timepass Platform Terms and Developer
                Policies and Music Guidelines
              </b>
              . If you post branded content, you must comply with our Branded
              Content Policies, which require you to use our branded content
              tool. Learn how to report conduct or content in our Help Centre.
            </li>
            <li>
              4.
              <b>
                You can't do anything to interfere with or impair the intended
                operation of the Service
              </b>
              . This includes misusing any reporting, dispute or appeals
              channel, such as by making fraudulent or groundless reports or
              appeals.
            </li>
            <li>
              5.
              <b>
                You can't attempt to create accounts or access or collect
                information in unauthorised ways
              </b>
              . This includes creating accounts or collecting information in an
              automated way without our express permission.
            </li>
            <li>
              6.
              <b>
                You can't sell, license or purchase any account or data obtained
                from us or our Service
              </b>
              . This includes attempts to buy, sell or transfer any aspect of
              your account (including your username); solicit, collect or use
              login credentials or badges of other users; or request or collect
              Timepass usernames, passwords or misappropriate access tokens.
            </li>
            <li>
              7.
              <b>
                You can't post someone else's private or confidential
                information without permission or do anything that violates
                someone else's rights, including intellectual property rights
                (e.g. copyright infringement, trademark infringement,
                counterfeit or pirated goods)
              </b>
              . You may use someone else's works under exceptions or limitations
              to copyright and related rights under applicable law. You
              represent that you own or have obtained all necessary rights to
              the content you post or share. Learn more, including how to report
              content that you think infringes your intellectual property
              rights, here.
            </li>
            <li>
              8.
              <b>
                You can't modify, translate, create derivative works of or
                reverse-engineer our products or their components.
              </b>
            </li>
            <li>
              9.
              <b>
                You can't use a domain name or URL in your username without our
                prior written consent.
              </b>
            </li>
          </ul>
        </p>
        <p>
          <b>Permissions you give to us</b>. As part of our agreement, you also
          give us permissions that we need to provide the Service.
          <ul>
            <li>
              1.
              <b>
                We do not claim ownership of your content, but you grant us a
                licence to use it
              </b>
              . Nothing is changing about your rights in your content. We do not
              claim ownership of your content that you post on or through the
              Service and you are free to share your content with anyone else,
              wherever you choose. However, we need certain legal permissions
              from you (known as a "licence") to provide the Service. When you
              share, post or upload content that is covered by intellectual
              property rights (such as photos or videos) on or in connection
              with our Service, you hereby grant to us a non-exclusive,
              royalty-free, transferable, sublicensable, worldwide licence to
              host, use, distribute, modify, run, copy, publicly perform or
              display, translate and create derivative works of your content
              (consistent with your privacy and application settings). This
              licence will end when your content is deleted from our systems.
              You can delete content individually or all at once by deleting
              your account. To learn more about how we use information, and how
              to control or delete your content, review the Privacy Policy and
              visit the Timepass Help Centre.
            </li>
            <li>
              2.
              <b>
                Permission to use your username, profile picture and information
                about your relationships and actions with accounts, ads and
                sponsored content
              </b>
              . You give us permission to show your username, profile picture
              and information about your actions (such as likes) or
              relationships (such as follows) next to or in connection with
              accounts, ads, offers and other sponsored content that you follow
              or engage with that are displayed on Timepass Products, without
              any compensation to you. For example, we may show that you liked a
              sponsored post created by a brand that has paid us to display its
              ads on Timepass. As with actions on other content and follows of
              other accounts, actions on sponsored content and follows of
              sponsored accounts can only be seen by people who have permission
              to see that content or follow. We will also respect your ad
              settings. You can learn more here about your ad settings.
            </li>
            <li>
              3.
              <b>
                You agree that we can download and install updates to the
                Service on your device.
              </b>
            </li>
          </ul>
        </p>
        <h2>4. Additional rights we retain</h2>
        <p>
          <ul>
            <li>
              1. If you select a username or similar identifier for your
              account, we may change it if we believe it is appropriate or
              necessary (for example, if it infringes someone's intellectual
              property or impersonates another user).
            </li>
            <li>
              2. If you use content covered by intellectual property rights that
              we have and make available in our Service (for example, images,
              designs, videos or sounds we provide that you add to content you
              create or share), we retain all rights to our content (but not
              yours).
            </li>
            <li>
              3. You can only use our intellectual property and trademarks or
              similar marks as expressly permitted by our Brand Guidelines or
              with our prior written permission.
            </li>
            <li>
              4. You must obtain written permission from us or under an
              open-source licence to modify, create derivative works of,
              decompile or otherwise attempt to extract source code from us.
            </li>
          </ul>
        </p>
        <h2>5. Content removal and disabling or terminating your account</h2>
        <p>
          <ul>
            <li>
              1. We can remove any content or information that you share on the
              Service if we believe that it violates these Terms of Use, our
              policies (including our Timepass Community Guidelines) or we are
              permitted or required to do so by law. We can refuse to provide or
              stop providing all or part of the Service to you (including
              terminating or disabling your access to the Timepass Products and
              Timepass Company Products) immediately to protect our community or
              services, or if you create risk or legal exposure for us, violate
              these Terms of Use or our policies (including our Timepass
              Community Guidelines), if you repeatedly infringe other people's
              intellectual property rights, or where we are permitted or
              required to do so by law. We can also terminate or change the
              Service, remove or block content or information shared on our
              Service, or stop providing all or part of the Service if we
              determine that doing so is reasonably necessary to avoid or
              mitigate adverse legal or regulatory impacts on us. If you believe
              that your account has been terminated in error, or you want to
              disable or permanently delete your account, consult our Help
              Centre. When you request to delete content or your account, the
              deletion process will automatically begin no more than 30 days
              after your request. It may take up to 90 days to delete content
              after the deletion process has begun. While the deletion process
              for such content is being undertaken, the content is no longer
              visible to other users, but remains subject to these Terms of Use
              and our Data Policy. After the content has been deleted, it may
              take us up to another 90 days to remove it from backups and
              disaster recovery systems.
            </li>
            <li>
              2. Content will not be deleted within 90 days of the account
              deletion or content deletion process beginning in the following
              situations:
              <ul>
                <li>
                  2.1. where your content has been used by others in accordance
                  with this licence and they have not deleted it (in which case
                  this licence will continue to apply until that content has
                  been deleted); or
                </li>
                <li>
                  2.2. where deletion within 90 days is not possible due to
                  technical limitations of our systems, in which case, we will
                  complete the deletion as soon as technically feasible; or
                </li>
                <li>
                  2.3. where deletion would restrict our ability to:
                  <ul>
                    <li>
                      2.3.1. investigate or identify illegal activity or
                      violations of our terms and policies (for example, to
                      identify or investigate misuse of our products or
                      systems);
                    </li>
                    <li>
                      2.3.2. protect the safety and security of our products,
                      systems and users;
                    </li>
                    <li>
                      2.3.3. comply with a legal obligation, such as the
                      preservation of evidence; or
                    </li>
                    <li>
                      2.3.4. comply with a request of a judicial or
                      administrative authority, law enforcement or a government
                      agency;
                    </li>
                  </ul>
                  <li>
                    2.4. in which case, the content will be retained for no
                    longer than is necessary for the purposes for which it has
                    been retained (the exact duration will vary on a
                    case-by-case basis).
                  </li>
                </li>
              </ul>
              <li>
                3. If you delete or we disable your account, these Terms shall
                terminate as an agreement between you and us, but this section
                and the section below called "Our agreement and what happens if
                we disagree" will still apply even after your account has been
                terminated, disabled or deleted.
              </li>
            </li>
          </ul>
        </p>
        <h2>6. Our agreement and what happens if we disagree</h2>
        <p>
          <b>Our agreement</b>
          <ul>
            <li>
              1. Your use of music on the Service is also subject to our Music
              Guidelines, and your use of our API is subject to our Timepass
              Platform Terms and Developer Policies. If you use certain other
              features or related services, additional terms will be made
              available and will also become a part of our agreement. For
              example, if you use payment features, you will be asked to agree
              to the Community Payment Terms. If any of those terms conflict
              with this agreement, those other terms will govern.
            </li>
            <li>
              2. If any aspect of this agreement is unenforceable, the rest will
              remain in effect.
            </li>
            <li>
              3. Any amendment or waiver to our agreement must be in writing and
              signed by us. If we fail to enforce any aspect of this agreement,
              it will not be a waiver.
            </li>
            <li>4. We reserve all rights not expressly granted to you.</li>
          </ul>
        </p>
        <p>
          <b>Who has rights under this agreement.</b>
          <ul>
            <li>
              1. This agreement does not give rights to any third parties.
            </li>
            <li>
              2. You cannot transfer your rights or obligations under this
              agreement without our consent.
            </li>
            <li>
              3. Our rights and obligations can be assigned to others. For
              example, this could occur if our ownership changes (as in a
              merger, acquisition or sale of assets) or by law.
            </li>
          </ul>
        </p>
        <p>
          <b>Who is responsible if something happens.</b>
          <ul>
            <li>
              1. Our Service is provided "as is", and we can't guarantee that it
              will be safe and secure or will work perfectly all the time. TO
              THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES,
              WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT.
            </li>
            <li>
              2. We also don't control what people and others do or say, and we
              aren't responsible for their (or your) actions or conduct (whether
              online or offline) or content (including unlawful or objectionable
              content). We also aren't responsible for services and features
              offered by other people or companies, even if you access them
              through our Service.
            </li>
            <li>
              3. Our responsibility for anything that happens on the Service
              (also called "liability") is limited as much as the law will
              allow. If there is an issue with our Service, we can't know what
              all the possible impacts might be. You agree that we won't be
              responsible ("liable") for any lost profits, revenues, information
              or data, or consequential, special, indirect, exemplary, punitive
              or incidental damages arising out of or related to these Terms,
              even if we know that they are possible. This includes when we
              delete your content, information or account.
            </li>
          </ul>
        </p>
        <p>
          <b>How we will handle disputes.</b>
          <br></br>
          If you are a consumer, the laws of the country in which you reside
          will apply to any claim, cause of action or dispute that you have
          against us that arises out of or relates to these Terms ("claim"), and
          you may resolve your claim in any competent court in that country that
          has jurisdiction over the claim. In all other cases, you agree that
          the claim must be resolved exclusively in the US District Court for
          the Northern District of California or a state court located in San
          Mateo County, that you submit to the personal jurisdiction of either
          of these courts for the purpose of litigating any such claim, and that
          the laws of the State of California will govern these Terms and any
          claim, without regard to conflict of law provisions. Without prejudice
          to the foregoing, you agree that, in its sole discretion, RAHSOU
          SIMPLE VIRAL GAMES PRIVATE LIMITED. may also bring any claim that we
          have against you related to efforts to abuse, interfere or engage with
          our products in unauthorised ways in the country in which you reside
          that has jurisdiction over the claim.
        </p>
        <p>
          <b>Unsolicited material.</b>
          <br></br>
          We always appreciate feedback or other suggestions, but may use them
          without any restrictions or obligation to compensate you for them, and
          are under no obligation to keep them confidential.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default TermsPage
