import { createSlice } from '@reduxjs/toolkit'

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState: {
    data: [],
    totalpage: 0,
  },
  reducers: {
    setRecommendations: (state, action) => {
      state.data = action?.payload?.data
      state.totalpage = action?.payload?.totalpage
      state.isSuccess = true
    },
  },
})

export const { setRecommendations } = recommendationSlice.actions
export default recommendationSlice.reducer
