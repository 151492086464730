import { GameCard } from './GameCard'
import { useRefOnScreen } from '../../../hooks/useRefOnScreen'
import { GamesBarWrapper } from './GamesBarWrapper'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setContinuePlay } from '../../../store/ContinuePlay/continuePlaySlice'
import { useUserContinuePlayMutation } from '../../../store/user/actions'

const ContinuePlay = ({
  continuePlay,
  handleContinuePlayingClick,
  totalPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [toBeIntersectedImgRef, isVisible] = useRefOnScreen('100% 0% 100% 0%')
  const [userContinuePlay, { isLoading: isContinuePlayLoading }] =
    useUserContinuePlayMutation()
  const dispatch = useDispatch()

  const getUserContinuePlay = async (continuePlayPage) => {
    try {
      const res = await userContinuePlay(continuePlayPage).unwrap()
      const nextData = res.results
      dispatch(
        setContinuePlay({
          data: [...continuePlay.data, ...nextData],
          total_page: res.total_page,
        })
      )
      setCurrentPage((prev) => prev + 1)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isVisible && totalPage >= currentPage + 1) {
      getUserContinuePlay(currentPage + 1)
    }
  }, [isVisible])

  return (
    <>
      <p className="text-lg text-black font-bold leading-6 sm:pl-0 pl-2 pt-4">
        Continue Playing
      </p>
      <GamesBarWrapper>
        {continuePlay?.data?.map((data, idx) => {
          return (
            <GameCard
              data={data}
              idx={idx}
              ref={toBeIntersectedImgRef}
              handleGameCardClick={handleContinuePlayingClick}
              allGamesLength={continuePlay?.data?.length}
            />
          )
        })}
      </GamesBarWrapper>
    </>
  )
}
export default ContinuePlay
