import { Modal } from 'reactstrap'

export const CreateOrJoinRoom = ({
  handleJoinRoom,
  handleCreateRoom,
  isCreateRoomJoinRoomPopupOpen,
}) => {
  return (
    <Modal
      isOpen={isCreateRoomJoinRoomPopupOpen}
      className="flex items-center justify-center"
    >
      <div className="flex flex-col gap-3 p-4">
        <button
          onClick={handleCreateRoom}
          className="text-black border-[2px] border-black bg-yellow-200 px-4 py-2"
        >
          Create Room
        </button>
        <button
          onClick={handleJoinRoom}
          className="text-black border-[2px] border-black bg-yellow-200 px-4 py-2"
        >
          Join Room
        </button>
      </div>
    </Modal>
  )
}
