export const formatDateToDDMonth = (inputDateString) => {
  const currentDate = new Date()
  const inputDate = new Date(inputDateString)

  const timeDifference = currentDate - inputDate

  if (timeDifference < 24 * 60 * 60 * 1000) {
    if (timeDifference < 60 * 60 * 1000) {
      return 'Just now'
    } else if (timeDifference < 2 * 60 * 60 * 1000) {
      return 'An hour ago'
    } else {
      return 'Today'
    }
  } else if (timeDifference < 2 * 24 * 60 * 60 * 1000) {
    return 'Yesterday'
  } else {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const day = inputDate.getDate()
    const month = months[inputDate.getMonth()]
    return `${day} ${month}`
  }
}

export const formatTime = (seconds) => {
  if (seconds < 60) {
    return seconds + ' seconds'
  } else if (seconds < 3600) {
    const minutes = Math.round(seconds / 60)
    if (minutes === 1) {
      return '1 minute'
    } else if (minutes === 0) {
      return ''
    } else {
      return minutes + ' minutes'
    }
  } else {
    const hours = Math.floor(seconds / 3600)
    const remainingMinutes = Math.round((seconds % 3600) / 60)
    if (hours === 1) {
      if (remainingMinutes === 1) {
        return `1 hour and 1 minute`
      } else if (remainingMinutes > 1) {
        return `1 hour and ${remainingMinutes} minutes`
      } else {
        return '1 hour'
      }
    } else if (hours > 1) {
      if (remainingMinutes === 1) {
        return `${hours} hours and 1 minute`
      } else if (remainingMinutes > 1) {
        return `${hours} hours and ${remainingMinutes} minutes`
      } else {
        return `${hours} hours`
      }
    } else {
      if (remainingMinutes === 1) {
        return `1 minute`
      } else {
        return `${remainingMinutes} minutes`
      }
    }
  }
}

export const convertDateFormat = (inputDate) => {
  if (!!inputDate) {
    const date = new Date(inputDate)
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date)
    return formattedDate.replace(',', '').replace(' ', ', ')
  }
}

export const convertToAMPMFormat = (dateString) => {
  const date = new Date(dateString)
  const options = { hour: 'numeric', minute: 'numeric', hour12: true }
  return date.toLocaleTimeString(undefined, options)
}
