import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: undefined,
}

export const userHistorySlice = createSlice({
  name: 'userHistory',
  initialState,
  reducers: {
    setUserHistory: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setUserHistory } = userHistorySlice.actions
export default userHistorySlice.reducer
