import { Modal } from 'reactstrap'
import { CoinImg, PremiumCrown } from '../icones'
import { formatTime } from '../../../utils/formatDate'

export const BuyPremiumGameModal = ({
  isBuyPremiumGameModalOpen,
  togglePremiumGameModal,
  gameName,
  handleCancelBuy,
  handleConsumePlay,
  gameCost,
  gameDuration,
  uid,
}) => {
  const timeLeft = formatTime(gameDuration)
  return (
    <Modal
      isOpen={isBuyPremiumGameModalOpen}
      style={{ width: '50%', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="px-14 pt-4 flex flex-col justify-center items-center gap-8 pb-7 z-[99999]">
        <div className="styled-border"></div>
        <div className="bg-black h-[64px] w-[64px] rounded-full z-50 flex items-center justify-center ">
          <PremiumCrown width="30px" height="25px" />
        </div>
        <div className="font-bold text-2xl">Play {gameName}</div>
        <div className="text-center gap-2">
          Unlock <span className="font-medium">{timeLeft}</span> of unlimited
          access by spending <span className="font-medium">{gameCost}</span>{' '}
          coins.
        </div>
        <div className="flex px-6 gap-3">
          <button
            onClick={() => handleCancelBuy(gameName, uid)}
            className="border-2 border-black font-medium  rounded-[22px] text-xl px-3 py-2 whitespace-nowrap"
          >
            Cancel
          </button>
          <button
            onClick={handleConsumePlay}
            className="border flex items-center gap-2 border-black font-medium rounded-[22px] text-xl bg-[#F3D505] px-3 py-2 whitespace-nowrap"
          >
            <CoinImg className="w-6 h-6" />
            <span>Consume & Play</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}
