import { PremiumCrown } from '../icones'
import { PremiumTime } from './PremiumTime'

export const PremiumCard = ({
  premiumGameNotBought,
  premiumGameBought,
  expiresAt,
  maxPlayed,
}) => {
  return (
    <>
      {premiumGameNotBought && (
        <div className="bg-black h-[24px]  w-[24px] rounded-full z-50 flex items-center justify-center absolute top-[5%] left-[80%]">
          <PremiumCrown />
        </div>
      )}

      {premiumGameBought && !!expiresAt && (
        <PremiumTime expiresAt={expiresAt} />
      )}
    </>
  )
}
