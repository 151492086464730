import { apiSlice } from '../api/apiSlice'

export const gameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGame: builder.mutation({
      query: (slug) => ({
        url: `/game/slug/${slug}?platform=WEB&language=en`,
        method: 'GET',
      }),
    }),
  }),
})

export const gameProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGameProductDetail: builder.mutation({
      query: ({ id, body }) => ({
        url: `/game/game-product-purchase-info/${id}`,
        method: 'POST',
        body: {
          growth_factor: body.growthFactor,
        },
      }),
    }),
  }),
})

export const gameProductPurchaseSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postGameProductPurchase: builder.mutation({
      query: ({ id, body }) => ({
        url: `/game/purchase-game-product/${id}`,
        method: 'POST',
        body: {
          growth_factor: body.growthFactor,
        },
      }),
    }),
  }),
})

export const searchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSearchResults: builder.mutation({
      query: (data) => {
        const selectedCategory = !!data.category ? data.category : 'all-games'
        const page = !!data.page ? data.page : 1
        return {
          url: `/game/search?query=${data.query}&category=${selectedCategory}&p=${page}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useGetSearchResultsMutation } = searchSlice

export const { useGetGameMutation } = gameSlice

export const { useGetGameProductDetailMutation } = gameProductSlice

export const { usePostGameProductPurchaseMutation } = gameProductPurchaseSlice
