import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: undefined,
}

export const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {
    setTournament: (state, action) => {
      state.data = action?.payload
    },
  },
})

export const { setTournament } = tournamentSlice.actions
export default tournamentSlice.reducer
