import { Modal } from 'reactstrap'
import { FloatingLabeledInput } from '../Common/FloatingLabelInput'
import { GoBack } from '../icones'

export const JoinRoomWithCode = ({
  gameLogo,
  handleRoomCodeChange,
  gameRoomValue,
  gameName,
  isJoinWithCodeModalOpen,
  handleJoinRoomFromCode,
  goBackFromJoinWithCode,
}) => {
  return (
    <Modal
      isOpen={isJoinWithCodeModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="px-7 pt-4 flex flex-col justify-center items-center gap-8 pb-7">
        <div className="styled-border"></div>
        <div className="flex justify-between w-full items-center gap-8">
          <GoBack
            width="1.5rem"
            height="1.5rem"
            className="cursor-pointer"
            onClick={goBackFromJoinWithCode}
          />
          <div className="text-[1.375rem] font-semibold">
            Join Room with Code
          </div>
          <div></div>
        </div>
        <div className="pb-6 gap-6 w-full flex flex-col items-center justify-center">
          <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
            <img
              src={gameLogo}
              alt="placeholder-avatar"
              className="w-[72px] h-[72px] rounded-full   cursor-pointer"
            />
            <div className="m-0 font-medium text-black text-base ">
              {gameName}
            </div>
          </div>
          <FloatingLabeledInput
            onChange={handleRoomCodeChange}
            value={gameRoomValue}
            labelInput={'Enter Room ID'}
            type="number"
          />
          <button
            className="text-xl bg-[#F3D505] py-[10px] px-6 font-medium rounded-3xl"
            onClick={handleJoinRoomFromCode}
          >
            Join Room
          </button>
        </div>
      </div>
    </Modal>
  )
}
