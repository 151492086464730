import { Modal } from 'reactstrap'
import { FloatingLabeledInput } from '../Common/FloatingLabelInput'
import { GoBack } from '../icones'
import { useLocation } from 'react-router'
export const EnterNameModal = ({
  isEnterNameModalOpen,
  handleNameChange,
  userNameFromAgora,
  handleChangeAvatar,
  selectedAvatar,
  handleJoinCreatedRoom,
  goBackFromEnterModal,
  toggleModalOnScreen,
  firstAvatar,
}) => {
  const location = useLocation()
  const isHomePageOrProfile =
    location.pathname === '/' || location.pathname === '/user/profile'

  return (
    <Modal
      isOpen={isEnterNameModalOpen}
      style={{ width: 'fit-content', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
      toggle={toggleModalOnScreen}
    >
      <div className="px-7 pt-4 flex flex-col justify-center items-center gap-8 pb-7 z-[99999]">
        <div className="styled-border"></div>
        <div className="flex justify-between w-full items-center">
          {!isHomePageOrProfile && (
            <GoBack
              width="1.5rem"
              height="1.5rem"
              className="cursor-pointer"
              onClick={goBackFromEnterModal}
            />
          )}

          <div className="text-[1.375rem] font-semibold mx-9">
            Add name and avatar
          </div>
          <div></div>
        </div>

        <div className="pb-6 gap-6 w-full flex flex-col items-center justify-center">
          <div
            className="flex flex-col justify-center items-center gap-1 cursor-pointer"
            onClick={handleChangeAvatar}
          >
            <img
              src={!!selectedAvatar ? selectedAvatar : firstAvatar}
              alt="placeholder-avatar"
              className="w-[72px] h-[72px] rounded-full border-[1.5px] border-[#0077FF] cursor-pointer"
            />
            <div className="m-0 font-medium text-[#0077FF] text-base ">
              Change avatar
            </div>
          </div>
          <FloatingLabeledInput
            onChange={handleNameChange}
            value={userNameFromAgora}
            labelInput={'Your name'}
            type="text"
          />
          <button
            className="text-xl bg-[#F3D505] py-[10px] px-6 font-medium rounded-3xl"
            onClick={handleJoinCreatedRoom}
          >
            {isHomePageOrProfile ? 'Next' : 'Save & Join Room'}
          </button>
        </div>
      </div>
    </Modal>
  )
}
