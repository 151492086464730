import {
  selectIsConnectedToRoom,
  useHMSActions,
  selectPeers,
  useHMSStore,
  selectIsLocalAudioEnabled,
  selectHMSMessages,
} from '@100mslive/react-sdk'
import { useEffect } from 'react'
import { handleMPmsgs } from '../utils/muliplayerMessages'

export const use100ms = (
  setAllMultiPlayerUsers,
  selectedAvatar,
  agoraUid,
  allMultiPlayerUsers,
  micMuted,
  setMicMuted,
  setChannelMessage
) => {
  const hmsActions = useHMSActions()

  const peers = useHMSStore(selectPeers)

  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled)

  const isConnected = useHMSStore(selectIsConnectedToRoom)

  const storeMessages = useHMSStore(selectHMSMessages)

  useEffect(() => {
    if (isConnected && peers) {
      const connectedPeers = peers?.reduce((acc, val) => {
        const peerInfo = JSON.parse(val.metadata)
        return [
          ...acc,
          {
            uid: val.customerUserId,
            name: val.name,
            avatar: peerInfo.avatar,
            isMicMuted: peerInfo.isMicMuted,
          },
        ]
      }, [])
      setAllMultiPlayerUsers(connectedPeers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, peers])

  useEffect(() => {
    if (storeMessages?.length > 0) {
      const broadCastedMessage = JSON.parse(
        storeMessages[storeMessages.length - 1].message
      )
      handleMPmsgs(broadCastedMessage, setChannelMessage)
    }
  }, [storeMessages])

  const init100ms = async (hmsToken, userNameFromAgora, selectedAvatar) => {
    try {
      hmsActions.join({
        userName: userNameFromAgora,
        authToken: hmsToken,
        metaData: JSON.stringify({ avatar: selectedAvatar, isMicMuted: '1' }),
        settings: {
          isAudioMuted: true,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handle100msMessage = (message) => {
    hmsActions.sendBroadcastMessage(message.text)
  }

  const handleLeaveHms = () => {
    hmsActions.leave()
  }

  const toggleHmsMicToggle = async () => {
    await hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled)

    setMicMuted(!micMuted)
    const localUserData = allMultiPlayerUsers.find(
      (user) => user.uid === agoraUid
    )
    const changedMetaData = JSON.stringify({
      avatar: selectedAvatar,
      isMicMuted: localUserData.isMicMuted === '1' ? '0' : '1',
    })
    hmsActions.changeMetadata(changedMetaData)
  }

  return { init100ms, toggleHmsMicToggle, handle100msMessage, handleLeaveHms }
}
