import { Modal } from 'reactstrap'
import { AndroidDownload, AppleDownload, TimepassLogoMobile } from '../icones'

export const PreJoinRoom = ({
  isPreJoinRoomModalOpen,
  gameLogo,
  onJoinRoomClick,
  gameParticipants,
  host,
  gameName,
}) => {
  return (
    <Modal
      isOpen={isPreJoinRoomModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
    >
      <div className=" flex flex-col justify-center items-center gap-8">
        <div className="flex flex-col justify-center items-center gap-10 p-12">
          <p className="text-base m-0">{host?.name} has invited you to play</p>
          <div className="flex flex-col justify-center items-center gap-2">
            <img
              src={gameLogo}
              alt="game-logo"
              className="w-14 h-14 rounded-xl"
            />
            <p className="text-base font-semibold m-0">{gameName}</p>
            <p className="text-xs m-0">
              Currently in room:{' '}
              {gameParticipants?.map((players, idx) => (
                <span key={idx}>
                  {idx !== 0 ? `, ${players.name}` : players.name}
                </span>
              ))}
            </p>
          </div>
          <button
            className="text-xl bg-[#F3D505] py-[10px] px-6 font-semibold rounded-3xl"
            onClick={onJoinRoomClick}
          >
            Join Room
          </button>
        </div>
        <div className="bg-[#F3D505] bg-opacity-20 flex flex-col justify-center items-center py-6 px-14 w-full gap-4">
          <TimepassLogoMobile />
          <p className="m-0">For better experience download our app</p>
          <div className="flex gap-1">
            <a href="https://apple.co/3MHAuNq">
              <AppleDownload />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.simpleviralgames.timepass">
              <AndroidDownload />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}
