import { useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'
import { handleMPmsgs } from '../utils/muliplayerMessages'

export const useAgoraUtils = (
  allMultiPlayerUsers,
  setAllMultiPlayerUsers,
  agoraUid,
  setAgoraUid,
  micMuted,
  setMicMuted,
  setChannelMessage
) => {
  const [rtcClient, setRtcClient] = useState()
  const [rtmClient, setRtmClient] = useState()
  const [rtmChannel, setRtmChannel] = useState()
  const [isLocalMicEnabled, setIsLocalMicEnabled] = useState(false)

  const [audioTracks, setAudioTracks] = useState({
    localAudioTrack: null,
    remoteAudioTracks: {},
  })

  const [newUserInfo, setNewUserInfo] = useState('')

  const initVolumeIndicator = async (rtcClient) => {
    AgoraRTC.setParameter('AUDIO_VOLUME_INDICATION_INTERVAL', 500)
    rtcClient.enableAudioVolumeIndicator()
    rtcClient.on('volume-indicator', (volumes) => {
      volumes.forEach((volume) => {
        try {
          let item = document.getElementsByClassName(
            `user-rtc-${volume.uid}`
          )[0]
          if (volume.level >= 50) {
            item.style.borderColor = '#00ff00'
          } else {
            item.style.borderColor = '#000000'
          }
        } catch (error) {
          console.error(error)
        }
      })
    })
  }

  const initRTC = async (channelName, uid, token) => {
    const rtcClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

    rtcClient.on('user-published', async (user, mediaType) => {
      await rtcClient.subscribe(user, mediaType)
      if (mediaType === 'audio') {
        setAudioTracks((prevAudioTracks) => ({
          ...prevAudioTracks,
          remoteAudioTracks: {
            ...prevAudioTracks.remoteAudioTracks,
            [user.uid]: [user.audioTrack],
          },
        }))
        user?.audioTrack?.play()
      }
      setAllMultiPlayerUsers((prev) =>
        prev.map((agoraUser) =>
          agoraUser.uid === user.uid
            ? {
                uid: agoraUser.uid,
                name: agoraUser.name,
                avatar: agoraUser.avatar,
                isMicMuted: '0',
              }
            : agoraUser
        )
      )
    })

    rtcClient.on('user-unpublished', async (user, mediaType) => {
      await rtcClient.unsubscribe(user, mediaType)
      setAudioTracks((prevAudioTracks) => {
        const newRemoteAudioTracks = {
          ...prevAudioTracks.remoteAudioTracks,
        }
        delete newRemoteAudioTracks[user.uid]

        return {
          ...prevAudioTracks,
          remoteAudioTracks: newRemoteAudioTracks,
        }
      })
      setAllMultiPlayerUsers((prev) =>
        prev.map((agoraUser) =>
          agoraUser.uid === user.uid
            ? {
                uid: agoraUser.uid,
                name: agoraUser.name,
                avatar: agoraUser.avatar,
                isMicMuted: '1',
              }
            : agoraUser
        )
      )
    })

    rtcClient.on('user-left', (user) => {
      setAudioTracks((prevAudioTracks) => {
        const newRemoteAudioTracks = {
          ...prevAudioTracks.remoteAudioTracks,
        }
        delete newRemoteAudioTracks[user.uid]

        return {
          ...prevAudioTracks,
          remoteAudioTracks: newRemoteAudioTracks,
        }
      })
    })

    await rtcClient.join(
      process.env.REACT_APP_AGORA_APP_ID,
      channelName,
      token,
      uid
    )

    try {
      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      localAudioTrack.setMuted(micMuted)

      setAudioTracks((prevAudioTracks) => ({
        ...prevAudioTracks,
        localAudioTrack: localAudioTrack,
      }))
    } catch (error) {
      console.error(error)
      setIsLocalMicEnabled(false)
    }

    // await rtcClient.publish(localAudioTrack)

    initVolumeIndicator(rtcClient)
    setRtcClient(rtcClient)
  }

  const initRTM = async (
    channelName,
    uid,
    agoraToken,
    username,
    avatar,
    isHost
  ) => {
    const rtmClient = AgoraRTM.createInstance(
      process.env.REACT_APP_AGORA_APP_ID
    )

    await rtmClient.login({
      uid: uid,
      token: agoraToken,
    })

    const channel = rtmClient.createChannel(channelName)

    setAgoraUid(uid)

    await rtmClient.addOrUpdateLocalUserAttributes({
      name: username,
      uid: uid.toString(),
      avatar: avatar,
      isMicMuted: '1',
    })

    await channel.join()

    try {
      const members = await channel.getMembers()
      if (members?.length > 0) {
        try {
          const allUsers = []
          for (let i = 0; members?.length > i; i++) {
            const { name, uid, avatar, isMicMuted } =
              await rtmClient.getUserAttributesByKeys(members[i], [
                'name',
                'uid',
                'avatar',
                'isMicMuted',
              ])
            const user = { name, uid, avatar, isMicMuted }
            allUsers.push(user)
          }
          //use this in the future
          // Promise.all(allUsers).then((result) => setAllMultiPlayerUsers(result))
          setAllMultiPlayerUsers(allUsers)
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }

    channel.on('MemberJoined', async (rtmId) => {
      try {
        const { name, uid, avatar, isMicMuted } =
          await rtmClient.getUserAttributesByKeys(rtmId, [
            'name',
            'uid',
            'avatar',
            'isMicMuted',
          ])
        const userInfo = { name, uid, avatar, isMicMuted }
        setNewUserInfo(userInfo.name)

        setAllMultiPlayerUsers((prev) =>
          prev.uid !== userInfo.uid ? [...prev, userInfo] : prev
        )
      } catch (error) {
        console.error(error)
      }
    })

    channel.on('MemberLeft', (mem) => {
      setAllMultiPlayerUsers((prev) => prev.filter((user) => user.uid !== mem))
    })

    channel.on('ChannelMessage', (message) => {
      const channelMsg = JSON.parse(message.text)
      handleMPmsgs(channelMsg, setChannelMessage)
    })
    setRtmClient(rtmClient)
    setRtmChannel(channel)
  }

  const handleAgoraMessage = async (message) => {
    try {
      await rtmChannel.sendMessage(message)
    } catch (error) {
      console.error(error)
    }
  }

  const leaveRtmChannel = async () => {
    try {
      await rtmChannel?.leave()
    } catch (error) {
      console.error(error)
    }

    try {
      await rtmClient?.logout()
    } catch (error) {
      console.error(error)
    }
  }

  const handleLeaveAgoraRoom = async () => {
    if (!!audioTracks.localAudioTrack) {
      audioTracks.localAudioTrack.stop()
      audioTracks.localAudioTrack.close()
    }
    rtcClient?.unpublish()
    rtcClient?.leave()
    leaveRtmChannel()
    setAllMultiPlayerUsers([])
  }

  const handleAgoraMicToggle = async (uid) => {
    if (micMuted) {
      try {
        await rtcClient.publish(audioTracks.localAudioTrack)
        await rtmClient.addOrUpdateLocalUserAttributes({
          isMicMuted: '0',
        })
        setMicMuted(false)
        audioTracks.localAudioTrack.setMuted(false)
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await rtcClient.unpublish(audioTracks.localAudioTrack)
        await rtmClient.addOrUpdateLocalUserAttributes({
          isMicMuted: '1',
        })
        setMicMuted(true)
        audioTracks.localAudioTrack.setMuted(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    handleLeaveAgoraRoom,
    initRTC,
    micMuted,
    handleAgoraMicToggle,
    initRTM,
    newUserInfo,
    audioTracks,
    agoraUid,
    handleAgoraMessage,
    isLocalMicEnabled,
    setIsLocalMicEnabled,
  }
}
