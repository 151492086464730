import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useUserNameMutation } from '../../../store/user/actions'
import {
  setUserInfo,
  setCredentails,
  setAvatars,
} from '../../../store/login/loginSlice'
import { useGetStreaksMutation } from '../../../store/streaks/action'
import { setStreaks } from '../../../store/streaks/streakSlice'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { usePostLogoutMutation } from '../../../store/login/logout/actions'
import { logout } from '../../../store/login/loginSlice'
import { ChangeAvatarModal } from '../../component/Multiplayer/ChangeAvatarModal'
import { EnterNameModal } from '../../component/Multiplayer/EnterNameModal'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { MoreDetailsModal } from '../../component/Home/MoreDetailsModal'
import { YearPickerModal } from '../../component/Common/YearPickerModal'
import { isBottomBarVisible } from '../../../utils/constansts'
import { BottomBar } from '../../component/Common/BottomBar'
import { useNavigate } from 'react-router'
import {
  CoinImg,
  FBIcon,
  LogoutIcon,
  PenIcon,
  PhoneIcon,
  Streak,
  TwitterIcon,
} from '../../component/icones'
import '../../component/Footer/Footer.css'
import { useGetAgoraUsersMutation } from '../../../store/communication/action'

export const ProfilePage = () => {
  const loginState = useSelector((state) => state.login)

  const naviagte = useNavigate()

  const {
    handleAvatarChange,
    handleSaveNameAvatar,
    onSelectedAvatar,
    toggleAvatarModalOnScreen,
    toggleEnterNameModalOnScreen,
    homePageModals,
    setHomePageModals,
    handleChangeAvatar,
    goBackFromEnterModal,
    handleNameChange,
    onClickYearOfBirth,
    handleProfile,
    handleGenderChange,
    goBackFromMoreDetails,
    toggleMoreDetailsOnScreen,
    handleNextToMoreDetails,
    handleGoBackFromYearPick,
    handleYearChange,
    toggleYearOfBirthOnScreen,
  } = useProfileUpdate()

  const streaks = useSelector((state) => state.streaks)

  const navigate = useNavigate()

  const [postLogout] = usePostLogoutMutation()

  const dispatch = useDispatch()

  const [getProfileSlice, { isLoading: isProfileLoading }] =
    useUserNameMutation()

  const [getStreaks, { isLoading: isStreaksLoading }] = useGetStreaksMutation()

  const [getAgoraUsers, { isLoading: isAvatarsLoading }] =
    useGetAgoraUsersMutation()
  const handleLogout = async () => {
    naviagte('/')
    dispatch(logout())
    try {
      await postLogout({
        refresh_token: loginState.refreshToken,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    const properties = {
      ScreenName: 'Homepage',
    }

    sendAnalytics('track', properties, 'Logout Button Pressed', [
      'moengage',
      'mixpanel',
    ])
    sendAnalytics('reset', false, false, ['moengage', 'mixpanel'])

    window.location.reload()
  }

  const getUserName = async () => {
    try {
      const nameRes = await getProfileSlice().unwrap()

      dispatch(
        setUserInfo({
          name: nameRes?.data?.name,
          selectedAvatar: nameRes?.data?.avatar,
          yearOfBirth: nameRes?.data?.year_of_birth,
          gender: nameRes?.data?.gender,
          mobile: nameRes?.data?.mobile,
          code: nameRes?.data?.country_code,
          social_id: nameRes?.data?.social_id,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getAvatars = async () => {
    try {
      const res = await getAgoraUsers().unwrap()
      // return res.token
      const randomNumber = Math.floor(Math.random() * res?.data?.length - 1)
      const ava = res?.data?.reduce(
        (acc, val, idx) =>
          idx === randomNumber
            ? [...acc, { src: val, selected: true }]
            : [...acc, { src: val, selected: false }],
        []
      )
      dispatch(setAvatars(ava))
      // if (loginState?.selectedAvatar?.length === 0) {
      //   dispatch(setUserInfo({ selectedAvatar: ava[randomNumber]?.src }))
      // }
    } catch (error) {
      console.log(error)
    }
  }

  const getUserStreakData = async () => {
    try {
      const streakData = await getStreaks().unwrap()
      dispatch(setStreaks(streakData))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getUserStreakData()
  }, [])

  useEffect(() => {
    if (!loginState.mobile) {
      getUserName()
    }
    if (loginState?.avatars?.length === 0) {
      getAvatars()
    }
  }, [])

  const handleStreakIconPress = () => {
    navigate(`/streaks`, { state: { from: '/' } })
    const properties = {
      OPENED_FROM: 'Home',
      USER_COINS: streaks?.data?.current_streak_days,
    }
    sendAnalytics('track', properties, 'Streaks Icon Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleCoinTransactionPressed = () => {
    navigate('/coins/transactions', { state: { from: '/' } })
    const properties = {
      OPENED_FROM: 'Home',
      USER_COINS: streaks?.data?.coin_balance,
    }
    sendAnalytics('track', properties, 'Coins Icon Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handlePrivacy = () => {
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Privacy Clicked', [
      'moengage',
      'mixpanel',
    ])

    navigate('/privacy')
  }

  const handleTerms = () => {
    navigate('/terms')
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Terms Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleSupport = () => {
    navigate('/support')
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Support Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const nameToBeDisplayed = !loginState?.name
    ? 'Guest'
    : loginState?.name?.length > 7
    ? `${loginState?.name?.substring(0, 6)}...`
    : loginState?.name

  return (
    <div className="bg-[#f6f7f9] h-screen px-[24px] pt-[36px] pb-24 overflow-y-scroll">
      <ChangeAvatarModal
        avatars={loginState?.avatars}
        handleAvatarChange={handleAvatarChange}
        isChangeAvatarModalOpen={homePageModals.isChangeAvatarModalOpen}
        onSelectedAvatar={onSelectedAvatar}
        selectedAvatar={loginState?.selectedAvatar}
        firstAvatar={loginState?.avatars?.[0]?.src}
        toggleModalOnScreen={toggleAvatarModalOnScreen}
      />
      <EnterNameModal
        goBackFromEnterModal={goBackFromEnterModal}
        handleChangeAvatar={handleChangeAvatar}
        handleJoinCreatedRoom={handleSaveNameAvatar}
        handleNameChange={handleNameChange}
        isEnterNameModalOpen={homePageModals.isEnterNameModalOpen}
        selectedAvatar={loginState?.selectedAvatar}
        userNameFromAgora={loginState?.name}
        toggleModalOnScreen={toggleEnterNameModalOnScreen}
        firstAvatar={loginState?.avatars?.[0]?.src}
      />
      <MoreDetailsModal
        isMoreDetailsModalOpen={homePageModals.isMoreDetailsModalOpen}
        onClickYearOfBirth={onClickYearOfBirth}
        gender={loginState.gender}
        yob={loginState.yearOfBirth}
        handleGenderChange={handleGenderChange}
        goBackFromMoreDetails={goBackFromMoreDetails}
        toggleMoreDetailsOnScreen={toggleMoreDetailsOnScreen}
        handleNextToMoreDetails={handleNextToMoreDetails}
      />
      <YearPickerModal
        isEnterYearOfBirthModalOpen={homePageModals.isEnterYearOfBirthModalOpen}
        handleGoBackFromYearPick={handleGoBackFromYearPick}
        handleYearChange={handleYearChange}
        yob={loginState.yearOfBirth}
        toggleYearOfBirthOnScreen={toggleYearOfBirthOnScreen}
      />
      <div
        id="profile-section"
        className="flex items-center mb-[24px] gap-2"
        onClick={handleProfile}
      >
        <div>
          <img
            src={
              !!loginState?.selectedAvatar
                ? loginState?.selectedAvatar
                : loginState?.avatars?.[0]?.src
            }
            alt="avatar"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-[22px] flex gap-2 items-center ">
            <span>{nameToBeDisplayed}</span>
            <span className="cursor-pointer">
              <PenIcon width="1rem" height="1rem" />
            </span>
          </div>
          <div className="text-[22px] text-sm bg-[#ebeef1] p-1 rounded-sm">
            @{loginState?.social_id}
          </div>
          <div className="flex gap-1 items-center">
            <PhoneIcon width="1rem" height="1rem" />
            <span className="text-sm">{loginState?.mobile}</span>
          </div>
        </div>
      </div>
      <div id="coins-streaks" className="flex gap-4 ">
        <div
          className="bg-[#ffff] px-4 py-2 flex flex-col gap-2 rounded-lg w-1/2 cursor-pointer"
          onClick={handleCoinTransactionPressed}
        >
          <div className="text-[12px]">Coins</div>
          <div className="flex gap-2">
            <CoinImg className="w-6 h-6" />
            <div className="text-[17px] font-semibold text-[#d1800e]">
              {streaks?.data?.coin_balance}
            </div>
          </div>
        </div>
        <div
          className="bg-[#ffff] px-4 py-2 flex flex-col gap-2 rounded-lg w-1/2 cursor-pointer"
          onClick={handleStreakIconPress}
        >
          <div className="text-[12px]">Days Streak</div>
          <div className="flex gap-2">
            <Streak className="w-6 h-6" fill="#f09819" />
            <div className="text-[17px] font-semibold text-[#d1800e]">
              {streaks?.data?.current_streak_days}
            </div>
          </div>
        </div>
      </div>
      <div className="my-[30px]">Settings</div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <button
            onClick={handlePrivacy}
            className="bg-white py-[18px] rounded-xl"
          >
            Privacy
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={handleTerms}
            className="bg-white py-[18px] rounded-xl"
          >
            Terms
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={handleSupport}
            className="bg-white py-[18px] rounded-xl"
          >
            Support
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={handleLogout}
            className="bg-white py-[18px] rounded-xl flex items-center justify-center gap-4"
          >
            {/* <LogoutIcon /> */}
            <span>Logout</span>
          </button>
        </div>
      </div>
      <div className="sticky bottom-0">
        <BottomBar token={loginState.accessToken} />
      </div>
    </div>
  )
}
