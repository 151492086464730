import { useEffect, useState } from 'react'

export const useOnScreen = (ref, rootMargin = '0px', threshold = 0) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (ref?.current === undefined || ref?.current === null) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry?.isIntersecting)
      },
      { rootMargin, threshold }
    )
    observer.observe(ref.current)

    return () => {
      if (ref?.current === undefined || ref?.current === null) return
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(ref?.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, rootMargin])

  return isVisible
}
