import { Modal } from 'reactstrap'
import { ArrowLeft, Check } from '../icones'
import classNames from 'classnames'

export const YearPickerModal = ({
  isEnterYearOfBirthModalOpen,
  handleGoBackFromYearPick,
  handleYearChange,
  yob,
  toggleYearOfBirthOnScreen,
}) => {
  const date = new Date().getFullYear()
  const startYear = date - 70
  const endYear = date

  const yearsArray = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => endYear - index
  )

  return (
    <Modal
      isOpen={isEnterYearOfBirthModalOpen}
      style={{ width: '50%', padding: '0', zIndex: '999999' }}
      className="multiplayer-modal-wrapper"
      toggle={toggleYearOfBirthOnScreen}
    >
      <div
        className="px-3 pt-6 pb-2 cursor-pointer"
        onClick={handleGoBackFromYearPick}
      >
        <ArrowLeft />
      </div>
      <div className="h-[30rem] overflow-scroll">
        {yearsArray?.map((item, idx) => {
          return (
            <div>
              <div
                idx={item}
                className={classNames(
                  'px-8 flex items-center gap-6 py-3 text-base font-medium cursor-pointer hover:bg-[#ededed]',
                  { 'bg-[#ededed]': yob.toString() === item.toString() }
                )}
                onClick={() => handleYearChange(item)}
              >
                <span>
                  {yob.toString() === item.toString() && (
                    <Check width="16px" height="16px" />
                  )}
                </span>
                <span
                  className={classNames('', {
                    'ml-5': yob !== item,
                  })}
                >
                  {' '}
                  {item}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
