import { Fragment } from 'react'
import { AvatarSmallGroup, CoinImg } from '../icones'
import { TimeLeft } from './TimeLeft'

export const CarouselCard = ({ active, handlePlayInTournamentFromActive }) => {
  return (
    <Fragment>
      <div
        className="flex flex-col justify-start py-4 gap-[1rem] cursor-pointer"
        onClick={() =>
          handlePlayInTournamentFromActive(
            active?.uuid,
            active?.tournament_name
          )
        }
      >
        <div className=" text-base md:text-[20px]  font-semibold whitespace-nowrap">
          {active?.tournament_name}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <CoinImg className="w-4 h-4 md:w-6 md:h-6" />
          <div className="whitespace-nowrap text-sm md:text-base">
            Price Pool ({active?.pool_prize})
          </div>
        </div>
        <div className="flex gap-2 text-sm md:text-base items-center ">
          <AvatarSmallGroup />
          <div className="whitespace-nowrap">
            {active?.active_participants} playing
          </div>
        </div>
        <TimeLeft endDate={active?.end_date} />
      </div>
      <div
        className="flex items-center justify-end w-[100%] h-[100%] md:p-4"
        onClick={() =>
          handlePlayInTournamentFromActive(
            active?.uuid,
            active?.tournament_name
          )
        }
      >
        <img
          onClick={() =>
            handlePlayInTournamentFromActive(
              active?.uuid,
              active?.tournament_name
            )
          }
          src={active?.game?.thumbnail}
          alt="thumbnail"
          className=" rounded-xl min-w-[7rem] md:min-w-0 min-h-[7rem] md:min-h-0 md:w-[11rem] md:h-[10rem] "
        />
      </div>
    </Fragment>
  )
}
