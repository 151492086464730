import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setTokens, logout } from '../login/loginSlice'
import { sendAnalytics } from '../../utils/mixpannelConn'
import { v4 as uuidv4 } from 'uuid'

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_SERVER_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().login.accessToken
    const anonymousId = headers.get('anonymous-user-id')
    headers.set('platform-name', 'WEB')
    headers.set('build-number', '1')
    // headers.set('time-zone', 'Asia/Kolkata')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    if (!anonymousId) {
      let id = localStorage.getItem('tp-id')
      if (!id) {
        id = uuidv4()
        headers.set('anonymous-user-id', id)
        localStorage.setItem('tp-id', id)
      } else {
        headers.set('anonymous-user-id', id)
      }
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    // send refresh token to get new access token

    const refreshToken = api.getState().login.refreshToken

    const refreshResult = await baseQuery(
      {
        url: 'user/refresh',
        method: 'POST',
        body: {
          refresh: refreshToken,
        },
      },
      api,
      extraOptions
    )
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setTokens({ ...refreshResult.data }))

      localStorage.setItem(
        'accessToken',
        JSON.stringify(refreshResult?.data?.access)
      )
      localStorage.setItem(
        'refreshToken',
        JSON.stringify(refreshResult?.data?.refresh)
      )
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
      localStorage.setItem('refreshToken', null)
      localStorage.setItem('accessToken', null)
      sendAnalytics('reset', false, false, ['moengage', 'mixpanel'])
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
})
