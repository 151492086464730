import toast from 'react-hot-toast'
import { Error } from '../app/component/icones'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMobile,
  setCredentails,
  setOtpToStore,
} from '../store/login/loginSlice'
import { showNotification } from '../utils/showNotification'
import { useLocalStorage } from './useLocalStorage'
import { sendAnalytics } from '../utils/mixpannelConn'
import { useVerifyOTPMutation } from '../store/login/verifyUser/action'
import { useLoginMutation } from '../store/login/createUser/actions'

export const useLoginInfo = () => {
  const [mobileNumber, setMobileNumber] = useState('')
  const [counter, setCounter] = useState(0)
  const [countryCode, setCountryCode] = useState('')
  const [login, { isError }] = useLoginMutation()
  const [otp, setOtp] = useState('')
  const [verifyOTP, { isLoading: isVerifyOtpLoading }] = useVerifyOTPMutation()
  const [otpSent, setOtpSent] = useState(false)

  const [isNormalLogin, setNormalLogin] = useState(false)
  const loginState = useSelector((state) => state.login)

  // const [isLoggedInToSeeLeaderBoard, setIsLoggedInToSeeLeaderBoard] =
  //   useState(false)

  const [accessToken, setAccessToken] = useLocalStorage('accessToken', null)

  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', null)

  const dispatch = useDispatch()

  function handleMobileNumberChange(value, country, e, formattedValue) {
    const no = value.slice(2)
    setMobileNumber(no)
    setCountryCode(country.dialCode)
  }

  const isCorrectMobileNumber = (num = undefined) => {
    if (!!num) {
      const mobile = num?.slice(countryCode?.length)
      if (mobile?.length >= 8 && mobile?.length <= 15) return true
      return false
    }
    const mobile = mobileNumber?.slice(countryCode?.length)
    if (mobile?.length >= 8 && mobile?.length <= 15) return true
    return false
  }

  const handleGetOtp = async () => {
    const mobile = mobileNumber
    const code = '+' + countryCode

    const correctMobileNo =
      mobileNumber?.length >= 8 && mobileNumber?.length <= 15

    sendAnalytics('track', {}, 'Get Otp Pressed', ['moengage', 'mixpanel'])

    if (!correctMobileNo) {
      toast.dismiss()
      toast.error('Please enter valid mobile number', {
        icon: (
          <Error
            style={{
              width: '24px',
              height: '24px',
              color: 'black',
            }}
          />
        ),
      })
      return
    }
    dispatch(setMobile(mobileNumber))

    try {
      const res = await login({
        country_code: code,
        mobile,
        type: 'SMS',
      }).unwrap()
      if (res.message === 'OTP Sent Successfully') {
        setCounter(30)
        dispatch(setOtpToStore(true))
        setOtpSent(true)
      }
      return res
    } catch (error) {
      toast.dismiss()
      toast.error(
        !!error?.data?.message
          ? error?.data?.message
          : 'Please enter valid mobile number',
        {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                background: 'hsl(46, 100%, 90%)',
                color: 'black',
              }}
            />
          ),
        }
      )
    }
  }
  const handleSubmitOTP = async () => {
    const mobile = mobileNumber
    const code = '+' + countryCode
    try {
      const res = await verifyOTP({
        mobile,
        otp,
        country_code: code,
        type: 'SMS',
      }).unwrap()
      if (res?.message === 'Logged In') {
        if (res?.notification?.length > 0) {
          showNotification(res?.notification)
        }
        dispatch(
          setCredentails({
            accessToken: res?.access,
            refreshToken: res?.refresh,
            otpVerified: true,
          })
        )
        localStorage.setItem('accessToken', JSON.stringify(res?.access))
        localStorage.setItem('refreshToken', JSON.stringify(res?.refresh))
        // setAccessToken(res?.access)
        // setRefreshToken(res?.refresh)

        sendAnalytics(
          'identify',
          false,
          `${res?.data?.country_code + res?.data?.mobile}`,
          ['moengage', 'mixpanel']
        )
        const properties = {
          LOGIN_TYPE: 'SMS',
          LOGIN_STATUS: 'SUCCESSFUL',
        }
        sendAnalytics('track', properties, 'Login Status', [
          'moengage',
          'mixpanel',
        ])

        toast.success(
          `Logged in as ${
            !!res?.data?.name
              ? res?.data?.name
              : res?.data?.country_code + res?.data?.mobile
          }`
        )
        return res
      }
    } catch (error) {
      toast.dismiss()
      toast.error(
        !!error?.data?.message
          ? error?.data?.message
          : 'Please enter a valid OTP',
        {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                background: 'hsl(46, 100%, 90%)',
                color: 'black',
              }}
            />
          ),
        }
      )
      const properties = {
        LOGIN_TYPE: 'SMS',
        LOGIN_STATUS: 'FAILED',
      }
      sendAnalytics('track', properties, 'Login Status', [
        'moengage',
        'mixpanel',
      ])
    }
  }

  const handleResendOTP = async () => {
    if (!otpSent) return

    const code = '+' + countryCode

    try {
      const res = await login({
        country_code: code,
        mobile: mobileNumber,
        type: 'SMS',
      }).unwrap()

      if (res.message === 'OTP Sent Successfully') {
        setOtpSent(true)
        setCounter(30)
      }
    } catch (error) {
      toast.error(
        !!error?.data?.message
          ? error?.data?.message
          : 'Please enter a valid mobile number',
        {
          icon: (
            <Error
              style={{
                width: '24px',
                height: '24px',
                background: 'hsl(46, 100%, 90%)',
                color: 'black',
              }}
            />
          ),
        }
      )
    }
    setOtpSent(false)
  }

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  return {
    counter,
    handleGetOtp,
    handleMobileNumberChange,
    isCorrectMobileNumber,
    otp,
    handleSubmitOTP,
    setOtp,
    handleResendOTP,
    mobileNumber,
    otpSent,
    isVerifyOtpLoading,
    setCounter,
    isNormalLogin,
    countryCode,
    setNormalLogin,
    setMobileNumber,
    setCountryCode,
  }
}
