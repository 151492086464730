import { Fragment } from 'react'
import { Mic, MicOff } from '../icones'

export const MicFun = ({
  agoraUid,
  userUID,
  localMicMuted,
  remoteMicMuted,
}) => {
  return (
    <Fragment>
      {agoraUid === userUID && localMicMuted && (
        <MicOff className=" text-white cursor-pointer w-3 h-3 mt-1" />
      )}

      {agoraUid === userUID && !localMicMuted && (
        <Mic className=" text-white cursor-pointer w-3 h-3 mt-1" />
      )}

      {agoraUid !== userUID && remoteMicMuted === '1' && (
        <MicOff className=" text-white cursor-pointer w-3 h-3 mt-1" />
      )}

      {agoraUid !== userUID && remoteMicMuted === '0' && (
        <Mic className=" text-white cursor-pointer w-3 h-3 mt-1" />
      )}
    </Fragment>
  )
}
