import { Modal } from 'reactstrap'
import classNames from 'classnames'
import { ArrowRightLobby, GoBack, Mic, MicOff } from '../icones'
import LobbyImage from './../../../images/lobby-card.png'
import { Fragment } from 'react'
import { MicFun } from './MicFun'

const OnlyOnePersonInTheLobby = () => {
  return (
    <div className="bg-[#005FCC] text-sm text-white flex justify-between px-4 py-2">
      <span>Waiting for other players to join...</span>
      <span>Only you in the room</span>
    </div>
  )
}

const MoreThanOnePersonInLobby = ({ maxCapacity, currentUsers, newUser }) => {
  return (
    <div className="bg-[#FF8C00] text-sm text-white flex justify-between px-4 py-2">
      {newUser ? (
        <span>{newUser} has joined the room</span>
      ) : (
        <span>Welcome to the room</span>
      )}

      <span>
        Joined: {currentUsers}/{maxCapacity}
      </span>
    </div>
  )
}

const GameStartingSoon = ({ counter }) => {
  return (
    <div className="bg-[#168E22] text-sm text-white flex justify-center items-center text-center px-4 py-2">
      <span>Game will automatically start in </span>
      <span className="font-semibold"> {counter} seconds</span>.
    </div>
  )
}

export const LobbyRoom = ({
  isLobbyRoomModalOpen,
  gameName,
  allMultiPlayerUsers,
  handleCopyLink,
  code,
  handleMicToggle,
  micMuted,
  handleClose,
  newUserInfo,
  maxCapacity,
  agoraUid,
  isHostInLobby,
  handleStartRoom,
  isMobile,
  shareableLink,
  isAudioChatEnabled,
  handleShareableLink,
  isLocalMicEnabled,
  counter,
}) => {
  return (
    <Modal
      isOpen={isLobbyRoomModalOpen}
      style={{ width: 'fit-content', padding: '0' }}
      className="multiplayer-modal-wrapper"
    >
      <div className="bg-black  w-full">
        <div className="bg-black flex text-white justify-between p-4 ">
          <span className="normal-case">{gameName}</span>
          <span className="normal-case cursor-pointer" onClick={handleClose}>
            X
          </span>
        </div>
        {counter < 6 ? (
          <GameStartingSoon counter={counter} />
        ) : allMultiPlayerUsers?.length === 1 ? (
          <OnlyOnePersonInTheLobby />
        ) : (
          <MoreThanOnePersonInLobby
            maxCapacity={maxCapacity}
            currentUsers={allMultiPlayerUsers?.length}
            newUser={newUserInfo}
          />
        )}
        <div className="flex flex-col justify-center items-center">
          <div className="pt-2 gap-2 no-scrollbar w-fit pb-4 justify-center items-center px-4 grid grid-cols-2 h-[380px] overflow-scroll [&>*]:h-[180px] [&>*]:w-[160px] [&>*]:rounded-lg">
            {allMultiPlayerUsers?.map((user) => {
              const splittedUserName = user?.name?.split(' ')[0]
              const userName =
                splittedUserName?.length > 5
                  ? `${splittedUserName.substring(0, 4)}...`
                  : splittedUserName
              return (
                <div
                  className={`py-4 pl-4 pr-3 flex flex-col justify-between bg-lobby-card bg-cover border-2 border-black user-rtc-${user.uid}`}
                  style={{
                    boxShadow: 'inset 1px 1px 3px rgba(254, 254, 254, 0.2)',
                  }}
                  key={user.uid}
                >
                  <div className="flex justify-between ">
                    <img
                      src={user.avatar}
                      alt="female-1"
                      className="h-16 w-16 rounded-full"
                    />
                    {isAudioChatEnabled && (
                      <div className="bg-black p-1 h-fit rounded-full">
                        <MicFun
                          agoraUid={agoraUid}
                          userUID={user.uid}
                          localMicMuted={micMuted}
                          remoteMicMuted={user.isMicMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-white">{userName}</div>
                </div>
              )
            })}
          </div>
          <div className="text-white flex px-6 py-2 justify-between mb-1 w-full bg-[#16191D]">
            <div className=" text-white">Code : {code}</div>
            <div onClick={handleShareableLink}>
              {isMobile ? (
                <a
                  className="flex gap-2 cursor-pointer"
                  aria-label="Chat on WhatsApp"
                  href={`https://wa.me/?text=${shareableLink}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowRightLobby className=" w-6 h-6 text-white" />
                  <div className="text-white">Share room link</div>
                </a>
              ) : (
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={handleCopyLink}
                >
                  <ArrowRightLobby className=" w-6 h-6 text-white" />
                  <div className="text-white">Share room link</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between w-full items-center p-4 bg-[#16191D]">
            {isAudioChatEnabled ? (
              <Fragment>
                {micMuted ? (
                  <div className="flex flex-col items-center gap-2">
                    <MicOff
                      onClick={handleMicToggle}
                      className="text-[#FF2E1F] cursor-pointer"
                    />
                    <div className="text-white">Mic is Off</div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-2">
                    <Mic
                      onClick={handleMicToggle}
                      className="text-[#0077FF] cursor-pointer"
                    />
                    <div className="text-white">Mic is On</div>
                  </div>
                )}
              </Fragment>
            ) : (
              <div></div>
            )}

            {isHostInLobby && (
              <button
                className={classNames(
                  'bg-[#F3D505] text-black py-2 px-6 rounded-2xl',
                  {
                    'opacity-50 cursor-not-allowed': counter < 6,
                  }
                )}
                onClick={handleStartRoom}
                disabled={counter < 6}
              >
                Start Game
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
