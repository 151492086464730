import React, { createElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../component/Footer/Footer'
import Banner from '../../component/Banner/Banner'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux/es'
import { LoggedInBanner } from '../../component/LoggedInBanner/LoggedInBanner'
import { removeAllCategory } from '../../../store/ViewAll/categorySlice'
import { SkeletonCards } from '../../component/Common/SkeletonCards'
import {
  useUserContinuePlayMutation,
  useUserNameMutation,
  useUserRecordHistoryMutation,
  useUserSavedGamesMutation,
  useUserProfileMutation,
} from '../../../store/user/actions'
import { useVerifyOTPMutation } from '../../../store/login/verifyUser/action'
import { setRecommendations } from '../../../store/recommendation/slice'
import { useUserReelRecomendationMutation } from '../../../store/recommendation/action'
import { setAvatars, setUserInfo } from '../../../store/login/loginSlice'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import toast from 'react-hot-toast'
import { ArrowLeft, CloseXIcon, SearchIcon } from '../../component/icones'
import { Helmet } from 'react-helmet'
import { useScroll } from '../../../hooks/useScroll'
import { CheckAppInstalled } from '../../component/Common/CheckAppInstalled'
import ContinuePlay from '../../component/Common/ContinuePlay'
import ReelRecomendation from '../../component/Common/ReelRecomendation'
import SavedGames from '../../component/Common/SavedGames'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { CategoryComponent } from '../../component/Home/CategoryComponent'
import { EnterNameModal } from '../../component/Multiplayer/EnterNameModal'
import { ChangeAvatarModal } from '../../component/Multiplayer/ChangeAvatarModal'
import { useGetAgoraUsersMutation } from '../../../store/communication/action'
import { MoreDetailsModal } from '../../component/Home/MoreDetailsModal'
import { YearPickerModal } from '../../component/Common/YearPickerModal'
import { BottomBar } from '../../component/Common/BottomBar'
import { usePostPremiumConfigMutation } from '../../../store/premium/action'
import { BuyPremiumGameModal } from '../../component/Common/BuyPremiumGameModal'
import { useIsOnSafari } from '../../../hooks/useIsOnSafari'
import { useProfileUpdate } from '../../../hooks/useProfileUpdate'
import { isBottomBarVisible } from '../../../utils/constansts'
import { useGetPurchaseHistoryMutation } from '../../../store/coins/action'
import { setPurchaseHistory } from '../../../store/coins/slice'
import { useSearch } from '../../../hooks/useSearch'
import { CategoryList } from '../../component/Common/CategoryList'
import { PremiumCard } from '../../component/Common/PremiumCard'
import classNames from 'classnames'
import { convertString } from '../../../utils/maniputeString'
import { Carousal } from '../../component/Common/Carousal'
import DownLoadLinkModalOpen from '../../component/Common/DownLoadLinkModal'
import { useLoginInfo } from '../../../hooks/useLoginInfo'
import { useSendDownloadLinkMutation } from '../../../store/tournament/action'
import { TournamentLinkSentToast } from '../../component/Common/TournamentLinkSentToast'

const Home = () => {
  const dispatch = useDispatch()
  const games = useSelector((state) => state.games)
  const loginState = useSelector((state) => state.login)
  const scrollState = useSelector((state) => state.scroll)
  const continuePlay = useSelector((state) => state.continuePlay)
  const savedGames = useSelector((state) => state.savedGames)
  const allCategory = useSelector((state) => state.allCategory)
  const allCategoryId = useSelector((state) => state.allCategoryId)
  const userHistoryStore = useSelector((state) => state.userHistory)
  const recommendation = useSelector((state) => state.recommendation)

  const [verifyOTP, { isLoading: isVerifyOtpLoading }] = useVerifyOTPMutation()
  const [userSavedGames, { isLoading: isSavedGamesLoading }] =
    useUserSavedGamesMutation()
  const [userRecordHistory] = useUserRecordHistoryMutation()
  const [userContinuePlay, { isLoading: isContinuePlayLoading }] =
    useUserContinuePlayMutation()

  const [putUserProfile, { isLoading: isPutUserProfileLoading }] =
    useUserProfileMutation()

  const [userReelRecomendation, { isLoading: isReelRecomendationLoading }] =
    useUserReelRecomendationMutation()
  const [userName] = useUserNameMutation()
  const [userReport, setUserReport] = useState(undefined)

  const [postPremiumConfig, { isLoading: isPremiumPostGameConfigLoading }] =
    usePostPremiumConfigMutation()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const {
    scrollHomepageForNotLoggedInUser,
    setScrollHomepageForNotLoggedInUser,
    setScrollHomePageForLoggedInUser,
  } = useScroll()

  const [page, setPage] = useState(1)
  const isIOSorSafari = useIsOnSafari()
  const [continuePlayPage, setContinuePlayPage] = useState(1)

  const [savedGamePage, setSavedGamesPage] = useState(1)

  const [isGameVisible, setIsGameVisible] = useState(false)

  const streaks = useSelector((state) => state.streaks)

  const [isSavedGameVisible, setIsSavedGameVisible] = useState(false)

  const [getPurchaseHistory, { isLoading: isPurcahseHistoryLoading }] =
    useGetPurchaseHistoryMutation()

  const [sendDownlaodLink, { isLoading: isDownloadLinkLoading }] =
    useSendDownloadLinkMutation()

  const {
    isCorrectMobileNumber,
    handleMobileNumberChange,
    mobileNumber,
    setMobileNumber,
    countryCode,
    setCountryCode,
  } = useLoginInfo()

  const handlePressPlayStore = (storeName) => {
    const properties = {
      ScreenName: 'Homepage',
      StoreName: storeName,
    }

    sendAnalytics('track', properties, 'Download Button Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const handleGetSMS = async () => {
    try {
      const res = await sendDownlaodLink({
        country_code: `${countryCode}`,
        mobile_number: mobileNumber,
      }).unwrap()
      if (res.success) {
        setIsDownloadLinkModalOpen(false)
        toast(
          <TournamentLinkSentToast
            mobileNumber={`${countryCode}${mobileNumber}`}
            handlePressPlayStore={handlePressPlayStore}
          />,
          { icon: null, style: { background: '#fff' }, duration: 1000 }
        )
        const properties = {
          FROM: 'Home',
        }
        sendAnalytics('track', properties, 'Get Download Link Pressed', [
          'moengage',
          'mixpanel',
        ])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const {
    handleAvatarChange,
    handleSaveNameAvatar,
    onSelectedAvatar,
    toggleAvatarModalOnScreen,
    toggleEnterNameModalOnScreen,
    homePageModals,
    setHomePageModals,
    handleChangeAvatar,
    goBackFromEnterModal,
    handleNameChange,
    onClickYearOfBirth,
    handleProfile,
    handleGenderChange,
    goBackFromMoreDetails,
    toggleMoreDetailsOnScreen,
    handleNextToMoreDetails,
    handleGoBackFromYearPick,
    handleYearChange,
    toggleYearOfBirthOnScreen,
    handleNavigationHistoryAndNotif,
    premiumGameConfig,
    setPremiumGameConfig,
    getUserStreakData,
    recordHistoryAndSendNotif,
    togglePremiumGameModal,
    handleCancelBuy,
    getUserHistory,
    getUserSavedGames,
    getUserContinuePlay,
    fetchCategories,
    fetchSingleCategory,
    getAllCategoryId,
    isAllCategoryLoading,
    isAllCategoriesLoading,
  } = useProfileUpdate()

  const [scrollRef, scroll] = useScroll()

  const getUserName = async () => {
    try {
      const nameRes = await userName().unwrap()
      dispatch(
        setUserInfo({
          name: nameRes?.data?.name,
          selectedAvatar: nameRes?.data?.avatar,
          yearOfBirth: nameRes?.data?.year_of_birth,
          gender: nameRes?.data?.gender,
          mobile: nameRes?.data?.mobile,
          code: nameRes?.data?.country_code,
          social_id: nameRes?.data?.social_id,
        })
      )
      setMobileNumber(nameRes?.data?.mobile)
      setCountryCode(nameRes?.data?.country_code)
      return nameRes
    } catch (error) {
      console.log(error)
    }
  }
  const [getAgoraUsers, { isLoading: isAvatarsLoading }] =
    useGetAgoraUsersMutation()

  const getUserReelRecomendation = async (page) => {
    try {
      const allRecommds = await userReelRecomendation(page).unwrap()

      const updatedRecommends = allRecommds?.results?.map((reel) => ({
        ...reel,
        muted: true,
      }))

      dispatch(
        setRecommendations({
          data:
            page === 1
              ? [...updatedRecommends]
              : [...recommendation?.data, ...updatedRecommends],
          totalpage: allRecommds.total_page,
        })
      )
      return allRecommds
    } catch (error) {
      console.log(error)
    }
  }

  const getAvatars = async () => {
    try {
      const res = await getAgoraUsers().unwrap()
      // return res.token
      const randomNumber = Math.floor(Math.random() * res?.data?.length - 1)
      const ava = res?.data?.reduce(
        (acc, val, idx) =>
          idx === randomNumber
            ? [...acc, { src: val, selected: true }]
            : [...acc, { src: val, selected: false }],
        []
      )
      dispatch(setAvatars(ava))
    } catch (error) {
      console.log(error)
    }
  }

  const isBottomBar = isBottomBarVisible()

  useEffect(() => {
    if (!!loginState?.accessToken) {
      getUserName().then((userNameResponse) => {
        if (userNameResponse && !userHistoryStore?.data) {
          getUserHistory().then((historyResponse) => {
            if (historyResponse && savedGames?.data?.length === 0) {
              getUserSavedGames(1).then((savedGamesResponse) => {
                if (savedGamesResponse && continuePlay?.data?.length === 0) {
                  getUserContinuePlay(1).then((res) => {
                    if (res && !streaks.data) {
                      getUserStreakData()
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState.accessToken])

  useEffect(() => {
    if (loginState?.avatars?.length === 0 && loginState?.accessToken) {
      getAvatars()
    }
  }, [loginState])

  const handleReelRecomendationClick = async (
    game_name,
    uid,
    updatedAt,
    expiresAt,
    isPremium,
    slug,
    categoryId = 'recommendation',
    currentPage = 1
  ) => {
    // const properties = {
    //   GAME_NAME: game_name,
    //   GAME_FROM: 'Home',
    //   GAME_UUID: uid,
    //   SEGMENT_NAME: 'Reels tab',
    // }

    // sendAnalytics('track', properties, 'Play Game', ['moengage', 'mixpanel'])

    // handleNavigationHistoryAndNotif(
    //   slug,
    //   isPremium,
    //   uid,
    //   game_name,
    //   updatedAt,
    //   expiresAt,
    //   categoryId,
    //   currentPage
    // )
    navigate(`/reels`, { state: { from: uid } })
  }

  const [isDownLoadLinkModalOpen, setIsDownloadLinkModalOpen] = useState(false)
  const [smsPhoneNumberVlaue, setSMSPhoneNumberValue] = useState(false)

  const toggleDownLoadLink = () => {
    setIsDownloadLinkModalOpen(false)
  }
  const handlePhoneNumberChangeFromSMS = (e) => {
    setSMSPhoneNumberValue(e.target.value)
  }
  const getPurchses = async (pg) => {
    try {
      const res = await getPurchaseHistory(pg).unwrap()
      dispatch(setPurchaseHistory({ data: res.results }))
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  const handleAllGamesClicked = async (
    game_name,
    uid,
    updatedAt,
    expiresAt,
    isPremium,
    isMultiPlayer,
    slug,
    categoryId,
    currentPage
  ) => {
    //
    const properties = {
      GAME_NAME: game_name,
      GAME_FROM: 'Home',
      GAME_UUID: uid,
      SEGMENT_NAME: 'All Games',
    }
    sendAnalytics('track', properties, 'Play Game', ['moengage', 'mixpanel'])

    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }

  const handleSavedGameClick = async (
    game_name,
    uid,
    updatedAt,
    expiresAt,
    isPremium,
    isMultiPlayer,
    slug,
    categoryId = 'saved-games',
    currentPage
  ) => {
    const properties = {
      GAME_NAME: game_name,
      GAME_FROM: 'Home',
      GAME_UUID: uid,
      SEGMENT_NAME: 'Saved Games',
    }
    sendAnalytics('track', properties, 'Play Game', ['moengage', 'mixpanel'])
    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }

  useEffect(() => {
    if (allCategoryId?.data?.length === 0) {
      getAllCategoryId().then((res) => {
        if (res && allCategory?.data?.length === 0) {
          dispatch(removeAllCategory())
          fetchCategories(res)
        }
      })
    }
    if (recommendation?.data?.length === 0) {
      getUserReelRecomendation(1)
    }
  }, [])

  const handleScrollForNotLoggedInUser = () =>
    setScrollHomepageForNotLoggedInUser(window.scrollY)
  const handleScrollForLoggedInUser = () =>
    setScrollHomePageForLoggedInUser(window.scrollY)

  useEffect(() => {
    if (!loginState.accessToken) {
      window.scrollTo(0, scrollHomepageForNotLoggedInUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSavedGamesLoading,
    isContinuePlayLoading,
    isReelRecomendationLoading,
    games,
    userReport,
    loginState,
  ])

  const handleContinuePlayingClick = async (
    game_name,
    uid,
    updatedAt,
    expiresAt,
    isPremium,
    isMultiPlayer,

    slug,
    categoryId = 'continue_playing',
    currentPage
  ) => {
    const properties = {
      GAME_NAME: game_name,
      GAME_FROM: 'Home',
      GAME_UUID: uid,
      SEGMENT_NAME: 'Continue Playing',
    }
    sendAnalytics('track', properties, 'Play Game', ['moengage', 'mixpanel'])

    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }

  useEffect(() => {
    if (
      !games?.isLoading &&
      games?.data?.results?.length > 0 &&
      !loginState.accessToken &&
      !userReport
    ) {
      document.addEventListener('scroll', handleScrollForNotLoggedInUser)
    }
    return () =>
      document.removeEventListener('scroll', handleScrollForNotLoggedInUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSavedGamesLoading,
    isContinuePlayLoading,
    isReelRecomendationLoading,
    games,
    loginState,
    userReport,
  ])

  useEffect(() => {
    if (
      !games?.isLoading &&
      games?.data?.results?.length > 0 &&
      !!loginState.accessToken &&
      userReport &&
      loginState.mobile &&
      loginState.name
    ) {
      document.addEventListener('scroll', handleScrollForLoggedInUser)
    }
    return () =>
      document.removeEventListener('scroll', handleScrollForLoggedInUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, loginState, userReport])

  const playId = continuePlay?.data[continuePlay?.data?.length - 2]?.uuid
  const savedPlayId = savedGames?.data[savedGames?.data?.length - 2]?.game?.uuid

  const handleContinueScroll = () => {
    if (playId && continuePlayPage < continuePlay.totalpage) {
      setContinuePlayPage((prevCont) => prevCont + 1)
    }
  }

  const arePageNosLessThanTwo = continuePlayPage < 2
  const areSavedGamePageNosLessThanTwo = savedGamePage < 2
  const areAllComponentsLoading =
    (isSavedGamesLoading ||
      isContinuePlayLoading ||
      isReelRecomendationLoading) &&
    arePageNosLessThanTwo &&
    areSavedGamePageNosLessThanTwo

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  }
  const handleGameIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsGameVisible(true)
      } else {
        setIsGameVisible(false)
      }
    })
  }

  const observeElement = useIntersectionObserver(
    handleGameIntersection,
    observerOptions
  )

  useEffect(() => {
    const gameIdElement = document.querySelector(`.Id-${playId}`)
    observeElement(gameIdElement)
    return () => {
      observeElement(null)
    }
  }, [playId, observeElement])

  useEffect(() => {
    if (isGameVisible) {
      handleContinueScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameVisible])

  /***************************************** saved games section *******************************************************/
  const observerSavedOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  }

  const handleSavedGameIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsSavedGameVisible(true)
      } else {
        setIsSavedGameVisible(false)
      }
    })
  }

  const observeSavedElement = useIntersectionObserver(
    handleSavedGameIntersection,
    observerSavedOptions
  )

  useEffect(() => {
    const gameSavedIdElement = document.querySelector(`.Id-${savedPlayId}`)
    observeSavedElement(gameSavedIdElement)
    return () => {
      observeSavedElement(null)
    }
  }, [savedPlayId, observeSavedElement])

  const handleConsumePlay = async () => {
    try {
      const res = await postPremiumConfig(premiumGameConfig.uid).unwrap()
      if (res?.is_bought) {
        const recordHistoryRes = await recordHistoryAndSendNotif(
          premiumGameConfig.uid,
          premiumGameConfig.game_name,
          premiumGameConfig.updatedAt
        )
        if (recordHistoryRes.message === 'Game history saved.') {
          navigate(`/${premiumGameConfig.slug}`)
          dispatch(removeAllCategory())
          fetchCategories(allCategoryId?.data)
          getUserSavedGames(1)
          getUserReelRecomendation(1)
          getPurchses(1)
          getUserStreakData()
        }

        const properties = {
          GAME_NAME: premiumGameConfig?.game_name,
          GAME_UUID: premiumGameConfig?.uid,
        }

        sendAnalytics('track', properties, 'Buy Premium Game', [
          'moengage',
          'mixpanel',
        ])
      }
    } catch (error) {
      const properties = {
        GAME_NAME: premiumGameConfig.game_name,
        PURCHASE_STATUS: 'PAYMENT_FAILURE',
        GAME_UUID: premiumGameConfig.uid,
        GAME_FROM: 'Home',
      }
      sendAnalytics('track', properties, 'Premium Gameplay', [
        'moengage',
        'mixpanel',
      ])
    }
  }

  const handleSearch = () => {
    navigate('/search/')
    const properties = {
      FROM: 'Home',
    }

    sendAnalytics('track', properties, 'Search Icon Clicked', [
      'moengage',
      'mixpanel',
    ])
  }

  const {
    toBeIntersectedImgRef,
    handleSearchChange,
    handleCategoryClick,
    searchInput,
    selectedCategory,
    gamesToBeShown,
    debouncedSearch,
    setGamesToBeShown,
    isSearchEmpty,
    isVisible,
    getSearchResults,
    setSelectedCategory,
    setDebouncedSearch,
    setSearchInput,
    isSearchLoading,
  } = useSearch(allCategory)

  const handleGameClick = (
    slug,
    isPremium,
    isMultiPlayer,
    uid,
    game_name,
    updatedAt,
    expiresAt,
    categoryId,
    currentPage
  ) => {
    handleNavigationHistoryAndNotif(
      slug,
      isPremium,
      isMultiPlayer,
      uid,
      game_name,
      updatedAt,
      expiresAt,
      categoryId,
      currentPage
    )
  }
  const activeTournament = useSelector((state) => state.tournament)

  const areThereLessThanThreeGames =
    gamesToBeShown?.results?.games?.length < 3 ||
    gamesToBeShown?.results?.length < 3

  const isLarge = useMediaQuery('(min-width:768px)')

  const fetchNextPageOfCat = async () => {
    try {
      const data = await fetchSingleCategory(
        selectedCategory,
        gamesToBeShown?.current_page + 1
      )
      // [...gamesToBeShown?.results, ...res?.results],
      const newRes = {
        ...data,
        results: {
          ...data?.results,
          games: [...gamesToBeShown?.results?.games, ...data?.results?.games],
        },
      }
      setGamesToBeShown(newRes)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchNextPageOfSearch = () => {
    getSearchResults(debouncedSearch)
  }

  const handleViewMore = async () => {
    if (gamesToBeShown?.results?.length > 0) {
      fetchNextPageOfSearch()
    }

    if (gamesToBeShown?.results?.games?.length > 0) {
      fetchNextPageOfCat()
    }
  }

  const handleCloseXIcon = () => {
    setSelectedCategory('')
    setDebouncedSearch('')
    setSearchInput('')
  }

  const isCategoryOrSearchLoading = isSearchLoading || isAllCategoryLoading

  const enableMinHeightOnSelectedCat =
    (selectedCategory &&
      gamesToBeShown?.results?.games?.length > 0 &&
      gamesToBeShown?.results?.games?.length < 7) ||
    debouncedSearch

  const enableMarginSelectedCat =
    (selectedCategory && gamesToBeShown?.results?.games?.length > 0) ||
    (debouncedSearch && gamesToBeShown?.results?.length > 0)

  const largeLoggedIn = isLarge || (!isLarge && !loginState.accessToken)

  const handleTextClick = () => {
    if (!selectedCategory) {
      const allGames = allCategory?.data?.find(
        (cat) => cat.results.uuid === 'all-games'
      )
      setSelectedCategory('all-games')
      setGamesToBeShown(allGames)
    }
    const properties = {
      OPENED_FROM: 'Home',
    }
    sendAnalytics('track', properties, 'Search Icon Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const fetchNextPage = async (categoryId, pg) => {
    try {
      const data = await fetchSingleCategory(categoryId, pg)

      // [...gamesToBeShown?.results, ...res?.results],
      const newRes = {
        ...data,
        results: {
          ...data?.results,
          games: [...gamesToBeShown?.results?.games, ...data?.results?.games],
        },
      }
      setGamesToBeShown(newRes)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      isVisible &&
      gamesToBeShown?.current_page < gamesToBeShown?.total_page &&
      !debouncedSearch
    ) {
      fetchNextPage(selectedCategory, gamesToBeShown?.current_page + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const handlePlayInTournament = () => {
    const properties = {
      ScreenName: 'Home',
      IS_LOGIN: !!loginState?.accessToken ? true : false,
    }
    setIsDownloadLinkModalOpen(true)
    sendAnalytics('track', properties, 'Play in Tournamnet Button', [
      'moengage',
      'mixpanel',
    ])
  }

  const handlePlayInTournamentFromActive = (id, name) => {
    const properties = {
      ScreenName: 'Home',
      IS_LOGIN: !!loginState?.accessToken ? true : false,
      UUID: id,
      NAME: name,
    }
    setIsDownloadLinkModalOpen(true)
    sendAnalytics('track', properties, 'Active Tournament Pressed', [
      'moengage',
      'mixpanel',
    ])
  }

  const isNoResultsVisible = !!isSearchEmpty && !!searchInput

  return (
    <div className="min-h-screen relative flex flex-col">
      <div>
        <CheckAppInstalled isInstalled={false} deeplink="" />

        {loginState?.accessToken ? (
          <LoggedInBanner
            handleNavigationHistoryAndNotif={handleNavigationHistoryAndNotif}
            handleProfile={handleProfile}
            firstAvatar={loginState?.avatars?.[0]?.src}
          />
        ) : null}
        <DownLoadLinkModalOpen
          isOpen={isDownLoadLinkModalOpen}
          toggle={toggleDownLoadLink}
          handlePhoneNumberChangeFromSMS={handlePhoneNumberChangeFromSMS}
          smsPhoneNumberVlaue={smsPhoneNumberVlaue}
          isCorrectMobileNumber={isCorrectMobileNumber}
          handlePressPlayStore={handlePressPlayStore}
          handleMobileNumberChange={handleMobileNumberChange}
          handleGetSMS={handleGetSMS}
          mobileNumber={`${countryCode}${mobileNumber}`}
          isDownloadLinkLoading={isDownloadLinkLoading}
        />
        <Banner
          setHomePageModals={setHomePageModals}
          homePageModals={homePageModals}
          premiumGameConfig={premiumGameConfig}
          fetchCategories={fetchCategories}
          handleSearch={handleSearch}
          handleGetSMS={handleGetSMS}
          handlePlayInTournament={handlePlayInTournament}
          handlePlayInTournamentFromActive={handlePlayInTournamentFromActive}
        />
        <BuyPremiumGameModal
          isBuyPremiumGameModalOpen={homePageModals.isBuyPremiumGameModalOpen}
          togglePremiumGameModal={togglePremiumGameModal}
          gameName={premiumGameConfig.game_name}
          handleCancelBuy={handleCancelBuy}
          handleConsumePlay={handleConsumePlay}
          gameCost={premiumGameConfig.game_cost}
          gameDuration={premiumGameConfig?.validity}
          uid={premiumGameConfig.uid}
        />
        <ChangeAvatarModal
          avatars={loginState?.avatars}
          handleAvatarChange={handleAvatarChange}
          isChangeAvatarModalOpen={homePageModals.isChangeAvatarModalOpen}
          onSelectedAvatar={onSelectedAvatar}
          selectedAvatar={loginState?.selectedAvatar}
          firstAvatar={loginState?.avatars?.[0]?.src}
          toggleModalOnScreen={toggleAvatarModalOnScreen}
        />
        <EnterNameModal
          goBackFromEnterModal={goBackFromEnterModal}
          handleChangeAvatar={handleChangeAvatar}
          handleJoinCreatedRoom={handleSaveNameAvatar}
          handleNameChange={handleNameChange}
          isEnterNameModalOpen={homePageModals.isEnterNameModalOpen}
          selectedAvatar={loginState?.selectedAvatar}
          userNameFromAgora={loginState?.name}
          toggleModalOnScreen={toggleEnterNameModalOnScreen}
          firstAvatar={loginState?.avatars?.[0]?.src}
        />
        <MoreDetailsModal
          isMoreDetailsModalOpen={homePageModals.isMoreDetailsModalOpen}
          onClickYearOfBirth={onClickYearOfBirth}
          gender={loginState.gender}
          yob={loginState.yearOfBirth}
          handleGenderChange={handleGenderChange}
          goBackFromMoreDetails={goBackFromMoreDetails}
          toggleMoreDetailsOnScreen={toggleMoreDetailsOnScreen}
          handleNextToMoreDetails={handleNextToMoreDetails}
        />
        <YearPickerModal
          isEnterYearOfBirthModalOpen={
            homePageModals.isEnterYearOfBirthModalOpen
          }
          handleGoBackFromYearPick={handleGoBackFromYearPick}
          handleYearChange={handleYearChange}
          yob={loginState.yearOfBirth}
          toggleYearOfBirthOnScreen={toggleYearOfBirthOnScreen}
        />
        <div
          id="gamecon"
          className={classNames(
            'w-full xl:w-[60%] mx-auto sm:px-6 lg:px-8 md:py-4',
            {
              'lg:mt-[15%] 2xl:mt-[10%]':
                activeTournament?.data?.results?.length > 0,
            }
          )}
        >
          {areAllComponentsLoading || isAllCategoriesLoading ? (
            <div
              className={classNames('mt-[0%]', {
                'lg:mt-[15%] 2xl:mt-[10%]':
                  activeTournament?.data?.results?.length > 0,
              })}
            >
              <SkeletonCards />
            </div>
          ) : (
            <>
              <Helmet>
                <title>Timepass Games - Free Online Games - Play Now!</title>
                <meta
                  name="description"
                  content="100+ Free Games on Timepass Games. Discover a vast collection of free games, from action to strategy games and enjoy the convenience of playing multiple games in one place, without the need for downloads. Experience endless hours of online gaming fun, all for free."
                />
              </Helmet>
              {!selectedCategory && !debouncedSearch && (
                <div className="min-h-screen">
                  <div className="block lg:hidden">
                    <Carousal
                      handlePlayInTournamentFromActive={
                        handlePlayInTournamentFromActive
                      }
                    />
                  </div>
                  {recommendation?.data?.length > 0 && (
                    <ReelRecomendation
                      handleReelRecomendationClick={
                        handleReelRecomendationClick
                      }
                      recomm={recommendation?.data}
                    />
                  )}
                  {continuePlay && continuePlay?.data?.length > 0 && (
                    <ContinuePlay
                      continuePlay={continuePlay}
                      handleContinuePlayingClick={handleContinuePlayingClick}
                      totalPage={continuePlay.totalpage}
                    />
                  )}
                  {savedGames && savedGames?.data?.length > 0 && (
                    <SavedGames
                      handleSavedGameClick={handleSavedGameClick}
                      savedGames={savedGames}
                      totalPage={savedGames?.totalpage}
                    />
                  )}
                  <div className="pb-24 md:pb-0">
                    {allCategory?.data?.map((data) =>
                      createElement(CategoryComponent, {
                        key: data?.results?.uuid,
                        games: data?.results,
                        categoryId: data?.results?.uuid,
                        totalpages: allCategory?.totalpages,
                        fetchSingleCategory: fetchSingleCategory,
                        handleAllGamesClicked: handleAllGamesClicked,
                      })
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {isBottomBar && <BottomBar token={loginState.accessToken} />}
      </div>
      <Footer />
    </div>
  )
}
export default Home
