import { Modal } from 'reactstrap'
import { CoinImg, OrangeStreak, StreakFrame } from '../icones'

export const StreakIntroModal = ({
  isStreakIntroModalOpen,
  toggleStreakIntroModal,
  handleViewStreaks,
}) => {
  return (
    <Modal
      isOpen={isStreakIntroModalOpen}
      toggle={toggleStreakIntroModal}
      style={{ width: '350px' }}
    >
      <div className="flex flex-col justify-center items-center p-4 gap-4 my-auto">
        <OrangeStreak width="50px" height="70px" fill={'#f09819'} />
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-[22px]">Introducing Streaks</div>
          <div className="text-[#777f88] text-[17px]">
            Play games & earn coins
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-[21px] border-b border-b-[#e5e5e6] pb-[24px]">
            <CoinImg width="49px" height="53px" />
            <div>Play games & earn coins</div>
          </div>
          <div className="flex items-center gap-[21px]">
            <StreakFrame />
            <div className="w-[20ch]">
              Earn extra coins by completing challenges
            </div>
          </div>
        </div>
        <button
          className="bg-[#0077ff] my-2 text-white font-medium text-[20px] px-6 py-2 rounded-full"
          onClick={handleViewStreaks}
        >
          View Streaks
        </button>
      </div>
    </Modal>
  )
}
