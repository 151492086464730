import { useScroll } from '../../../hooks/useScroll'
import { useIsOverflow } from '../../../hooks/useOverFlow'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import classNames from 'classnames'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

export const GamesBarWrapper = (props) => {
  const isLarge = useMediaQuery('(min-width:579px)')

  const [scrollRef, scroll] = useScroll()

  const isOverflow = useIsOverflow(scrollRef)

  return (
    <div className="relative">
      <div
        className="wrapper h-40 flex items-center overflow-y-visible overflow-x-scroll gap-0 sm:pl-0 pl-2 scroll-smooth group px-2"
        ref={scrollRef}
      >
        {isOverflow && isLarge && (
          <div
            className={classNames(
              'absolute hidden group-hover:block left-9 top-12 z-10 cursor-pointer carousel-button text-white opacity-50 transition-opacity duration-300'
            )}
            onClick={() => scroll(-600)}
          >
            <FaChevronLeft className="text-white w-11 h-11 items-center align-middle rounded-full bg-black p-2.5" />
          </div>
        )}
        {props.children}
        {isOverflow && isLarge && (
          <div
            className={`absolute hidden group-hover:block right-9 top-12 cursor-pointer items-center text-white opacity-50 transition-opacity duration-300`}
            onClick={() => scroll(600)}
          >
            <FaChevronRight className="text-white w-11 h-11 items-center align-middle rounded-full bg-black p-2.5" />
          </div>
        )}
      </div>
    </div>
  )
}
